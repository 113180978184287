import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import RateLineComponent from './RateLineComponent'

const SkillsRateLineComponent = ({ gridSize = 3, alignRight = false }: { gridSize?: number; alignRight?: boolean }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, skillIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentSkillIndex(null)
    }

    const skills = euContext.resume.skills

    return (
        <>
            <RateLineComponent
                gridSize={gridSize}
                alignRight={alignRight}
                rateList={skills}
                onItemClick={handleOpenModal}
            />

            {currentSkillIndex !== null && currentCategoryIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isSkills={true}
                />
            )}
        </>
    )
}

export default SkillsRateLineComponent
