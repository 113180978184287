import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IPhone } from '../../../models/Phone'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { AddressComponent } from './AddressComponent'

interface ContactInterface {
    hideStreet?: boolean
    hideCity?: boolean
    hideState?: boolean
    hideZip?: boolean
    hideEmail?: boolean
    hidePhone?: boolean
    hideLinkedIn?: boolean
    sectionBgColor?: string
}

const ContactContent = ({
    hideState,
    hideCity,
    hideStreet,
    hideZip,
    hideEmail,
    hidePhone,
    hideLinkedIn,
    sectionBgColor,
    style,
    onClick,
    euContext
}: ContactInterface & { style: IStyles; onClick?: () => void; euContext: EmploymentUniverseType }) => (
    <Stack
        sx={{
            zIndex: 2,
            cursor: onClick ? 'pointer' : 'default'
        }}
        width='100%'
        bgcolor={sectionBgColor}
        columnGap={4}
        direction={'row'}
        onClick={onClick}>
        <AddressComponent
            hideCity={hideCity}
            hideState={hideState}
            hideStreet={hideStreet}
            hideZip={hideZip}
        />
        &#8226;
        {!hideEmail && (
            <Typography
                fontSize={`${style?.BodyFontSize ?? 5}px`}
                color={`${style.BodyFontColor ?? 'black'}`}
                fontStyle={style?.BodyFontStyle ?? 'initial'}
                letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                alignSelf={style?.BodyAlign ?? 'end'}
                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                {euContext.resume.personalInfo.email}
            </Typography>
        )}
        &#8226;
        {!hidePhone &&
            euContext.resume.personalInfo.phone.map((val: IPhone, index: number) => {
                return (
                    <Typography
                        key={index}
                        fontFamily={style[style.BodyFontName]}
                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                        alignSelf={style?.BodyAlign ?? 'end'}
                        textAlign={style?.BodyAlign ?? 'end'}
                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                        color={style.BodyFontColor ?? 'black'}
                        letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                        display='inline'>
                        {val.phone}
                    </Typography>
                )
            })}
        &#8226;
        {!hideLinkedIn && (
            <Typography
                fontFamily={style[style.BodyFontName]}
                fontSize={`${style?.BodyFontSize ?? 5}px`}
                fontStyle={style?.BodyFontStyle ?? 'initial'}
                alignSelf={style?.BodyAlign ?? 'end'}
                fontWeight={style?.BodyFontWeight ?? 'initial'}
                color={style.BodyFontColor ?? 'black'}
                letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}>
                {euContext.resume.personalInfo.linkedIn || 'Enter your LinkedIn profile id'}
            </Typography>
        )}
    </Stack>
)

const ContactHorizontalComponent = ({
    hideState = false,
    hideCity = false,
    hideStreet = false,
    hideZip = false,
    hideEmail = false,
    hidePhone = false,
    hideLinkedIn = false,
    sectionBgColor = ''
}: ContactInterface): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box width='100%'>
            <ContactContent
                hideState={hideState}
                hideCity={hideCity}
                hideStreet={hideStreet}
                hideZip={hideZip}
                hideEmail={hideEmail}
                hidePhone={hidePhone}
                hideLinkedIn={hideLinkedIn}
                sectionBgColor={sectionBgColor}
                style={style}
                onClick={handleOpenModal}
                euContext={euContext}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={['personalInfo.address.state', 'personalInfo.email', 'personalInfo.phone[0].phone', 'personalInfo.linkedIn']}
            />
        </Box>
    )
}

export default ContactHorizontalComponent
