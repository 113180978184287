import { Stack, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import EditModal from '../../EditModal/EditModal'

const TitleContent = ({ style, isHighlighted, onClick, sx = {} }: { style: IStyles; isHighlighted?: boolean; onClick?: () => void; sx?: object }) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <Stack
            spacing={0}
            direction='column'
            marginBottom={`${style?.SpaceCard ?? 10}px`}
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0',
                ...sx
            }}>
            <Typography
                fontFamily={style[style.TitleFontName]}
                fontSize={`${style?.TitleFontSize ?? 40}px`}
                fontWeight={style?.TitleFontWeight ?? 'initial'}
                fontStyle={style?.TitleFontStyle ?? 'initial'}
                textAlign={style?.TitleAlign ?? 'end'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                color={style?.TitleFontColor ?? 'black'}
                letterSpacing={`${style?.TitleFontLetterSpacing ?? 0}px`}
                textTransform={style?.TitleFontCase ?? 'none'}
                sx={sx}>
                {euContext.resume.title}
            </Typography>
        </Stack>
    )
}

const TitleComponent = ({ sx }: { sx?: object }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const titleRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={titleRef}>
                <TitleContent
                    style={style}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                    sx={sx}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                names={['title']}
            />
        </>
    )
}

export default TitleComponent
