import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import ApprovalIcon from '@mui/icons-material/Approval'
import BoltIcon from '@mui/icons-material/Bolt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import HandshakeIcon from '@mui/icons-material/Handshake'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront'
import PsychologyIcon from '@mui/icons-material/Psychology'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TrainIcon from '@mui/icons-material/Train'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WorkIcon from '@mui/icons-material/Work'
import AffiliationsComponent from '../components/resume/affiliations/AffiliationsComponent'
import EducationCondensedListComponent from '../components/resume/educations/EducationCondensedListComponent'
import EducationCondensedSummaryComponent from '../components/resume/educations/EducationCondensedSummaryComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import EducationsComponent from '../components/resume/educations/EducationsComponent'
import EmploymentCondensedListComponent from '../components/resume/employments/EmploymentCondensedListComponent'
import EmploymentsComponent from '../components/resume/employments/EmploymentsComponent'
import HonorsComponent from '../components/resume/honors/HonorsComponent'
import LicensesListComponent from '../components/resume/licenses/LicensesListComponent'
import ProjectsDroppableComponent from '../components/resume/projects/ProjectsDroppableComponent'
import PublicationsComponent from '../components/resume/publications/PublicationsComponent'
import ReferencesComponent from '../components/resume/references/ReferencesComponent'
import ObjectiveComponent from '../components/resume/simpleCards/ObjectiveComponent'
import OtherComponent from '../components/resume/simpleCards/OtherComponent'
import PersonalComponent from '../components/resume/simpleCards/PersonalComponent'
import ProfessionalComponent from '../components/resume/simpleCards/ProfessionalComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import AccomplishmentsBulletListComponent from '../components/resume/simpleLists/AccomplishmentsBulletListComponent'
import AccomplishmentsListComponent from '../components/resume/simpleLists/AccomplishmentsListComponent'
import CapabilitiesBulletListComponent from '../components/resume/simpleLists/CapabilitiesBulletListComponent'
import CapabilitiesListComponent from '../components/resume/simpleLists/CapabilitiesListComponent'
import SkillsBubbleListComponent from '../components/resume/simpleLists/SkillsBubbleListComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import SkillsCircularProgressComponent from '../components/resume/simpleLists/SkillsCircularProgressComponent'
import SkillsHorizontalListComponent from '../components/resume/simpleLists/SkillsHorizontalListComponent'
import SkillsRateLineComponent from '../components/resume/simpleLists/SkillsRateLineComponent'
import TalentsDroppableComponent from '../components/resume/talents/TalentsDroppableComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import VolunteersComponent from '../components/resume/volunteers/VolunteersComponent'
import { cardTypes } from './Constants'
import { defaultCard } from './Util'

export const DefaultDefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume1DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume2DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume3DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsCircularProgressComponent, description: 'Skills', icon: StarIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'List of employments', icon: WorkIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume4DefaultCards: defaultCard[] = [
    {
        title: 'Accomplishments',
        cardType: cardTypes.accomplishments,
        cardComponent: AccomplishmentsListComponent,
        description: 'List of accomplishments',
        icon: EmojiEventsIcon
    },
    {
        title: 'Affiliations',
        cardType: cardTypes.affiliations,
        cardComponent: AffiliationsComponent,
        description: 'List of affiliations',
        icon: HandshakeIcon
    },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    {
        title: 'Employments',
        cardType: cardTypes.employments,
        cardComponent: EmploymentsComponent,
        isDraggable: true,
        description: 'List of employments',
        icon: WorkIcon
    },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Professional Profile', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume5DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsCircularProgressComponent, description: 'Skills', icon: StarIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'List of employments', icon: WorkIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume6DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsBulletListComponent, description: 'Summary Qualifications', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesBulletListComponent, description: 'Professional Skills', icon: BoltIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary Statement', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume7DefaultCards: defaultCard[] = [
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume8DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'Work Experience', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Profile', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume9DefaultCards: defaultCard[] = [
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume10DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationCondensedListComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume11DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume12DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume13DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsRateLineComponent, description: 'Skills', icon: StarIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume14DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume15DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesBulletListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationCondensedSummaryComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsHorizontalListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume16DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume17DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsBulletListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationCondensedSummaryComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentCondensedListComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Certifications', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Certifications', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume18DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume19DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationListComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume20DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const SingleColumnDefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]
