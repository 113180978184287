import { Stack } from '@mui/material'
import React from 'react'
import ActiveClientUsers from './ActiveClientUsers'
import ArchivedClientUsers from './ArchivedClientUsers'

const ClientUsers = () => {
    return (
        <Stack>
            <ActiveClientUsers />
            <br />
            <ArchivedClientUsers />
        </Stack>
    )
}

export default ClientUsers
