import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Checkbox, FormControlLabel, IconButton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import { IEmployment } from '../../models/Employment'
import QuillEditor from '../forms/QuillEditor'

interface EmploymentEditModalComponentProps {
    employmentList: IEmployment[]
    handleQuillChange: (listType: string, value: string) => void
    handleAddEmployment: (listType: string) => void
    handleDeleteEmployment: (listType: string, index: number) => void
    handleEmploymentChange: (listType: string, index: number, field: string, value: string | boolean) => void
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
    listType: string
}

const EmploymentEditModalComponent: React.FC<EmploymentEditModalComponentProps> = ({
    employmentList,
    handleQuillChange,
    handleAddEmployment,
    handleDeleteEmployment,
    handleEmploymentChange,
    selectedTab,
    setSelectedTab,
    listType
}) => {
    const isPresent = employmentList[selectedTab]?.isPresent || false

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                aria-label={`${listType}-tabs`}
                variant='scrollable'
                scrollButtons='auto'
                sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {employmentList.map((employment, index) => (
                    <Tab
                        key={`tab-${index}`}
                        label={employment.companyName || 'Untitled'}
                    />
                ))}
            </Tabs>

            {employmentList[selectedTab] && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Stack
                        spacing={3}
                        direction='row'>
                        <TextField
                            label='Title'
                            variant='outlined'
                            value={employmentList[selectedTab]?.title || ''}
                            onChange={(e) => handleEmploymentChange(listType, selectedTab, 'title', e.target.value)}
                        />
                        <TextField
                            label='Organization Name'
                            variant='outlined'
                            value={employmentList[selectedTab]?.companyName || ''}
                            onChange={(e) => handleEmploymentChange(listType, selectedTab, 'companyName', e.target.value)}
                        />
                        <TextField
                            label='Start Date'
                            type='month'
                            variant='outlined'
                            value={`${employmentList[selectedTab]?.startYear || ''}-${employmentList[selectedTab]?.startMonth?.toString().padStart(2, '0') || ''}`}
                            onChange={(e) => handleEmploymentChange(listType, selectedTab, 'start', e.target.value)}
                        />

                        {!isPresent && (
                            <TextField
                                label='End Date'
                                type='month'
                                variant='outlined'
                                value={`${employmentList[selectedTab]?.endYear || ''}-${employmentList[selectedTab]?.endMonth?.toString().padStart(2, '0') || ''}`}
                                onChange={(e) => handleEmploymentChange(listType, selectedTab, 'end', e.target.value)}
                            />
                        )}
                    </Stack>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPresent}
                                onChange={(e) => {
                                    handleEmploymentChange(listType, selectedTab, 'isPresent', e.target.checked)
                                }}
                            />
                        }
                        label='I am currently working here'
                    />
                    <Box sx={{ mt: 3, mb: 3 }}>
                        <Typography variant='h6'>Job Summary</Typography>
                        <QuillEditor
                            value={employmentList[selectedTab]?.summary?.description || ''}
                            onChange={(value) => handleQuillChange(listType, value)}
                        />
                    </Box>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                        mt={2}>
                        <IconButton
                            onClick={() => handleDeleteEmployment(listType, selectedTab)}
                            color='error'>
                            <DeleteIcon />
                        </IconButton>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => handleAddEmployment(listType)}>
                            Add Employment
                        </Button>
                    </Stack>
                </Box>
            )}
            {!employmentList[selectedTab] && (
                <Box>
                    <Typography>No Employment History found</Typography>
                </Box>
            )}
            {!employmentList[selectedTab] && (
                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    mt={2}>
                    <IconButton
                        onClick={() => handleDeleteEmployment(listType, selectedTab)}
                        color='error'>
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => handleAddEmployment(listType)}>
                        Add Employment
                    </Button>
                </Stack>
            )}
        </Box>
    )
}

export default EmploymentEditModalComponent
