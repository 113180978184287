import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import { useDistinctStatementCategories, useDistinctStatementJobTitles, useStatementsByCategoryJobTitle, useUpdateStatement } from '../../hooks/useStatements'

import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'
import CardComponent from './CardComponent'
import StatementsComponent from './StatementsComponent'

const AssignCardsComponent = () => {
    const [selectedJobCategory, setSelectedJobCategory] = useState(0)
    const [selectedJobTitle, setSelectedJobTitle] = useState(0)
    const { data: distinctCategoriesResponse, isLoading: distinctCategoriessLoading } = useDistinctStatementCategories()
    const { data: distinctJobTitlesResponse, isLoading: distinctJobTitlesIsLoading } = useDistinctStatementJobTitles(
        distinctCategoriesResponse ? distinctCategoriesResponse[selectedJobCategory] : undefined
    )
    const { data: distinctCategoryJobTitlesResponse, isLoading: distinctCategoryJobTitlesIsLoading } = useStatementsByCategoryJobTitle(
        distinctCategoriesResponse ? distinctCategoriesResponse[selectedJobCategory] : undefined,
        distinctJobTitlesResponse ? distinctJobTitlesResponse[selectedJobTitle] : undefined
    )
    const { mutate: updateStatement, isPending: isUpdating, error: updateError } = useUpdateStatement()

    const loadStatementCategories = async (categoryNum: number, titleNum: number) => {
        setSelectedJobCategory(categoryNum)
        setSelectedJobTitle(titleNum)
    }

    useEffect(() => {
        loadStatementCategories(0, 0)
    }, [])

    useEffect(() => {
        if (selectedJobCategory >= 0) {
            setSelectedJobTitle(0)
        }
    }, [selectedJobCategory])

    return (
        <Stack
            direction='row'
            gap={2}
            width='100%'>
            {distinctCategoriessLoading && <LoadingState message='Loading Categories' />}
            {distinctJobTitlesIsLoading && <LoadingState message='Loading Job Titles' />}
            {!distinctCategoriessLoading && !distinctJobTitlesIsLoading && (
                <CardComponent
                    jobCategories={distinctCategoriesResponse || []}
                    jobTitles={distinctJobTitlesResponse || []}
                    setSelectedJobCategory={setSelectedJobCategory}
                    setSelectedJobTitle={setSelectedJobTitle}
                    selectedJobCategory={selectedJobCategory}
                    selectedJobTitle={selectedJobTitle}
                />
            )}
            {distinctCategoryJobTitlesIsLoading && <LoadingState message='Loading Statements' />}
            {!distinctCategoryJobTitlesIsLoading && (
                <StatementsComponent
                    label='Statements'
                    statements={distinctCategoryJobTitlesResponse || []}
                    setStatements={updateStatement}
                />
            )}
            {isUpdating && <LoadingState message='Updating Statement' />}
            {updateError && <ErrorState message='Error Updating Statement' />}
        </Stack>
    )
}

export default AssignCardsComponent
