import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import LoadingResume from 'views/Loading'
import { IResume } from '../models/Resume'
import { viewResume } from '../utils/DBUtils'

import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import UnavailableResume from 'views/Unavailable'
import { getView } from 'views/viewUtils'
import { getDashBoardDefaultValues } from './forms/defaultValues/FormDefaultValues'
import { DashboardSchema } from './forms/schemas/FormSchemas'

export const ViewResumePage: FC = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    const { resume, setResume } = euContext
    const [StyledResume, setStyledResume] = useState<ReactNode>(<LoadingResume />)
    const { firstName = '', lastName = '', resumeName = '', location = '', state = '' } = useParams()

    const formContext = useForm({
        resolver: yupResolver(DashboardSchema),
        defaultValues: getDashBoardDefaultValues({} as IResume),
        mode: 'onBlur',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    useEffect(() => {
        getMyResume(firstName, lastName, resumeName, location, state).then((data: IResume | undefined) => {
            if (data) {
                setResume(data)
            } else setStyledResume(<UnavailableResume />)
        })
    }, [])

    useEffect(() => {
        if (resume) {
            setStyledResume(getView(resume))
        } else setStyledResume(<UnavailableResume />)
    }, [resume])

    const getMyResume = async (firstName: string, lastName: string, resumeName: string, location: string, state: string): Promise<IResume | undefined> => {
        const resume: IResume | undefined = await viewResume(euContext.domain, firstName, lastName, resumeName, location, state)

        if (resume) formContext.reset(resume)

        return resume
    }

    return (
        <FormProvider {...formContext}>
            <form>{StyledResume}</form>
        </FormProvider>
    )
}
