import Typography from '@mui/material/Typography'
import React from 'react'

export const Footer = (): JSX.Element => {
    return (
        <Typography
            variant='body1'
            alignSelf='center'
            align='center'
            width='100%'>
            My Footer Page, that will have copyright info
        </Typography>
    )
}
