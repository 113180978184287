import { Alert, Box, Button, Grid2, Snackbar, Typography } from '@mui/material'
import * as colors from '@mui/material/colors'
import React, { useContext, useEffect, useState } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateClientColor } from '../../hooks/useClients'
import { IHTMLColorName, IHTMLColorNameItem } from '../../models/HTMLColors'
import { camelToTitle } from '../../utils/Util'
import SelectColorComponent from '../SelectColorComponent'

const initialUICustomization: IHTMLColorName = {
    header: {
        backgroundColor: colors.blue[500],
        textColor: '#000000',
        borderBackgroundColor: '#000000',
        borderColor: '#000000',
        hoverBackgroundColor: colors.blueGrey[500],
        hoverTextColor: colors.grey[500],
        buttonBackgroundColor: colors.teal[500],
        buttonTextColor: '#000000',
        inputBackgroundColor: '#000000',
        inputTextColor: '#000000',
        linkBackgroundColor: colors.blue[500],
        linkTextColor: '#000000',
        buttonTextSecondary: colors.blue[500]
    },
    navigationBar: {
        backgroundColor: colors.blue[500],
        textColor: '#000000',
        borderBackgroundColor: colors.grey[500],
        borderColor: colors.grey[500],
        hoverBackgroundColor: colors.blueGrey[500],
        hoverTextColor: colors.grey[500],
        buttonBackgroundColor: colors.blue[500],
        buttonTextColor: '#000000',
        inputBackgroundColor: '#000000',
        inputTextColor: '#000000',
        linkBackgroundColor: colors.blue[500],
        linkTextColor: '#000000',
        buttonTextSecondary: colors.blue[500]
    },
    footer: {
        backgroundColor: colors.grey[500],
        textColor: '#000000',
        borderBackgroundColor: '#000000',
        borderColor: '#000000',
        hoverBackgroundColor: colors.grey[500],
        hoverTextColor: colors.blueGrey[500],
        buttonBackgroundColor: colors.blue[500],
        buttonTextColor: '#000000',
        inputBackgroundColor: '#000000',
        inputTextColor: '#000000',
        linkBackgroundColor: colors.blue[500],
        linkTextColor: '#000000',
        buttonTextSecondary: colors.blue[500]
    },
    login: {
        backgroundColor: '#000000',
        textColor: colors.blue[500],
        borderBackgroundColor: colors.grey[500],
        borderColor: colors.grey[500],
        hoverBackgroundColor: colors.blueGrey[500],
        hoverTextColor: colors.grey[500],
        buttonBackgroundColor: colors.blue[500],
        buttonTextColor: '#000000',
        inputBackgroundColor: '#000000',
        inputTextColor: '#000000',
        linkBackgroundColor: colors.blue[500],
        linkTextColor: '#000000',
        buttonTextSecondary: colors.blue[500]
    },
    card: {
        backgroundColor: '#000000',
        textColor: '#000000',
        borderBackgroundColor: colors.grey[500],
        borderColor: colors.grey[500],
        hoverBackgroundColor: colors.blueGrey[500],
        hoverTextColor: colors.grey[500],
        buttonBackgroundColor: colors.blue[500],
        buttonTextColor: '#000000',
        inputBackgroundColor: '#000000',
        inputTextColor: '#000000',
        linkBackgroundColor: colors.blue[500],
        linkTextColor: '#000000',
        buttonTextSecondary: colors.blue[500]
    }
}

const SiteCustomization: React.FC = () => {
    const [alertOpen, setAlertOpen] = useState(false)
    const [customization, setCustomization] = useState<IHTMLColorName>(initialUICustomization)
    const euContext = useContext(EmploymentUniverseContext)
    const updateClientColorMutation = useUpdateClientColor()

    useEffect(() => {
        if (euContext.htmlColors) {
            setCustomization(euContext.htmlColors)
        }
    }, [euContext.htmlColors])

    const handleCustomizationChange = (category: keyof IHTMLColorName, key: keyof IHTMLColorNameItem, value: string) => {
        console.log('handleCustomizationChange', category, key, value)
        setCustomization({
            ...customization,
            [category]: {
                ...customization[category],
                [key]: value
            }
        })
    }

    const handleSaveCustomizations = () => {
        euContext.setHtmlColors(customization)
        updateClientColorMutation.mutate(
            { subdomain: euContext.domain, htmlColors: customization },
            {
                onSuccess: () => {
                    setAlertOpen(true)
                },
                onError: (error) => {
                    console.error('Error saving customizations:', error)
                }
            }
        )
    }

    const handleResetCustomizations = () => {
        const defaultCustomization = {
            header: {
                backgroundColor: colors.blue[500],
                textColor: '#000000',
                borderBackgroundColor: '#000000',
                borderColor: '#000000',
                hoverBackgroundColor: colors.grey[500],
                hoverTextColor: colors.blueGrey[500],
                buttonBackgroundColor: colors.teal[500],
                buttonTextColor: '#000000',
                inputBackgroundColor: '#000000',
                inputTextColor: '#000000',
                linkBackgroundColor: colors.blue[500],
                linkTextColor: '#000000',
                buttonTextSecondary: colors.blue[500]
            }
        } as IHTMLColorName
        setCustomization(defaultCustomization)
        euContext.setHtmlColors(defaultCustomization)

        updateClientColorMutation.mutate(
            { subdomain: euContext.domain, htmlColors: defaultCustomization },
            {
                onSuccess: () => {
                    setAlertOpen(true)
                },
                onError: (error) => {
                    console.error('Error resetting customizations:', error)
                }
            }
        )
    }

    const renderCustomizationForm = () => {
        return Object.entries(customization).map(([category, settings]) => (
            <Box
                key={category}
                border={1}
                mb={3}>
                <Typography
                    variant='h6'
                    gutterBottom>
                    {camelToTitle(category)}
                </Typography>
                <Grid2
                    container
                    border={1}
                    spacing={2}>
                    {Object.entries(settings).map(([key, value]) => {
                        return (
                            <>
                                <Grid2
                                    size={8}
                                    key={key + value}>
                                    <Typography
                                        display='inline'
                                        width={200}
                                        textAlign='start'>
                                        {camelToTitle(key)}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={4}>
                                    <SelectColorComponent
                                        value={value as string}
                                        handleChange={(newValue) => handleCustomizationChange(category as keyof IHTMLColorName, key as keyof IHTMLColorNameItem, newValue)}
                                    />
                                </Grid2>
                            </>
                        )
                    })}
                </Grid2>
            </Box>
        ))
    }

    return (
        <Box>
            <Typography
                variant='h4'
                gutterBottom>
                Site Customization
            </Typography>
            {renderCustomizationForm()}
            <Button
                variant='contained'
                sx={{
                    backgroundColor: customization.card.buttonBackgroundColor,
                    color: customization.card.buttonTextColor,
                    borderColor: customization.card.borderBackgroundColor,
                    '&:hover': {
                        backgroundColor: customization.card.hoverBackgroundColor,
                        color: customization.card.hoverTextColor
                    }
                }}
                onClick={handleSaveCustomizations}>
                Save Customizations
            </Button>
            <Button
                variant='outlined'
                sx={{
                    backgroundColor: customization.card.buttonBackgroundColor,
                    color: customization.card.buttonTextColor,
                    borderColor: customization.card.borderBackgroundColor,
                    ml: 2,
                    '&:hover': {
                        backgroundColor: customization.card.hoverBackgroundColor,
                        color: customization.card.hoverTextColor
                    }
                }}
                onClick={handleResetCustomizations}>
                Reset to Defaults
            </Button>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity='success'
                    sx={{ width: '100%' }}>
                    Customizations saved successfully!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default SiteCustomization
