import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IProject } from '../../../models/Project'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import DroppableComponent from '../DroppableComponent'
import ProjectDraggableComponent from './ProjectDraggableComponent'

const ProjectsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IProject>
            section='projects'
            card='topics'
            items={resume.projects.topics}
            label={resume.projects.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={ProjectDraggableComponent}
        />
    )
}

export default ProjectsDroppableComponent
