import { ReactNode } from 'react'
import { IResume } from '../models/Resume'
import { DefaultSeqNumPerStyle, validResumeStyles } from '../utils/Constants'
import DefaultResume from './Default'
import LoadingResume from './Loading'
import LoadingState from './LoadingState'
import Resume10 from './Resume10'
import Resume11 from './Resume11'
import Resume12 from './Resume12'
import Resume13 from './Resume13'
import Resume14 from './Resume14'
import Resume15 from './Resume15'
import Resume16 from './Resume16'
import Resume17 from './Resume17'
import Resume18 from './Resume18'
import Resume19 from './Resume19'
import Resume2 from './Resume2'
import Resume20 from './Resume20'
import Resume3 from './Resume3'
import Resume4 from './Resume4'
import Resume5 from './Resume5'
import Resume6 from './Resume6'
import Resume7 from './Resume7'
import Resume8 from './Resume8'
import Resume9 from './Resume9'
import ResumeOne from './ResumeOne'
import SingleColumnResume from './SingleColumnResume'

export const getView = (myResume: IResume): ReactNode => {
    if (!myResume || !myResume.style) {
        return LoadingState({ message: 'Loading...' })
    }

    switch (myResume.currentStyle) {
        case validResumeStyles.Default:
            return DefaultResume(myResume)
        case validResumeStyles.Colorado:
        case validResumeStyles.Harvard:
        case validResumeStyles.Miami:
            return SingleColumnResume(myResume)
        case validResumeStyles.Resume1:
            return ResumeOne(myResume)
        case validResumeStyles.Resume2:
            return Resume2(myResume)
        case validResumeStyles.Resume3:
            return Resume3(myResume)
        case validResumeStyles.Resume4:
            return Resume4(myResume)
        case validResumeStyles.Resume5:
            return Resume5(myResume)
        case validResumeStyles.Resume6:
            return Resume6(myResume)
        case validResumeStyles.Resume7:
            return Resume7(myResume)
        case validResumeStyles.Resume8:
            return Resume8(myResume)
        case validResumeStyles.Resume9:
            return Resume9(myResume)
        case validResumeStyles.Resume10:
            return Resume10(myResume)
        case validResumeStyles.Resume11:
            return Resume11(myResume)
        case validResumeStyles.Resume12:
            return Resume12(myResume)
        case validResumeStyles.Resume13:
            return Resume13(myResume)
        case validResumeStyles.Resume14:
            return Resume14(myResume)
        case validResumeStyles.Resume15:
            return Resume15(myResume)
        case validResumeStyles.Resume16:
            return Resume16(myResume)
        case validResumeStyles.Resume17:
            return Resume17(myResume)
        case validResumeStyles.Resume18:
            return Resume18(myResume)
        case validResumeStyles.Resume19:
            return Resume19(myResume)
        case validResumeStyles.Resume20:
            return Resume20(myResume)
        default:
            return LoadingResume()
    }
}

export const getStyleSeqNumForStyle = (style: validResumeStyles) => {
    switch (style) {
        case validResumeStyles.Colorado:
            return DefaultSeqNumPerStyle.Colorado
        case validResumeStyles.Harvard:
            return DefaultSeqNumPerStyle.Harvard
        case validResumeStyles.Miami:
            return DefaultSeqNumPerStyle.Miami
        case validResumeStyles.Resume1:
            return DefaultSeqNumPerStyle.Resume1
        case validResumeStyles.Resume2:
            return DefaultSeqNumPerStyle.Resume2
        case validResumeStyles.Resume3:
            return DefaultSeqNumPerStyle.Resume3
        case validResumeStyles.Resume4:
            return DefaultSeqNumPerStyle.Resume4
        case validResumeStyles.Resume5:
            return DefaultSeqNumPerStyle.Resume5
        case validResumeStyles.Resume6:
            return DefaultSeqNumPerStyle.Resume6
        case validResumeStyles.Resume7:
            return DefaultSeqNumPerStyle.Resume7
        case validResumeStyles.Resume8:
            return DefaultSeqNumPerStyle.Resume8
        case validResumeStyles.Resume9:
            return DefaultSeqNumPerStyle.Resume9
        case validResumeStyles.Resume10:
            return DefaultSeqNumPerStyle.Resume10
        case validResumeStyles.Resume11:
            return DefaultSeqNumPerStyle.Resume11
        case validResumeStyles.Resume12:
            return DefaultSeqNumPerStyle.Resume12
        case validResumeStyles.Resume13:
            return DefaultSeqNumPerStyle.Resume13
        case validResumeStyles.Resume14:
            return DefaultSeqNumPerStyle.Resume14
        case validResumeStyles.Resume15:
            return DefaultSeqNumPerStyle.Resume15
        case validResumeStyles.Resume16:
            return DefaultSeqNumPerStyle.Resume16
        case validResumeStyles.Resume17:
            return DefaultSeqNumPerStyle.Resume17
        case validResumeStyles.Resume18:
            return DefaultSeqNumPerStyle.Resume18
        case validResumeStyles.Resume19:
            return DefaultSeqNumPerStyle.Resume19
        case validResumeStyles.Resume20:
            return DefaultSeqNumPerStyle.Resume20
        default:
            return DefaultSeqNumPerStyle.Default
    }
}
