import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import TitleComponent from '../resume/title/TitleComponent'

const ShowTitles = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Titles Component</TableCell>
                    <TableCell>
                        <TitleComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowTitles
