import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import PictureComponent from '../resume/picture/PictureComponent'
import PictureDodecagon from '../resume/picture/PictureDodecagon'
import PictureRectangle from '../resume/picture/PictureRectangle'

const ShowPictures = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Picture Component</TableCell>
                    <TableCell>
                        <PictureComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Picture Dodecagon</TableCell>
                    <TableCell>
                        <PictureDodecagon />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Small Circle 2 (more customizable)</TableCell>
                    <TableCell>
                        <PictureRectangle />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowPictures
