import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalListComponent } from './HorizontalListComponent'

const SkillsHorizontalListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, skillIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentCategoryIndex(null)
        setCurrentSkillIndex(null)
    }

    return (
        <>
            <HorizontalListComponent
                list={euContext.resume.skills}
                sectionBgColor={sectionBgColor}
                boldCategory={true}
                onSkillClick={handleOpenModal}
            />
            {currentCategoryIndex !== null && currentSkillIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    isSkills={true}
                />
            )}
        </>
    )
}

export default SkillsHorizontalListComponent
