import { Box, Grid2, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { format } from 'date-fns'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'

interface Props {
    sectionBgColor?: string
}

const PublicationsComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const publications = euContext.resume.publications

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {publications.label}
            </Typography>
            <Box
                onClick={() => openEditModal(0)}
                width='100%'
                rowGap={0}>
                {publications.publication.map((publication, index) => (
                    <Stack
                        key={index}
                        direction='column'
                        spacing={0}
                        rowGap={0}
                        sx={{ flexGrow: 1 }}>
                        <Typography
                            fontFamily={style[style.JobTitleFontName]}
                            fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                            fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                            alignSelf={style?.JobTitleAlign ?? 'end'}
                            sx={{ color: style?.JobTitleFontColor ?? 'black', letterSpacing: style?.JobTitleFontLetterSpacing ?? 1 }}
                            fontWeight={style?.JobTitleFontWeight ?? 'initial'}>
                            {publication.title}
                        </Typography>
                        <Grid2
                            container
                            spacing={1}
                            direction='row'
                            justifyContent='space-between'
                            sx={{ flexGrow: 1 }}>
                            <Grid2 size='auto'>
                                <Typography
                                    sx={{ alignSelf: 'left', width: '100%', color: style?.CompanyFontColor ?? 'black', letterSpacing: style?.CompanyFontLetterSpacing ?? 1 }}
                                    fontFamily={style[style.CompanyFontName]}
                                    fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                    fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                    alignSelf={style?.CompanyAlign ?? 'end'}
                                    fontWeight={style?.CompanyFontWeight ?? 'initial'}>
                                    {publication.publisher}
                                </Typography>
                            </Grid2>
                            <Grid2
                                size='auto'
                                alignItems='right'>
                                <Typography
                                    sx={{ alignSelf: 'end', marginTop: -0.75, color: style?.DateFontColor ?? 'black', letterSpacing: style?.DateFontLetterSpacing ?? 1 }}
                                    fontSize={`${style?.DateFontSize ?? 5}px`}
                                    fontStyle={style?.DateFontStyle ?? 'initial'}
                                    alignSelf={style?.DateAlign ?? 'end'}
                                    fontWeight={style?.DateFontWeight ?? 'initial'}>
                                    {format(new Date(publication.pubYear, publication.pubMonth - 1), style.DateFormat)}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2
                            container
                            spacing={1}
                            direction='row'
                            justifyContent='space-between'
                            sx={{ flexGrow: 1 }}>
                            <Grid2 size='auto'>
                                <Typography
                                    sx={{ alignSelf: 'left', width: '100%', color: style?.CompanyFontColor ?? 'black', letterSpacing: style?.CompanyFontLetterSpacing ?? 1 }}
                                    fontFamily={style[style.CompanyFontName]}
                                    fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                    fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                    alignSelf={style?.CompanyAlign ?? 'end'}
                                    fontWeight={style?.CompanyFontWeight ?? 'initial'}>
                                    {publication.publications}
                                </Typography>
                            </Grid2>
                            <Grid2
                                size='auto'
                                alignItems='right'>
                                <Stack
                                    direction='row'
                                    alignItems='right'>
                                    <Typography
                                        sx={{ alignSelf: 'end', marginTop: -0.75, color: style?.SectionFontColor ?? 'black', letterSpacing: style?.SectionFontLetterSpacing ?? 1 }}
                                        fontFamily={style[style.BodyFontName]}
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        alignSelf={style?.BodyAlign ?? 'end'}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                        {publication.pages}
                                    </Typography>
                                </Stack>
                            </Grid2>
                        </Grid2>
                        <Typography
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'end'}
                            sx={{ color: style?.BodyFontColor ?? 'black', letterSpacing: style?.BodyFontLetterSpacing ?? 1 }}
                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                            {publication.description}
                        </Typography>
                    </Stack>
                ))}
            </Box>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isPublications={true}
                />
            )}
        </Box>
    )
}

export default PublicationsComponent
