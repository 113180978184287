import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'

import { AppLayout } from 'AppLayout'
import { Footer } from 'components/Footer'

import { LinkedInRegister, LinkedInSignIn } from 'auth/LinkedInOAuth'
import { ProtectedAdminRoute } from '../auth/ProtectedAdminRoute'
import { ProtectedRoute } from '../auth/ProtectedRoute'
import { ProtectedSuperAdminRoute } from '../auth/ProtectedSuperAdminRoute'
import ShowAll from '../components/test/ShowAll'
import TokenExpiryAlert from '../components/TokenExpiryAlert'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { AskOpenAI } from '../pages/AskOpenAI'
import { AssignStatementsPage } from '../pages/AssignStatementsPage'
import { CheckoutForm } from '../pages/CheckoutForm'
import { ClientsPage } from '../pages/ClientsPage'
import { DashboardPage } from '../pages/DashboardPage'
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage'
import { LoginPage } from '../pages/LoginPage'
import { RegisterPage } from '../pages/RegisterPage'
import SettingsPage from '../pages/SettingsPage'
import { StripeStatus } from '../pages/StripeStatus'
import { UsersPage } from '../pages/UsersPage'
import { ViewResumePage } from '../pages/ViewResumePage'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const MyRoutes = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <Routes>
            <Route
                path='/askOpenAI'
                element={
                    <AppLayout key='askOpenAIAppLayout'>
                        <ProtectedRoute key='askOpenAIProtected'>
                            <TokenExpiryAlert />
                            <AskOpenAI key='askOpenAI' />
                            <Footer />
                        </ProtectedRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/dashboard'
                element={
                    <AppLayout key='DashboardAppLayout'>
                        <ProtectedRoute key='DashboardProtected'>
                            <TokenExpiryAlert />
                            <DashboardPage key='dashboard' />
                            <Footer />
                        </ProtectedRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/'
                element={
                    <AppLayout key='Dashboard2AppLayout'>
                        <>
                            {euContext.user.isSuperAdmin && (
                                <ProtectedSuperAdminRoute key='Dashboard2SuperProtected'>
                                    <TokenExpiryAlert />
                                    <ClientsPage key='ClientsSuperProteced' />
                                    <Footer />
                                </ProtectedSuperAdminRoute>
                            )}
                            {!euContext.user.isSuperAdmin && (
                                <ProtectedRoute key='Dashboard2Protected'>
                                    <TokenExpiryAlert />
                                    <DashboardPage key='dashboard2' />
                                    <Footer />
                                </ProtectedRoute>
                            )}
                        </>
                    </AppLayout>
                }
            />
            <Route
                path='/assignStatements'
                element={
                    <AppLayout key='AssignStatementsAppLayout'>
                        <ProtectedAdminRoute key='AssignStatementsProtected'>
                            <TokenExpiryAlert />
                            <AssignStatementsPage key='assignStatements' />
                            <Footer />
                        </ProtectedAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/settings'
                element={
                    <AppLayout key='SettingsAppLayout'>
                        <ProtectedAdminRoute key='SettingsProtected'>
                            <TokenExpiryAlert />
                            <SettingsPage key='SettingsPage' />
                            <Footer />
                        </ProtectedAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/clients'
                element={
                    <AppLayout key='ClientsAppLayout'>
                        <ProtectedSuperAdminRoute key='ClientsProtected'>
                            <TokenExpiryAlert />
                            <ClientsPage key='ClientsPage' />
                            <Footer />
                        </ProtectedSuperAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/users'
                element={
                    <AppLayout key='UsersAppLayout'>
                        <ProtectedSuperAdminRoute key='UsersProtected'>
                            <TokenExpiryAlert />
                            <UsersPage key='UsersPage' />
                            <Footer />
                        </ProtectedSuperAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/checkout'
                element={
                    <AppLayout key='CheckoutAppLayout'>
                        <ProtectedAdminRoute key='CheckoutProtected'>
                            <TokenExpiryAlert />
                            <CheckoutForm key='checkout' />
                            <Footer />
                        </ProtectedAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/stripeStatus'
                element={
                    <AppLayout key='stripeStatusAppLayout'>
                        <ProtectedAdminRoute key='strpeStatusProtected'>
                            <TokenExpiryAlert />
                            <StripeStatus />
                            <Footer />
                        </ProtectedAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='/view/:firstName/:lastName/:resumeName/:location/:state'
                element={<ViewResumePage key='viewResume' />}
            />
            <Route
                path='/login'
                element={
                    <AppLayout key='LoginAppLayout'>
                        <>
                            <LoginPage key='LoginPage' />
                            <Footer />
                        </>
                    </AppLayout>
                }
            />
            <Route
                path='/forgot-password'
                element={
                    <AppLayout key='ForgotPasswordAppLayout'>
                        <>
                            <ForgotPasswordPage key='ForgotPasswordPage' />
                            <Footer />
                        </>
                    </AppLayout>
                }
            />
            <Route
                path='/register'
                element={
                    <AppLayout key='RegisterAppLayout'>
                        <>
                            <RegisterPage key='RegisterPage' />
                            <Footer />
                        </>
                    </AppLayout>
                }
            />
            <Route
                path='/linkedinRegister'
                element={<LinkedInRegister key='LinkedInRegister' />}
            />
            <Route
                path='/linkedinSignIn'
                element={<LinkedInSignIn key='LinkedInSignIn' />}
            />
            <Route
                path='/showComponents'
                element={
                    <AppLayout key='ShowAllAppLayout'>
                        <ProtectedSuperAdminRoute key='ShowAllProtected'>
                            <TokenExpiryAlert />
                            <ShowAll key='ShowAllComponents' />
                            <Footer />
                        </ProtectedSuperAdminRoute>
                    </AppLayout>
                }
            />
            <Route
                path='*'
                element={<div>No Match</div>}
            />
        </Routes>
    )
}
