import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IUser } from '../models/User'
import { restoreUsersLink, userLink, usersLink } from '../utils/Constants'

interface IUserResponse {
    status: string
    user: IUser
}

interface IUsersResponse {
    status: string
    users: IUser[]
}

const fetchUsers = async (): Promise<IUsersResponse> => {
    const response = await axios.get<IUsersResponse>(usersLink)
    return response.data
}

const fetchUser = async (id: string): Promise<IUserResponse> => {
    const response = await axios.get<IUserResponse>(userLink(id))
    return response.data
}

const addUser = async (user: IUser) => {
    if (user._id) {
        const response = await axios.patch(userLink(user._id), { user })
        return response.data
    } else {
        const response = await axios.post(usersLink, { user })
        return response.data
    }
}

const archiveUser = async (id: string) => {
    const response = await axios.delete(userLink(id))
    return response.data
}

const restoreUser = async (id: string) => {
    const response = await axios.delete(restoreUsersLink(id))
    return response.data
}

const updateUser = async (user: IUser) => {
    const response = await axios.patch(userLink(user._id), { user })
    return response.data
}

export const useUsers = () => {
    return useQuery<IUsersResponse>({
        queryKey: ['users'],
        queryFn: fetchUsers
    })
}

export const useUser = (id: string) => {
    return useQuery<IUserResponse>({
        queryKey: ['user', id],
        queryFn: () => fetchUser(id)
    })
}

export const useAddUser = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (user: IUser) => addUser(user),
        onError: (error) => {
            console.error('Error adding/updating user:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            return data
        }
    })
}

export const useUpdateUser = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (user: IUser) => updateUser(user),
        onError: (error) => {
            console.error('Error adding/updating user:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['user'] })
            queryClient.invalidateQueries({ queryKey: ['users'] })
            return data
        }
    })
}

export const useArchiveUser = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: string) => archiveUser(id),
        onError: (error) => {
            console.error('Error archiving user:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clientUsers'] })
            queryClient.invalidateQueries({ queryKey: ['archivedClientUsers'] })
            return data
        }
    })
}

export const useRestoreUser = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: string) => restoreUser(id),
        onError: (error) => {
            console.error('Error restoring user:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clientUsers'] })
            queryClient.invalidateQueries({ queryKey: ['archivedClientUsers'] })
            return data
        }
    })
}
