import { Alignments, DateDelims, DateFormats, FontCases, FontNames, FontStyles, FontWeights, IStyles, ResumeAlignments } from '../models/Styles'
import { validResumeStyles } from '../utils/Constants'

const Resume17: IStyles = {
    StyleName: 'Resume17' as validResumeStyles,
    PrimaryFontName: FontNames.IBMPlexSansCondensed,
    SecondaryFontName: FontNames.Montserrat,
    AlternateFontName: FontNames.Electrolize,
    NameFontName: 'PrimaryFontName',
    NameFontSize: 36,
    NameFontWeight: FontWeights.Bold,
    NameFontStyle: FontStyles.Initial,
    NameAlign: Alignments.End,
    NameFontCase: FontCases.Uppercase,
    NameFontColor: '#f9a825',
    NameFontLetterSpacing: 8,
    TitleFontName: 'PrimaryFontName',
    TitleFontSize: 14,
    TitleFontWeight: FontWeights.Bold,
    TitleFontStyle: FontStyles.Initial,
    TitleAlign: Alignments.Center,
    TitleFontCase: FontCases.None,
    TitleFontColor: '#000000',
    TitleFontLetterSpacing: 1,
    AddressFontName: 'PrimaryFontName',
    AddressFontSize: 12,
    AddressFontWeight: FontWeights.Initial,
    AddressFontStyle: FontStyles.Initial,
    AddressAlign: Alignments.End,
    AddressFontCase: FontCases.None,
    AddressFontColor: '#f9a825',
    AddressFontLetterSpacing: 1,
    SectionFontName: 'PrimaryFontName',
    SectionFontSize: 14,
    SectionFontWeight: FontWeights.Bold,
    SectionFontStyle: FontStyles.Initial,
    SectionAlign: Alignments.Start,
    SectionFontCase: FontCases.Capitalize,
    SectionFontColor: '#f9a825',
    SectionFontLetterSpacing: 1,
    SectionHRColor: '#000000',
    SectionHRLine: false,
    DateFontName: 'PrimaryFontName',
    DateFontSize: 12,
    DateFontWeight: FontWeights.Initial,
    DateFontStyle: FontStyles.Initial,
    DateAlign: Alignments.Start,
    DateFontCase: FontCases.None,
    DateFontColor: '#f9a825',
    DateFontLetterSpacing: 1,
    DateFormat: DateFormats.MMMyyyy,
    DateDelim: DateDelims.Dash,
    CompanyFontName: 'PrimaryFontName',
    CompanyFontSize: 12,
    CompanyFontWeight: FontWeights.Initial,
    CompanyFontStyle: FontStyles.Initial,
    CompanyAlign: Alignments.Start,
    CompanyFontCase: FontCases.None,
    CompanyFontColor: '#000000',
    CompanyFontLetterSpacing: 1,
    JobTitleFontName: 'PrimaryFontName',
    JobTitleFontSize: 12,
    JobTitleFontWeight: FontWeights.Bold,
    JobTitleFontStyle: FontStyles.Initial,
    JobTitleAlign: Alignments.Start,
    JobTitleFontCase: FontCases.Capitalize,
    JobTitleFontColor: '#f9a825',
    JobTitleFontLetterSpacing: 1,
    BodyFontName: 'PrimaryFontName',
    BodyFontSize: 12,
    BodyFontWeight: FontWeights.Initial,
    BodyFontStyle: FontStyles.Initial,
    BodyAlign: Alignments.Start,
    BodyFontCase: FontCases.None,
    BodyFontColor: '#000000',
    BodyFontLetterSpacing: 0,
    Special: '',
    Alignment: ResumeAlignments.Left,
    SpaceHeader: 20,
    SpaceSectionTitle: 12,
    SpaceCard: 12,
    SpaceSection: 1,
    SpaceBorder: 1,
    BorderLocation: '',
    BorderStyle: '',
    seqNum: 1,
    ShowGraphic: false
}

export default Resume17
