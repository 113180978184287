import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Input, MenuItem, Modal, Select, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { MouseEvent, useState } from 'react'
import { useClients } from '../../hooks/useClients'
import { useAddUser, useArchiveUser, useUpdateUser, useUsers } from '../../hooks/useUser'
import { IClients } from '../../models/Clients'
import { IUser } from '../../models/User'
import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'
import CustomTable from '../CustomTable'

const headCells = [
    { id: 'firstName', label: 'First Name', width: '300px' },
    { id: 'lastName', label: 'Last Name', width: '300px' },
    { id: 'email', label: 'Email', width: '300px' },
    { id: 'client', label: 'Client', width: '140px' },
    { id: 'created', label: 'Created', width: '140px' },
    { id: 'lastAccess', label: 'Last Access', width: '140px' },
    { id: 'actions', label: 'Actions', width: '100px' }
]

export const UsersComponent = () => {
    const [newUser, setNewUser] = useState({} as IUser)
    const [openAddUser, setOpenAddUser] = useState(false)
    const { data: usersResponse, isLoading: usersIsLoading } = useUsers()
    const { mutate: addUser, isError: addError } = useAddUser()
    const { mutate: archiveUser, isError: archiveError } = useArchiveUser()
    const { mutate: updateUser, isPending: isUpdating, isError: updateError } = useUpdateUser()
    const { data: clientsResponse } = useClients()

    const handleAddUser = async (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        addUser(newUser, {
            onSuccess: () => {
                setNewUser({} as IUser)
                setOpenAddUser(false)
            }
        })
    }

    const handleUpdateUser = async (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        updateUser(newUser, {
            onSuccess: () => {
                setNewUser({} as IUser)
                setOpenAddUser(false)
            }
        })
    }

    const handleArchiveUser = async (rUser: IUser) => {
        archiveUser(rUser._id, {
            onSuccess: () => {
                setNewUser({} as IUser)
                setOpenAddUser(false)
            }
        })
    }

    const getClientName = (clientId: string) => {
        const client: IClients | undefined = clientsResponse?.clients.find((c) => c._id === clientId)
        return client ? client.name : ''
    }

    const enableAdd = () => {
        if (
            newUser.firstName &&
            newUser.firstName.length > 0 &&
            newUser.lastName &&
            newUser.lastName.length > 0 &&
            newUser.email &&
            newUser.email.length > 0 &&
            newUser.password &&
            newUser.password.length > 0
        ) {
            return false
        } else {
            return true
        }
    }

    const onClose = (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
            setNewUser({} as IUser)
            setOpenAddUser(false)
        }
    }

    if (archiveError || updateError || addError) {
        return <ErrorState message={'Error adding/updating User.'} />
    }

    if (usersIsLoading) {
        return <LoadingState message={'Loading User'} />
    }

    const rows = usersResponse?.users.map((user: IUser) => ({
        ...user,
        client: getClientName(user.client_id),
        created: moment(user.createDate?.toLocaleString()).format('lll'),
        lastAccess: `${moment(user.lastAccessDate?.toLocaleString()).fromNow(true)} ago`,
        actions: (
            <>
                <span
                    onClick={() => {
                        setNewUser(user)
                        setOpenAddUser(true)
                    }}>
                    <EditOutlinedIcon />
                </span>
                <span onClick={() => handleArchiveUser(user)}>
                    <DeleteOutlinedIcon />
                </span>
            </>
        )
    }))

    return (
        <Stack
            width='840px'
            paddingLeft={3}>
            <Typography align='center'>List of Users!!</Typography>
            <Box
                alignSelf='center'
                onClick={() => {
                    setNewUser({} as IUser)
                    setOpenAddUser(true)
                }}>
                <AddCircleOutlineOutlinedIcon />
            </Box>
            <Modal
                open={openAddUser}
                onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}>
                    <Button onClick={(evt) => onClose(evt, 'buttonClick')}>
                        <Typography>Close</Typography>
                    </Button>
                    {updateError && <ErrorState message={'Error updating User'} />}
                    <Input
                        fullWidth
                        id='UserFirstName'
                        value={newUser.firstName || ''}
                        onChange={(evt) => setNewUser({ ...newUser, firstName: evt.target.value })}
                        placeholder='Enter User First Name'
                    />
                    <Input
                        fullWidth
                        id='UserLastName'
                        value={newUser.lastName || ''}
                        onChange={(evt) => setNewUser({ ...newUser, lastName: evt.target.value })}
                        placeholder='Enter User Last Name'
                    />
                    <Input
                        fullWidth
                        value={newUser.email || ''}
                        onChange={(evt) => setNewUser({ ...newUser, email: evt.target.value })}
                        id='UserEmail'
                        placeholder='Enter Email'
                    />
                    {!newUser._id && (
                        <Input
                            fullWidth
                            value={newUser.password || ''}
                            onChange={(evt) => setNewUser({ ...newUser, password: evt.target.value })}
                            id='UserPassword'
                            placeholder='Enter Password'
                        />
                    )}
                    {newUser._id && <Typography>{newUser.password || ''}</Typography>}
                    <Select
                        value={newUser.client_id || ''}
                        onChange={(evt) => setNewUser({ ...newUser, client_id: evt.target.value })}
                        id='UserClientId'
                        fullWidth>
                        {clientsResponse?.clients.map((client) => (
                            <MenuItem
                                key={client._id}
                                value={client._id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        disabled={enableAdd() || isUpdating}
                        onClick={newUser._id ? handleUpdateUser : handleAddUser}>
                        {newUser._id ? 'Edit' : 'Add'}
                    </Button>
                </Box>
            </Modal>
            <CustomTable
                columns={headCells}
                rows={rows}
            />
            <Typography align='right'>Total: {usersResponse?.users.length}</Typography>{' '}
        </Stack>
    )
}
