import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import ContactComponent from './ContactComponent'
import { LocationComponent } from './LocationComponent'

export const PersonalDetailComponent = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <Stack>
            <Typography
                fontFamily={style[style.SectionFontName]}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                sx={{
                    fontSize: `${style?.SectionFontSize ?? 5}px`,
                    fontStyle: style?.SectionFontStyle ?? 'initial',
                    alignSelf: style?.SectionAlign ?? 'initial',
                    fontWeight: style?.SectionFontWeight ?? 'initial',
                    marginBottom: `${style?.SpaceSectionTitle}px`,
                    textTransform: style?.SectionFontCase ?? 'none'
                }}>
                Personal Details
            </Typography>
            <LocationComponent />
            <ContactComponent />
        </Stack>
    )
}
