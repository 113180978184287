import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import TrainingListComponent from '../resume/trainings/TrainingListComponent'

const ShowTrainings = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Trainings Component (no params)</TableCell>
                    <TableCell>
                        <TrainingListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Trainings Component (section background color)</TableCell>
                    <TableCell>
                        <TrainingListComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Trainings List</TableCell>
                    <TableCell>
                        <TrainingListComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowTrainings
