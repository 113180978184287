import { Box, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { format } from 'date-fns'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IEmployment } from '../../../models/Employment'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
    isHighlighted: boolean
    onClick?: () => void
}

const EmploymentCondensedListContentView = ({ sectionBgColor = '', isHighlighted, onClick }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEmployment: IEmployment[] = euContext.resume.employments.employment.sort((a, b) => b.startYear - a.startYear)

    return (
        <Stack
            marginBottom={`${style.SpaceCard}px`}
            width='100%'
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0'
            }}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.employments.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Table
                border={0}
                cellSpacing={0}
                cellPadding={0}
                size='small'>
                <TableBody>
                    {orderedEmployment.map((emp: IEmployment, index: number) => {
                        return (
                            <>
                                <TableRow
                                    key={index}
                                    sx={{ border: 0, lineHeight: 1 }}>
                                    <TableCell sx={{ border: 0, padding: 0 }}>
                                        <Typography
                                            fontFamily={style[style.JobTitleFontName]}
                                            fontSize={`${style.JobTitleFontSize}px`}
                                            fontStyle={style.JobTitleFontStyle}
                                            fontWeight={style.JobTitleFontWeight}
                                            textAlign={style.JobTitleAlign}
                                            color={style?.JobTitleFontColor ?? 'black'}
                                            letterSpacing={style?.JobTitleFontLetterSpacing ?? 1}>
                                            {emp.title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 0, padding: 0 }}>
                                        <Typography
                                            fontFamily={style[style.DateFontName]}
                                            fontSize={`${style.DateFontSize}px`}
                                            fontStyle={style.DateFontStyle}
                                            fontWeight={style.DateFontWeight}
                                            textAlign='end'
                                            color={style?.DateFontColor ?? 'black'}
                                            letterSpacing={style?.DateFontLetterSpacing ?? 1}>
                                            {format(new Date(emp.startYear, emp.startMonth - 1), style.DateFormat)}
                                            &nbsp;{style.DateDelim}&nbsp;
                                            {emp.endMonth === 0 && emp.endYear === 0 ? 'Present' : format(new Date(emp.endYear, emp.endMonth - 1), style.DateFormat)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ border: 0 }}>
                                    <TableCell sx={{ border: 0, paddingBottom: 2, paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                                        <Typography
                                            fontFamily={style[style.CompanyFontName]}
                                            fontSize={`${style.CompanyFontSize}px`}
                                            fontStyle={style.CompanyFontStyle}
                                            fontWeight={style.CompanyFontWeight}
                                            textAlign={style.CompanyAlign}
                                            color={style?.CompanyFontColor ?? 'black'}
                                            letterSpacing={style?.CompanyFontLetterSpacing ?? 1}>
                                            {emp.companyName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 0, paddingBottom: 2, paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                                        <Typography
                                            fontFamily={style[style.BodyFontName]}
                                            fontSize={`${style.BodyFontSize}px`}
                                            fontStyle={style.BodyFontStyle}
                                            fontWeight={style.BodyFontWeight}
                                            textAlign='end'
                                            color={style?.BodyFontColor ?? 'black'}
                                            letterSpacing={style?.BodyFontLetterSpacing ?? 1}>
                                            {emp.location}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        </Stack>
    )
}

const EmploymentCondensedListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box textAlign={style?.NameAlign ?? 'end'}>
            <EmploymentCondensedListContentView
                sectionBgColor={sectionBgColor}
                isHighlighted={isModalOpen}
                onClick={handleOpenModal}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                isEmployment={true}
            />
        </Box>
    )
}
export default EmploymentCondensedListComponent
