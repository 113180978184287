import { extendTheme } from '@mui/material/styles'

const blueTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#5893df'
                },
                background: {
                    default: '#192231',
                    paper: '#24344d'
                }
            }
        },
        dark: {
            palette: {
                primary: {
                    main: '#5893df'
                },
                background: {
                    default: '#192231',
                    paper: '#24344d'
                }
            }
        }
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: 0
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    backgroundColor: 'transparent !important',
                    appearance: 'inherit'
                }
            }
        }
    }
})

const redTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#ff8f00'
                },
                background: {
                    default: '#310000',
                    paper: '#731010'
                }
            }
        },
        dark: {
            palette: {
                primary: {
                    main: '#ff8f00'
                },
                background: {
                    default: '#310000',
                    paper: '#731010'
                }
            }
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        }
    }
})

const greenTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#0f0'
                },
                background: {
                    default: '#111111',
                    paper: '#212121'
                }
            }
        },
        dark: {
            palette: {
                primary: {
                    main: '#0f0'
                },
                background: {
                    default: '#111111',
                    paper: '#212121'
                }
            }
        }
    },
    typography: {
        h1: {
            fontFamily: 'Ubuntu Mono'
        },
        h2: {
            fontFamily: 'Ubuntu Mono'
        },
        h3: {
            fontFamily: 'Ubuntu Mono'
        },
        h4: {
            fontFamily: 'Ubuntu Mono'
        },
        subtitle1: {
            fontFamily: 'Ubuntu Mono'
        },
        subtitle2: {
            fontFamily: 'Ubuntu Mono'
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    marginBottom: 10
                }
            }
        }
    }
})

const defaultTheme = extendTheme()

const allThemes = {
    blue: blueTheme,
    red: redTheme,
    green: greenTheme,
    default: defaultTheme
}

const getTheme = (domain: string) => {
    switch (domain) {
        case 'blue1':
            return allThemes.blue
        case 'red1':
            return allThemes.red
        case 'green1':
            return allThemes.green
        default:
            return allThemes.default
    }
}

export { getTheme }
