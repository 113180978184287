import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import CircleNameComponent from '../resume/name/CircleNameComponent'
import InitialCircleComponent from '../resume/name/InitialCircleComponent'
import NameComponent from '../resume/name/NameComponent'
import NameComponentShade from '../resume/name/NameComponentShade'
import { NameComponentWithBackground } from '../resume/name/NameComponentWithBackground'
import NameVerticleComponent from '../resume/name/NameVerticleComponent'
import ShowInitialsComponent from '../resume/name/ShowInitialsComponent'

const ShowNames = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>CircleNameComponent (no params)</TableCell>
                    <TableCell>
                        <Box height={200}>
                            <CircleNameComponent />
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>InitialCircleComponent (no params)</TableCell>
                    <TableCell>
                        <InitialCircleComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>NameComponent (no params)</TableCell>
                    <TableCell>
                        <NameComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>NameComponentShade (no params)</TableCell>
                    <TableCell>
                        <NameComponentShade />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>NameComponentShade (inline)</TableCell>
                    <TableCell>
                        <NameComponentShade showInline={true} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>NameComponentWithBackground (no params)</TableCell>
                    <TableCell>
                        <NameComponentWithBackground />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>NameVerticalComponent (no params)</TableCell>
                    <TableCell>
                        <NameVerticleComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ShowInitialsComponent (no params)</TableCell>
                    <TableCell>
                        <Box
                            bgcolor='black'
                            width={60}
                            height={60}
                            textAlign='center'
                            paddingLeft={1.5}>
                            <ShowInitialsComponent transformValue='none' />
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowNames
