import { Box, Typography } from '@mui/material'
import { withStyles, type ClassNameMap } from '@mui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

interface PictureComponentProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picBT?: number
}

const CrosshairsComponent = withStyles({
    root: {
        backgroundImage: `url(${require('../../../images/background/initialsBackground.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        display: 'flex'
    }
})(({ classes }: { classes: ClassNameMap }) => <div className={classes.root}></div>)

const InitialCircleComponent = ({ picWidth = 200, picHeight = 200, picAlign = 'center', picMT = 0, picBT = 0 }: PictureComponentProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const firstName: string = euContext.resume.personalInfo.firstName
    const lastName: string = euContext.resume.personalInfo.lastName
    // Get style
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [firstInitial, setfirstInitial] = useState<string>(firstName[0])
    const [lastInitial, setlastInitial] = useState<string>(lastName[0])

    useEffect(() => {
        // Get first and last initial
        const firstName: string = euContext.resume.personalInfo.firstName
        const lastName: string = euContext.resume.personalInfo.lastName

        setfirstInitial(firstName[0])
        setlastInitial(lastName[0])
    }, [euContext.resume.personalInfo])

    return (
        <Box
            width={picWidth}
            height={picHeight}
            display='flex'
            alignSelf={picAlign}
            mt={picMT}
            mb={picBT}
            position='relative'>
            <CrosshairsComponent />
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={30}
                fontWeight='bold'
                color='white'
                position='absolute'
                top='35%'
                left='30%'
                sx={{ transform: 'translate(-50%, -50%)' }}>
                {firstInitial}
            </Typography>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={30}
                fontWeight='bold'
                color='white'
                position='absolute'
                top='65%'
                left='70%'
                sx={{ transform: 'translate(-50%, -50%)' }}>
                {lastInitial}
            </Typography>
        </Box>
    )
}

export default InitialCircleComponent
