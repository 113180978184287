import { Box, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IEmployment } from '../../../models/Employment'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
    alignRight?: boolean
}

const VolunteersComponent = ({ sectionBgColor = '', alignRight = false }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedVolunteers: IEmployment[] = euContext.resume.volunteers.volunteer.sort((a, b) => b.startYear - a.startYear)

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={alignRight ? 'end' : style?.SectionAlign ?? 'initial'}
                color={style.SectionFontColor}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                letterSpacing={`${style?.SectionFontLetterSpacing ?? 1}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none', color: style.SectionFontColor }}>
                {euContext.resume.volunteers.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                rowGap={1}>
                {orderedVolunteers.map((volunteer: IEmployment, index: number) => (
                    <Box
                        key={index}
                        onClick={() => openEditModal(index)}
                        sx={{ cursor: 'pointer' }}>
                        <Stack direction='column'>
                            <Typography
                                fontFamily={style[style.CompanyFontName]}
                                fontSize={`${style.CompanyFontSize}px`}
                                fontStyle={style.CompanyFontStyle}
                                fontWeight={style.CompanyFontWeight}
                                color={style.CompanyFontColor}
                                letterSpacing={`${style?.CompanyFontLetterSpacing ?? 1}px`}
                                textAlign={alignRight ? 'end' : style.CompanyAlign}>
                                {volunteer.companyName}&nbsp;-&nbsp;{volunteer.title}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                color={style.BodyFontColor}
                                letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                                textAlign={alignRight ? 'end' : style.BodyAlign}>
                                {volunteer.location}
                            </Typography>
                            <Typography
                                fontFamily={style[style.DateFontName]}
                                fontSize={`${style.DateFontSize}px`}
                                fontStyle={style.DateFontStyle}
                                fontWeight={style.DateFontWeight}
                                color={style.DateFontColor}
                                letterSpacing={`${style?.DateFontLetterSpacing ?? 1}px`}
                                textAlign={alignRight ? 'end' : style.DateAlign}>
                                {format(new Date(volunteer.startYear, volunteer.startMonth - 1), style.DateFormat)}
                                &nbsp;{style.DateDelim}&nbsp;
                                {volunteer.endMonth === 0 && volunteer.endYear === 0 ? 'Present' : format(new Date(volunteer.endYear, volunteer.endMonth - 1), style.DateFormat)}
                            </Typography>
                        </Stack>
                    </Box>
                ))}
            </Stack>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isVolunteers={true}
                />
            )}
        </Stack>
    )
}

export default VolunteersComponent
