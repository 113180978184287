import { Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { isDefaultEmptyState } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'
import RateLineComponent from './RateLineComponent'

const AccomplishmentsRateLineComponent = ({ gridSize = 3, alignRight = false }: { gridSize?: number; alignRight?: boolean }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, itemIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentItemIndex(itemIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentCategoryIndex(null)
        setCurrentItemIndex(null)
    }

    const accomplishmentsCategory = euContext.resume.accomplishments?.category ?? []
    const showEmptyState = isDefaultEmptyState(accomplishmentsCategory)

    return (
        <>
            <Stack
                direction='column'
                spacing={2}>
                <RateLineComponent
                    gridSize={gridSize}
                    alignRight={alignRight}
                    rateList={euContext.resume.accomplishments}
                    onItemClick={handleOpenModal}
                />
                {showEmptyState && (
                    <Stack
                        spacing={2}
                        alignItems='flex-end'
                        onClick={() => handleOpenModal(0, 0)}
                        sx={{ cursor: 'pointer' }}>
                        <Typography fontFamily={euContext.resume.style[euContext.resume.currentStyle]?.BodyFontName}>Click to add Accomplishments</Typography>
                    </Stack>
                )}
            </Stack>

            {currentCategoryIndex !== null && currentItemIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isAccomplishments={true}
                />
            )}
        </>
    )
}

export default AccomplishmentsRateLineComponent
