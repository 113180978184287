import { IEmployments } from '../../../models/Employments'
import { IPersonalInfo } from '../../../models/PersonalInfo'
import { ISimpleCard } from '../../../models/SimpleCard'
import { ISimpleList } from '../../../models/SimpleList'

import { IAffiliations } from '../../../models/Affiliations'
import { IEducations } from '../../../models/Educations'
import { IHonors } from '../../../models/Honors'
import { ILicenses } from '../../../models/Licenses'
import { IProjects } from '../../../models/Projects'
import { IPublications } from '../../../models/Publications'
import { IReferences } from '../../../models/References'
import { IResume } from '../../../models/Resume'
import { ITalents } from '../../../models/Talents'
import { ITrainings } from '../../../models/Trainings'
import { IVolunteers } from '../../../models/Volunteers'

export const getDashBoardDefaultValues = (resume: IResume) => {
    const resumeName = resume.resumeName
    const accomplishments = resume.accomplishments as ISimpleList
    const affiliations = resume.affiliations as IAffiliations
    const capabilities = resume.capabilities as ISimpleList
    const educations = resume.educations as IEducations
    const employments = resume.employments as IEmployments
    const honors = resume.honors as IHonors
    const licenses = resume.licenses as ILicenses
    const objective = resume.objective as ISimpleCard
    const other = resume.other as ISimpleCard
    const personal = resume.personal as ISimpleCard
    const personalInfo = resume.personalInfo as IPersonalInfo
    const professional = resume.professional as ISimpleCard
    const projects = resume.projects as IProjects
    const publications = resume.publications as IPublications
    const references = resume.references as IReferences
    const skills = resume.skills as ISimpleList
    const summary = resume.summary as ISimpleCard
    const talents = resume.talents as ITalents
    const trainings = resume.trainings as ITrainings
    const volunteers = resume.volunteers as IVolunteers
    const createDate = resume.createDate
    const isPublic = resume.isPublic
    const currentStyle = resume.currentStyle
    const style = resume.style
    const picture = resume.picture
    const cardOrder = resume.cardOrder
    const title = resume.title as string

    const dashboardDefaultValues = {
        resumeName: resumeName,
        accomplishments: accomplishments,
        affiliations: affiliations,
        capabilities: capabilities,
        educations: educations,
        employments: employments,
        honors: honors,
        licenses: licenses,
        objective: objective,
        other: other,
        personal: personal,
        personalInfo: personalInfo,
        professional: professional,
        projects: projects,
        publications: publications,
        references: references,
        skills: skills,
        summary: summary,
        talents: talents,
        trainings: trainings,
        volunteers: volunteers,
        createDate: createDate,
        isPublic: isPublic,
        currentStyle: currentStyle,
        style: style,
        picture: picture,
        cardOrder: cardOrder,
        title
    }

    return dashboardDefaultValues
}

export const getLoginDefaultValues = () => {
    return {
        email: '',
        password: ''
    }
}
