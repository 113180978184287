import { Box, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

export const ForgotPasswordPage = (): JSX.Element => {
    const [emailValue, setEmailValue] = useState('')

    const onButtonClicked = (): void => {
        alert('Login not implemented yet')
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            <Typography
                component='h1'
                variant='h4'>
                Forgot Password
            </Typography>
            <FormControl
                margin='normal'
                fullWidth>
                <FormLabel>Email</FormLabel>
                <TextField
                    variant='outlined'
                    value={emailValue}
                    onChange={(e) => {
                        setEmailValue(e.target.value)
                    }}
                    placeholder='someone@gmail.com'
                    fullWidth
                    autoFocus
                />
            </FormControl>
            <Button
                variant='outlined'
                disabled={emailValue.length === 0}
                onClick={onButtonClicked}>
                Submit
            </Button>
        </Box>
    )
}
