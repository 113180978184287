import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { HorizontalLine, SmallCircle, SmallCircle2 } from '../resume/misc/MiscComponents'

const ShowMisc = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Horizontal Line</TableCell>
                    <TableCell>
                        <HorizontalLine />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Small Circle</TableCell>
                    <TableCell>
                        <Box
                            width={50}
                            display='flex'>
                            <SmallCircle left={10} />
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Small Circle 2 (more customizable)</TableCell>
                    <TableCell>
                        <Box
                            width={50}
                            display='flex'>
                            <SmallCircle2
                                left={0}
                                height={10}
                                width={10}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowMisc
