import InboxIcon from '@mui/icons-material/Inbox'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const EmptyState = ({ message = 'No data available.', onAddNew }: { message?: string; onAddNew?: () => void }) => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100vh'>
            <InboxIcon
                color='action'
                style={{ fontSize: 50 }}
            />
            <Typography
                variant='h6'
                color='textSecondary'
                style={{ marginTop: 16 }}>
                {message}
            </Typography>
            {onAddNew && (
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onAddNew}
                    style={{ marginTop: 16 }}>
                    Add New
                </Button>
            )}
        </Box>
    )
}

export default EmptyState
