import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import SimpleCardComponent from './SimpleCardComponent'

const PersonalComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <SimpleCardComponent
            card={euContext.resume.personal}
            fieldName='personal'
            sectionBgColor={sectionBgColor}
        />
    )
}

export default PersonalComponent
