import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { Gauge } from '@mui/x-charts/Gauge'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const SkillsListRateComponent = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills: ISimpleListItem[] =
        euContext.resume.skills.category && euContext.resume.skills.category[0].list ? euContext.resume.skills.category[0].list?.sort((a, b) => a.seqNum - b.seqNum) : []
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)

    const handleOpenModal = (skillIndex: number) => {
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentSkillIndex(null)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                sx={{ mb: 0, mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}>
                {euContext.resume.skills.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Box sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {orderedSkills.map((item: ISimpleListItem, index: number) => (
                        <Box key={index}>
                            <ListItem
                                sx={{ padding: '2px' }}
                                key={item.item + index}>
                                <Typography
                                    onClick={() => {
                                        handleOpenModal(index)
                                    }}
                                    suppressContentEditableWarning={true}
                                    contentEditable={!!euContext.user?.email}
                                    sx={{
                                        borderRadius: 6,
                                        minWidth: '50px',
                                        textAlign: 'center',
                                        backgroundColor: 'lightgrey',
                                        color: style?.SectionFontColor ?? 'black',
                                        letterSpacing: style?.SectionFontLetterSpacing ?? 1
                                    }}
                                    fontFamily={style[style.BodyFontName]}
                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                    fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                    {item.item}
                                </Typography>

                                <Gauge
                                    valueMin={0}
                                    valueMax={5}
                                    value={item.rate}
                                />
                            </ListItem>
                        </Box>
                    ))}
                </List>
            </Box>

            {currentSkillIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isSkills={true}
                />
            )}
        </Stack>
    )
}

export default SkillsListRateComponent
