import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { isValidISODate } from '../utils/Util'

interface Column {
    id: string
    label: string
    minWidth?: number
    align?: 'right' | 'left' | undefined
    format?: (value: number) => string
}

interface CustomTableProps {
    columns: Column[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: any[] | undefined
    EnhancedTableHeadComponent?: React.ComponentType<EnhancedTableToolbarProps>
    EnhancedTableToolbarComponent?: React.ComponentType<EnhancedTableToolbarProps>
    toolbarProps?: EnhancedTableToolbarProps
    handleRowClick?: (event: React.MouseEvent<unknown>, selectedIndex: number) => void
    showSearch?: boolean
}

type Order = 'asc' | 'desc'

interface EnhancedTableToolbarProps {
    numSelected: number
    label: string
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addStatements?: any
    selected: number[] | number
    rowCount: number
}

const CustomTable: React.FC<CustomTableProps> = ({ columns, rows, EnhancedTableHeadComponent, EnhancedTableToolbarComponent, showSearch = true, toolbarProps }) => {
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [isSearchOpen, setIsSearchOpen] = useState(false)

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    const handleDate = (columnId: string, value: string) => {
        if (columnId === 'createDate' || columnId === 'created') {
            return moment(value?.toLocaleString()).format('lll')
        }
        if (columnId === 'lastAccessDate' || columnId === 'updated') {
            return `${moment(value.toLocaleString()).fromNow(true)} ago`
        }
    }

    const filteredRows = useMemo(() => {
        return rows ? rows.filter((row) => Object.values(row).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))) : []
    }, [rows, search])

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            if (orderBy) {
                if (order === 'asc') {
                    return a[orderBy] < b[orderBy] ? -1 : 1
                } else {
                    return a[orderBy] > b[orderBy] ? -1 : 1
                }
            }
            return 0
        })
    }, [filteredRows, order, orderBy])

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedRows.length - page * rowsPerPage)

    return (
        <Box>
            {EnhancedTableToolbarComponent && toolbarProps && <EnhancedTableToolbarComponent {...toolbarProps} />}
            <TableContainer
                component={Paper}
                sx={{ maxHeight: 600, overflowY: 'auto', minWidth: 1000 }}>
                <Table stickyHeader>
                    {EnhancedTableHeadComponent ? (
                        <EnhancedTableHeadComponent
                            numSelected={toolbarProps?.numSelected ?? 0}
                            onSelectAllClick={toolbarProps?.onSelectAllClick ?? (() => {})}
                            rowCount={rows?.length ?? 0}
                            label={toolbarProps?.label ?? ''}
                            selected={toolbarProps?.selected ?? 0}
                            addStatements={toolbarProps?.addStatements}
                        />
                    ) : (
                        <TableHead>
                            <TableRow>
                                {showSearch && (
                                    <TableCell sx={{ paddingLeft: 2, paddingRight: 0 }}>
                                        <Tooltip title='Search'>
                                            <IconButton
                                                size='small'
                                                onClick={() => setIsSearchOpen(!isSearchOpen)}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                )}
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sortDirection={orderBy === column.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}>
                                            {column.label}
                                            {orderBy === column.id ? (
                                                <Box
                                                    component='span'
                                                    sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            {isSearchOpen && (
                                <TableRow>
                                    <TableCell colSpan={columns.length + 1}>
                                        <InputBase
                                            placeholder='Search…'
                                            value={search}
                                            onChange={handleSearchChange}
                                            fullWidth
                                            startAdornment={<SearchIcon />}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableHead>
                    )}
                    <TableBody>
                        {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}>
                                {showSearch && <TableCell padding='none'></TableCell>}
                                {columns.map((column) => {
                                    const value = isValidISODate(row[column.id]) ? handleDate(column.id, row[column.id]) : row[column.id]

                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}>
                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={columns.length + 1} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={sortedRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default CustomTable
