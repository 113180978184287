import { Box, Grid2, Stack } from '@mui/material'
import React from 'react'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import PictureRectangle from '../components/resume/picture/PictureRectangle'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'

const Resume5 = (resume: IResume): JSX.Element => {
    console.log(resume)
    // const orderUsedCards = AllDefaultCards.Resume5?.filter((value: defaultCard) => {
    //     const style = resume.cardOrder[resume.currentStyle]
    //     if (style) {
    //         return (style[value.cardType] ?? -1) >= 0
    //     }
    //     return false
    // }).sort((a: defaultCard, b: defaultCard) => {
    //     return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    // })
    return (
        <Box
            sx={{
                width: '800px',
                margin: 'auto',
                border: '1px solid',
                backgroundColor: 'rgba(227,227,227, .7)'
            }}>
            <Grid2
                sx={{ width: '100%' }}
                container>
                <Grid2 sx={{ width: '100%' }}>
                    <Stack
                        sx={{ width: '100%' }}
                        spacing={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                width: '100%',
                                alignItems: 'center',
                                paddingRight: '20px',
                                paddingLeft: '20px',
                                paddingTop: '50px'
                            }}>
                            <PictureRectangle
                                picWidth={305}
                                picHeight={225}
                                picAlign='center'
                                picMT={0}
                            />

                            <Box sx={{ paddingTop: 1 }}>
                                <NameVerticleComponent
                                    sx={{ letterSpacing: '6px' }}
                                    mt={3}
                                    mb={3}
                                />
                                <TitleComponent />
                            </Box>
                        </Box>
                        <Box sx={{ height: 500 }}></Box>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', paddingLeft: '20px', paddingRight: '20px' }}>
                            <Stack
                                sx={{ width: '40%' }}
                                spacing={3}>
                                <ContactComponent includeIcons={false} />
                                <EducationSummaryComponent />
                                <ReferencesComponent />
                            </Stack>
                            <Stack
                                sx={{ width: '50%' }}
                                spacing={3}>
                                <SummaryComponent />
                                <Box>
                                    <Stack
                                        direction='column'
                                        sx={{ paddingTop: '16px', gap: '12px' }}>
                                        {orderUsedCards.map((value: defaultCard) => {
                                            return <value.cardComponent key={value.cardType} />
                                        })}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box> */}
                    </Stack>
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default Resume5
