import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import ReferencesComponent from '../resume/references/ReferencesComponent'

const ShowReferences = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>References Component (no params)</TableCell>
                    <TableCell>
                        <ReferencesComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>References Component (section background color)</TableCell>
                    <TableCell>
                        <ReferencesComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Component 3</TableCell>
                    <TableCell>View 3</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowReferences
