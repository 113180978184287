import { Avatar, Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

// const VisuallyHiddenInput = styled('input')({
//     clip: 'rect(0 0 0 0)',
//     clipPath: 'inset(50%)',
//     height: 1,
//     overflow: 'hidden',
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     whiteSpace: 'nowrap',
//     width: 1
// })

interface PictureComponentProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picMB?: number
    picML?: number
    picMR?: number
}

const PictureComponent = ({ picWidth = 200, picHeight = 200, picAlign = 'center', picMT = 0, picMB = 0, picML = 0, picMR = 0 }: PictureComponentProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [selectedImage, setSelectedImage] = useState<string>()

    const pictureRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (euContext.resume.picture) {
            const filePicture: string = euContext.resume.picture
            setSelectedImage(filePicture)
        } else {
            setSelectedImage('empty')
        }
    }, [euContext.resume])

    // const handleChange = async (evt: FormEvent<HTMLInputElement>) => {
    //     evt.preventDefault()
    //     const pictureFile = evt.currentTarget.files ? evt.currentTarget.files[0] : null
    //     if (pictureFile) {
    //         const pictureReader = new FileReader()
    //         pictureReader.readAsDataURL(pictureFile)

    //         pictureReader.onload = () => {
    //             const pictureResult: string = pictureReader.result as string
    //             if (pictureResult) {
    //                 updatePictureResume(euContext.resume._id, pictureResult)
    //                 setSelectedImage(pictureResult)
    //             }
    //         }

    //         pictureReader.onerror = () => {
    //             console.error('Picture error: ' + pictureReader.error)
    //         }
    //     }
    // }

    return (
        <div ref={pictureRef}>
            <Stack spacing={0}>
                {selectedImage && style?.ShowGraphic && (
                    <Avatar
                        src={selectedImage}
                        sx={{ width: picWidth, height: picHeight, alignSelf: picAlign, verticalAlign: 'middle', mt: picMT, mb: picMB, ml: picML, mr: picMR }}
                    />
                )}
            </Stack>
        </div>
    )
}

export default PictureComponent
