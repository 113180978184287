import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { AllDefaultCards, defaultCard } from 'utils/Util'
import HorizontalContactComponent from '../components/resume/contact/HorizontalContactComponent'

const Resume17 = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Resume17.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack width='100%'>
            <Stack
                direction='row'
                columnGap={2}
                marginBottom={`${style.SpaceHeader}px`}>
                <Stack
                    direction='column'
                    width='100%'>
                    <NameComponent />
                    <HorizontalContactComponent columnGap={0.5} />
                </Stack>
            </Stack>
            {orderUsedCards.map((value: defaultCard) => {
                return <value.cardComponent key={value.cardType} />
            })}
        </Stack>
    )
}

export default Resume17
