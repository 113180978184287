import { Box, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard, isSimpleCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import { HorizontalLine, SmallCircle2 } from '../components/resume/misc/MiscComponents'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import AccomplishmentsBulletListComponent from '../components/resume/simpleLists/AccomplishmentsBulletListComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import { IResume } from '../models/Resume'

const Resume9 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume9.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='column'
            padding={3}
            rowGap={0}>
            <Stack
                direction='row'
                justifyContent='space-between'
                columnGap={2}
                width='100%'>
                <PictureComponent
                    picHeight={140}
                    picWidth={140}
                />
                <Box
                    mb={style.SpaceHeader}
                    mt={1.5}
                    width='40%'>
                    <NameVerticleComponent />
                    <TitleComponent />
                </Box>
                <Box
                    mb={style.SpaceHeader}
                    mr={4}
                    width='40%'>
                    <ContactComponent
                        includeLabel={false}
                        includeAddress={true}
                        hideState={false}
                        hideCity={false}
                        hideStreet={true}
                        hideZip={true}
                        hideEmail={false}
                        hideLinkedIn={false}
                        hidePhone={false}
                        includeIcons={true}
                        iconOnRight={true}
                    />
                </Box>
            </Stack>
            <HorizontalLine
                thickness={6}
                bgColor='lightgray'
                width='885px'
                ml={-50}
                mr={0}
                mt={6}
                mb={30}
            />
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={2}>
                <Stack
                    direction='column'
                    width='275px'
                    paddingRight={2}
                    rowGap={style.SpaceSection}>
                    <EducationListComponent />
                    <HorizontalLine
                        mt={3}
                        mb={0}
                    />
                    <SkillsBulletListComponent />
                    <HorizontalLine
                        mt={3}
                        mb={0}
                    />
                    <TrainingListComponent />
                    <HorizontalLine
                        mt={3}
                        mb={0}
                    />
                    <AccomplishmentsBulletListComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='474px'
                    paddingLeft={2}
                    borderLeft={1}
                    borderColor='lightgray'>
                    {orderUsedCards.map((value: defaultCard, index: number) => {
                        return (
                            <>
                                <SmallCircle2
                                    key={value.cardType}
                                    left={-22}
                                    mb={isSimpleCard(value) ? -14 : -17}
                                    width={12}
                                    height={12}
                                />
                                <value.cardComponent />
                                {index < orderUsedCards.length - 1 && <HorizontalLine />}
                            </>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume9
