import { Box, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import NameComponent from '../components/resume/name/NameComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'

const Resume14 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume14.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='column'
            rowGap={0}>
            <Stack
                direction='row'
                bgcolor='#d7ccc8'>
                <Stack
                    direction='column'
                    width='275px'
                    alignSelf='center'>
                    <PictureComponent
                        picAlign='center'
                        picMT={3}
                        picMB={3}
                    />
                </Stack>
                <Stack
                    direction='column'
                    width='540px'
                    alignSelf='center'
                    textAlign='center'>
                    <NameComponent />
                    <TitleComponent />
                </Stack>
            </Stack>
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={3}>
                <Stack
                    direction='column'
                    width='275px'
                    paddingLeft={3}
                    paddingRight={3}
                    bgcolor='#e5e4e2'
                    paddingTop={3}
                    rowGap={style.SpaceHeader}>
                    <ContactComponent
                        includeLabel={true}
                        includeAddress={true}
                        hideState={false}
                        hideCity={false}
                        hideStreet={true}
                        hideZip={true}
                        hideEmail={false}
                        hideLinkedIn={false}
                        hidePhone={false}
                        includeIcons={true}
                    />
                    <SkillsBulletListComponent />
                    <EducationListComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='540px'
                    paddingTop={3}
                    paddingLeft={0}
                    paddingRight={3}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return (
                            <Box
                                key={value.cardType}
                                paddingBottom={style.SpaceSection}>
                                <value.cardComponent />
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume14
