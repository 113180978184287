import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IAffiliations } from '../../../models/Affiliations'
import { IEmployment } from '../../../models/Employment'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { EmploymentComponent } from '../employments/EmploymentComponent'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
    cardSeperator?: boolean
}

const AffiliationsComponent = ({ sectionBgColor = '', cardSeperator = false }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const affiliations: IAffiliations = euContext.resume.affiliations

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {affiliations.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            {affiliations.affiliation.map((_: IEmployment, index: number) => (
                <Box
                    key={index}
                    onClick={() => openEditModal(index)}>
                    <EmploymentComponent
                        key={index}
                        compNum={index}
                        cardSeperator={cardSeperator}
                    />
                </Box>
            ))}
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isAffiliations={true}
                />
            )}
        </Stack>
    )
}

export default AffiliationsComponent
