import { useQuery } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IEnvironment } from '../models/Environment'
import { environmentLink } from '../utils/Constants'

const fetchEnvironments = async (): Promise<IEnvironment> => {
    const response = await axios.get<IEnvironment>(environmentLink)
    return response.data
}

export const useEnvironments = () => {
    return useQuery<IEnvironment>({
        queryKey: ['environments'],
        queryFn: fetchEnvironments
    })
}
