import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import EmploymentCondensedListComponent from '../resume/employments/EmploymentCondensedListComponent'
import EmploymentListComponent from '../resume/employments/EmploymentListComponent'
import EmploymentsComponent from '../resume/employments/EmploymentsComponent'

const ShowEmployments = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Employments Component (no params)</TableCell>
                    <TableCell>
                        <EmploymentsComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Employment Condensed List Component (no params)</TableCell>
                    <TableCell>
                        <EmploymentCondensedListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Employment List Component (no params)</TableCell>
                    <TableCell>
                        <EmploymentListComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowEmployments
