import { MenuItem, Select, TableCell, TableRow, TextField, Typography, type SelectChangeEvent } from '@mui/material'
import React, { FocusEvent, useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IResume } from '../../models/Resume'
import { FontNameKeys, FontNames, ResumeAlignments, ResumeAlignmentsKeys } from '../../models/Styles'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'

const ResumeFontSettingComponent = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField } = useUpdateResumeField()
    const [spaceHeader, setSpaceHeader] = useState<number>(euContext.resume.style[euContext.resume.currentStyle].SpaceHeader)
    const [spaceSectionTitle, setSpaceSectionTitle] = useState<number>(euContext.resume.style[euContext.resume.currentStyle].SpaceSectionTitle)
    const [spaceSection, setSpaceSection] = useState<number>(euContext.resume.style[euContext.resume.currentStyle].SpaceSection)
    const [spaceCard, setSpaceCard] = useState<number>(euContext.resume.style[euContext.resume.currentStyle].SpaceCard)

    const changeResumeFont = async (event: SelectChangeEvent<string>, fontNameField: FontNameKeys) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const fontName: FontNames = newValue as FontNames
        const fontField: string = `style.${resume.currentStyle}.${fontNameField}`

        updateResumeField(
            { id: resume._id, fieldName: fontField, fieldValue: fontName },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeResumeAlignment = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const resumeAlignment: ResumeAlignmentsKeys = newValue as ResumeAlignmentsKeys
        const alignmentField: string = `style.${resume.currentStyle}.Alignment`

        updateResumeField(
            { id: resume._id, fieldName: alignmentField, fieldValue: resumeAlignment },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const blurSpaceSize = async (event: FocusEvent<HTMLSpanElement>, fieldName: string, spaceSize: number) => {
        event.preventDefault()
        event.stopPropagation()

        const resume: IResume = euContext.resume
        const spaceField: string = `style.${resume.currentStyle}.${fieldName}`
        updateResumeField(
            { id: resume._id, fieldName: spaceField, fieldValue: spaceSize },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>Primary Font Name</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={euContext.resume.style[euContext.resume.currentStyle].PrimaryFontName as FontNames}
                        onChange={(evt) => changeResumeFont(evt, 'PrimaryFontName')}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontNames).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Secondary Font Name</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={euContext.resume.style[euContext.resume.currentStyle].SecondaryFontName as FontNames}
                        onChange={(evt) => changeResumeFont(evt, 'SecondaryFontName')}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontNames).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Alternate Font Name</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={euContext.resume.style[euContext.resume.currentStyle].AlternateFontName as FontNames}
                        onChange={(evt) => changeResumeFont(evt, 'AlternateFontName')}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontNames).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Resume Alignment</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={euContext.resume.style[euContext.resume.currentStyle].Alignment}
                        onChange={changeResumeAlignment}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.keys(ResumeAlignments).map((val, index: number) => {
                            const alignValue = Object.values(ResumeAlignments)[index]
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {alignValue.length > 0 ? alignValue : 'None'}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Space Header</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type='number'
                        name={'SpaceHeader'}
                        label=''
                        size='small'
                        value={spaceHeader}
                        onBlur={(e) => blurSpaceSize(e, 'SpaceHeader', spaceHeader)}
                        onChange={(e) => {
                            e.stopPropagation()
                            const newSize: number = +e.target.value
                            setSpaceHeader(newSize)
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Space Section Title</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type='number'
                        name={'SpaceSectionTitle'}
                        label=''
                        size='small'
                        value={spaceSectionTitle}
                        onBlur={(e) => blurSpaceSize(e, 'SpaceSectionTitle', spaceSectionTitle)}
                        onChange={(e) => {
                            e.stopPropagation()
                            const newSize: number = +e.target.value
                            setSpaceSectionTitle(newSize)
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Space Card</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type='number'
                        name={'SpaceCard'}
                        label=''
                        size='small'
                        value={spaceCard}
                        onBlur={(e) => blurSpaceSize(e, 'SpaceCard', spaceCard)}
                        onChange={(e) => {
                            e.stopPropagation()
                            const newSize: number = +e.target.value
                            setSpaceCard(newSize)
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Space Section</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type='number'
                        name={'SpaceSection'}
                        label=''
                        size='small'
                        value={spaceSection}
                        onBlur={(e) => blurSpaceSize(e, 'SpaceSection', spaceSection)}
                        onChange={(e) => {
                            e.stopPropagation()
                            const newSize: number = +e.target.value
                            setSpaceSection(newSize)
                        }}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

export default ResumeFontSettingComponent
