import { Alert, Box, Button, CircularProgress, Divider, Input, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAskGoogleAI } from '../hooks/useAI'

export const AskOpenAI = (): JSX.Element => {
    const [question, setQuestion] = useState('')
    const [answers, setAnswers] = useState<string[]>([])

    const { mutate, isPending, isError, error } = useAskGoogleAI()

    const saveChanges = () => {
        setAnswers([])
        mutate(question, {
            onSuccess: (data) => {
                setAnswers(data)
            },
            onError: (err) => {
                console.error(err.message)
            }
        })
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            <Typography variant='h4'>Ask OpenAI</Typography>
            {isError && <Alert severity='error'>{error?.message}</Alert>}
            <Input
                placeholder='Ask OpenAI a question:'
                fullWidth
                onChange={(e) => {
                    setQuestion(e.target.value)
                }}
                value={question}
            />
            {isPending && <CircularProgress />}
            <Divider sx={{ width: '100%', marginY: 2 }} />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '90%'
                }}>
                <List sx={{ listStyleType: 'disc', width: '100%' }}>
                    {answers.map((ans, index) => (
                        <ListItem key={index}>
                            <Typography
                                variant='body1'
                                component='span'
                                sx={{ display: 'list-item' }}>
                                {ans}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Button
                variant='outlined'
                onClick={saveChanges}
                disabled={isPending}>
                Submit
            </Button>
        </Box>
    )
}
