import { List, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import { HorizontalLine } from './misc/MiscComponents'

interface DroppableComponentProps<T> {
    section: SectionName
    card: CardName
    items: T[]
    label: string
    sectionBgColor?: string
    DraggableComponent: React.ComponentType<{ item: T; index: number; compNum: number; isDraggable: boolean; section: string; card: string }>
}

interface IResumeSections {
    licenses: 'license'
    projects: 'topics'
    trainings: 'training'
    educations: 'education'
    volunteers: 'volunteer'
    employments: 'employment'
    affiliations: 'affiliation'
    publications: 'publication'
    honors: 'honor'
    talents: 'talent'
    references: 'reference'
}

type SectionName = keyof IResumeSections
type CardName = IResumeSections[SectionName]

const DroppableComponent = <T extends { seqNum: number }>({ section, card, items, label, sectionBgColor = '', DraggableComponent }: DroppableComponentProps<T>): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]

    const sortedItems = (): T[] => {
        const copyItems = items.slice()
        return copyItems.sort((a, b) => a.seqNum - b.seqNum)
    }

    const getResumeIndexFromSeqNum = (seqNum: number): number => {
        return items.findIndex((item) => item.seqNum === seqNum)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={`${style?.SectionFontLetterSpacing ?? 1}px`}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}

            <List sx={{ width: '100%', paddingTop: 0, paddingBottom: 0, minHeight: 100 }}>
                {sortedItems().map((item, index) => (
                    <DraggableComponent
                        key={item.seqNum}
                        item={item}
                        compNum={getResumeIndexFromSeqNum(item.seqNum)}
                        index={index}
                        section={section}
                        card={card}
                        isDraggable={items.length > 1}
                    />
                ))}
            </List>
        </Stack>
    )
}

export default DroppableComponent
