/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

const CircleNameComponent = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const fullName: string = euContext.resume.personalInfo.firstName + ' ' + euContext.resume.personalInfo.lastName

    useEffect(() => {
        const radius = 70
        const angle = 145 // in degrees; 0 = auto minimum; if too narrow, draws minimum angle required instead
        const canvas: HTMLCanvasElement = document.getElementById('myCanvas') as HTMLCanvasElement
        const context = canvas.getContext('2d')

        const centerX = canvas.width / 2
        const centerY = 20
        const wSpace = 4
        if (context) drawTextAlongArcInside(context, fullName, style[style.SectionFontName], style.SectionFontColor, radius, angle, centerX, centerY, wSpace)
    }, [fullName, style])

    // based on http://www.html5canvastutorials.com/labs/html5-canvas-text-along-arc-path/
    const drawTextAlongArcInside = (
        ctx: CanvasRenderingContext2D,
        str: string,
        font: string,
        color: string,
        radius: number,
        angle: number,
        centerX: number,
        centerY: number,
        wspace: number | undefined = undefined
    ) => {
        let s
        const r = []
        let sum = 0
        let previous = 0
        angle = (angle * Math.PI) / 180
        if (wspace == undefined) wspace = getFontSize(font) / 4 // word spacing is usually 25% of font size
        const wordSpace = getRadians(wspace, radius)
        let len = str.length
        for (let n = 0; n < len; n++) {
            s = str[n]
            r[n] = getRadians(textWidth(s, font), radius)
            if (s == ' ') sum += wordSpace
            sum += r[n]
        }
        let space = (angle - sum) / (len - 1)
        if (space < 0) {
            if (angle == 0) {
                angle = sum
                space = 0
            } else {
                str = '' + ((sum * 180) / Math.PI + 1) // shows minimum required angle
                len = str.length
                space = wspace / 100
            }
        }

        ctx.save()
        ctx.font = font
        ctx.textAlign = 'center' // letter must rotate from its center
        ctx.textBaseline = 'middle' // best looking kerning
        ctx.fillStyle = color
        ctx.translate(centerX, centerY)
        ctx.rotate(angle / 2)

        for (let n = 0; n < len; n++) {
            s = str[n]
            ctx.rotate((-1 * r[n]) / 2)
            ctx.rotate((-1 * previous) / 2)
            previous = r[n]
            if (s == ' ') ctx.rotate(-1 * wordSpace) // space between words
            ctx.save()
            ctx.translate(0, radius)
            ctx.fillText(s, 0, 0)
            ctx.restore()
            ctx.rotate(-1 * space) // space between characters
        }
        ctx.restore()

        function getRadians(base: number, radius: number) {
            return 2 * Math.atan(base / 2 / radius)
        }

        // see http://stackoverflow.com/questions/5618676/how-to-parse-css-font-shorthand-format
        function getFontSize(cssString: string) {
            const el = document.createElement('span')
            el.setAttribute('style', 'font: ' + cssString)
            // can also return fontFamily, fontWeight, fontStyle, fontVariant, lineHeight
            return parseInt(el.style.fontSize, 10) // removes 'px' from fontSize
        }
    }

    // see http://stackoverflow.com/questions/1134586/how-can-you-find-the-height-of-text-on-an-html-canvas
    function textWidth(text: string, font: string) {
        let fontDraw: HTMLCanvasElement | null = document.createElement('canvas')
        const height = 100
        const width = 100
        fontDraw.setAttribute('height', '' + height)
        fontDraw.setAttribute('width', '' + width)
        const ctx = fontDraw.getContext('2d')
        if (ctx) {
            ctx.fillRect(0, 0, width, height)
            ctx.textBaseline = 'top'
            ctx.fillStyle = 'white'
            ctx.font = font
            ctx.fillText(text /*'Eg'*/, 0, 0)

            const pixels = ctx.getImageData(0, 0, width, height).data
            let start = -1
            let end = -1
            for (let column = 0; column < width; column++) {
                for (let row = 0; row < height; row++) {
                    const index = (row * width + column) * 4
                    if (pixels[index] == 0) {
                        if (row == height - 1 && start != -1) {
                            end = column
                            column = width
                            break
                        }
                        continue
                    } else {
                        if (start == -1) {
                            start = column
                        }
                        break
                    }
                }
            }
            fontDraw = null
            return end - start
        }

        return 0
    }

    return (
        <Box
            display='flex'
            position='relative'
            width={125}
            height={125}
            bgcolor='white'
            color={style.SectionFontColor}
            borderRadius={50}
            border={2}
            left={-35}
            top={style.SpaceHeader * 8 + 15}
            zIndex={10}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                position='relative'
                left={50}
                fontSize={40}
                fontStyle={style.SectionFontStyle}
                fontWeight={style.SectionFontWeight}
                color={style.SectionFontColor}
                textAlign='center'
                lineHeight={3}
                sx={{ verticalAlign: 'middle', transform: 'rotate(30deg)' }}>
                {euContext.resume.personalInfo.firstName[0]}
            </Typography>
            <canvas
                id='myCanvas'
                style={{ position: 'relative', top: 42, left: -82, zIndex: 10 }}
            />
        </Box>
    )
}

export default CircleNameComponent
