import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import EducationCondensedListComponent from '../resume/educations/EducationCondensedListComponent'
import EducationCondensedSummaryComponent from '../resume/educations/EducationCondensedSummaryComponent'
import EducationsComponent from '../resume/educations/EducationsComponent'
import EducationSummaryComponent from '../resume/educations/EducationSummaryComponent'

const ShowEducations = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Educations Component (no params)</TableCell>
                    <TableCell>
                        <EducationsComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Education Condensed List Component (no params)</TableCell>
                    <TableCell>
                        <EducationCondensedListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Education Condensed Summary Component (no params)</TableCell>
                    <TableCell>
                        <EducationCondensedSummaryComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Education Summary Component (no params)</TableCell>
                    <TableCell>
                        <EducationSummaryComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowEducations
