import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Tab, Tabs } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { getDashBoardDefaultValues } from '../../pages/forms/defaultValues/FormDefaultValues'
import { DashboardSchema } from '../../pages/forms/schemas/FormSchemas'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import ShowAffiliations from './ShowAffiliations'
import ShowContacts from './ShowContacts'
import ShowEducations from './ShowEducations'
import ShowEmployments from './ShowEmployments'
import ShowHonors from './ShowHonors'
import ShowLicenses from './ShowLicenses'
import ShowMisc from './ShowMisc'
import ShowNames from './ShowNames'
import ShowPictures from './ShowPictures'
import ShowProjects from './ShowProjects'
import ShowPublications from './ShowPublications'
import ShowReferences from './ShowReferences'
import ShowSimpleCards from './ShowSimpleCards'
import ShowSimpleLists from './ShowSimpleLists'
import ShowTalents from './ShowTalents'
import ShowTitles from './ShowTitles'
import ShowTrainings from './ShowTrainings'
import ShowVolunteers from './ShowVolunteers'

const ShowAll = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const formContext = useForm({
        resolver: yupResolver(DashboardSchema),
        defaultValues: getDashBoardDefaultValues(euContext.resume),
        mode: 'onBlur',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    useEffect(() => {
        formContext.reset(euContext.resume)
    }, [euContext.resume])

    return (
        <FormProvider {...formContext}>
            <form>
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '845px' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={(event, newValue) => setSelectedTab(newValue)}
                        variant='scrollable'
                        scrollButtons='auto'
                        orientation='vertical'
                        sx={{ width: '125px', borderRight: 1, borderColor: 'divider', marginRight: '15px' }}>
                        <Tab
                            key='Contacts'
                            label='Contacts'
                        />
                        <Tab
                            key='ShowNames'
                            label='Names'
                        />
                        <Tab
                            key='Affiliations'
                            label='Affiliations'
                        />

                        <Tab
                            key='Educations'
                            label='Educations'
                        />
                        <Tab
                            key='Employments'
                            label='Employments'
                        />
                        <Tab
                            key='Honors'
                            label='Honors'
                        />
                        <Tab
                            key='Licenses'
                            label='Licenses'
                        />
                        <Tab
                            key='Projects'
                            label='Projects'
                        />
                        <Tab
                            key='Publications'
                            label='Publications'
                        />
                        <Tab
                            key='References'
                            label='References'
                        />
                        <Tab
                            key='SimpleCards'
                            label='Simple Cards'
                        />
                        <Tab
                            key='SimpleLists'
                            label='Simple Lists'
                        />
                        <Tab
                            key='Talents'
                            label='Talents'
                        />
                        <Tab
                            key='Trainings'
                            label='Trainings'
                        />
                        <Tab
                            key='Volunteers'
                            label='Volunteers'
                        />
                        <Tab
                            key='Misc'
                            label='Misc'
                        />
                        <Tab
                            key='Title'
                            label='Title'
                        />
                        <Tab
                            key='Pictures'
                            label='Pictures'
                        />
                    </Tabs>
                    <Box sx={{ width: '725px', marginLeft: '15px' }}>
                        {selectedTab === 0 && <ShowContacts />}
                        {selectedTab === 1 && <ShowNames />}
                        {selectedTab === 2 && <ShowAffiliations />}
                        {selectedTab === 3 && <ShowEducations />}
                        {selectedTab === 4 && <ShowEmployments />}
                        {selectedTab === 5 && <ShowHonors />}
                        {selectedTab === 6 && <ShowLicenses />}
                        {selectedTab === 7 && <ShowProjects />}
                        {selectedTab === 8 && <ShowPublications />}
                        {selectedTab === 9 && <ShowReferences />}
                        {selectedTab === 10 && <ShowSimpleCards />}
                        {selectedTab === 11 && <ShowSimpleLists />}
                        {selectedTab === 12 && <ShowTalents />}
                        {selectedTab === 13 && <ShowTrainings />}
                        {selectedTab === 14 && <ShowVolunteers />}
                        {selectedTab === 15 && <ShowMisc />}
                        {selectedTab === 16 && <ShowTitles />}
                        {selectedTab === 17 && <ShowPictures />}
                    </Box>
                </Box>
            </form>
        </FormProvider>
    )
}

export default ShowAll
