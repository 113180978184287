import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const ErrorState = ({ message = 'An error occurred. Please refresh the page and try again.' }: { message?: string }) => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100vh'>
            <ErrorOutlineIcon
                color='error'
                style={{ fontSize: 50 }}
            />
            <Typography
                variant='h6'
                color='textSecondary'
                style={{ marginTop: 16 }}>
                {message}
            </Typography>

            <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    window.location.reload()
                }}
                style={{ marginTop: 16 }}>
                Refresh Page
            </Button>
        </Box>
    )
}

export default ErrorState
