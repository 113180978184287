import { Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IEmployment } from '../../../models/Employment'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
}

const EmploymentListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEmployment: IEmployment[] = euContext.resume.employments.employment.sort((a, b) => b.startYear - a.startYear)

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.employments.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                rowGap={1}>
                {orderedEmployment.map((emp: IEmployment, index: number) => (
                    <Stack
                        direction='column'
                        key={index}
                        onClick={() => openEditModal(index)}
                        sx={{ cursor: 'pointer' }}>
                        <Typography
                            fontFamily={style[style.CompanyFontName]}
                            fontSize={`${style.CompanyFontSize}px`}
                            fontStyle={style.CompanyFontStyle}
                            fontWeight={style.CompanyFontWeight}
                            textAlign={style.CompanyAlign}
                            color={style?.CompanyFontColor ?? 'black'}
                            letterSpacing={style?.CompanyFontLetterSpacing ?? 1}>
                            {emp.companyName}&nbsp;-&nbsp;{emp.title}
                        </Typography>
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style.BodyFontSize}px`}
                            fontStyle={style.BodyFontStyle}
                            fontWeight={style.BodyFontWeight}
                            textAlign={style.BodyAlign}
                            color={style?.BodyFontColor ?? 'black'}
                            letterSpacing={style?.BodyFontLetterSpacing ?? 1}>
                            {emp.location}
                        </Typography>
                        <Typography
                            fontFamily={style[style.DateFontName]}
                            fontSize={`${style.DateFontSize}px`}
                            fontStyle={style.DateFontStyle}
                            fontWeight={style.DateFontWeight}
                            textAlign={style.DateAlign}
                            color={style?.DateFontColor ?? 'black'}
                            letterSpacing={style?.DateFontLetterSpacing ?? 1}>
                            {format(new Date(emp.startYear, emp.startMonth - 1), style.DateFormat)}
                            {style.DateDelim}
                            {emp.endMonth === 0 && emp.endYear === 0 ? 'Present' : format(new Date(emp.endYear, emp.endMonth - 1), style.DateFormat)}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isEmployment={true}
                />
            )}
        </Stack>
    )
}

export default EmploymentListComponent
