import { Grid2, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleList } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { HorizontalLine, HorizontalRate } from '../misc/MiscComponents'

const RateLineComponent = ({
    rateList,
    gridSize = 3,
    alignRight = false,
    onItemClick
}: {
    rateList: ISimpleList
    gridSize?: number
    alignRight?: boolean
    onItemClick?: (categoryIndex: number, itemIndex: number) => void
}): JSX.Element => {
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                sx={{ mb: 0, mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={alignRight ? 'end' : style?.SectionAlign ?? 'initial'}
                textAlign={alignRight ? 'end' : style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}>
                {rateList.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Grid2
                container
                alignContent={alignRight ? 'end' : 'start'}
                columnGap={0}>
                {rateList.category.map((category, categoryIndex: number) => (
                    <React.Fragment key={categoryIndex}>
                        {category.list.map((item, itemIndex) => (
                            <React.Fragment key={itemIndex}>
                                <Grid2
                                    size={gridSize}
                                    onClick={() => onItemClick && onItemClick(categoryIndex, itemIndex)}
                                    sx={{ cursor: 'pointer' }}>
                                    <Typography
                                        suppressContentEditableWarning={true}
                                        sx={{
                                            textAlign: alignRight ? 'end' : 'start',
                                            color: style?.BodyFontColor ?? 'black',
                                            letterSpacing: style?.BodyFontLetterSpacing ?? 1
                                        }}
                                        fontFamily={style[style.BodyFontName]}
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                        {item.item}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={gridSize}>
                                    <HorizontalRate
                                        rate={item.rate}
                                        maxValue={5}
                                        height={5}
                                        mt={1}
                                        width={200}
                                        left={alignRight ? 58 : 0}
                                        alignRight={alignRight}
                                        lineColor={style?.SectionFontColor ?? 'black'}
                                    />
                                </Grid2>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </Grid2>
        </Stack>
    )
}

export default RateLineComponent
