import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleCategory, ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { isDefaultEmptyState } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const CapabilitiesListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const capabilitiesCategory: ISimpleCategory[] = euContext.resume.capabilities?.category || []
    const capabilities = capabilitiesCategory[0]?.list?.sort((a, b) => a.seqNum - b.seqNum) || []
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    const showEmptyState = isDefaultEmptyState(capabilitiesCategory)

    return (
        <Stack>
            <Typography
                fontFamily={style[style?.SectionFontName] ?? 'Roboto'}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}>
                {euContext.resume.capabilities.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            {showEmptyState ? (
                <Stack
                    spacing={2}
                    alignItems='center'
                    sx={{ cursor: 'pointer', padding: 2, backgroundColor: 'lightgrey', borderRadius: 4 }}
                    onClick={() => openEditModal(0)}>
                    <Typography
                        fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                        fontWeight={style?.BodyFontWeight ?? 'bold'}
                        color={style?.BodyFontColor ?? 'black'}>
                        Click to add Capabilities
                    </Typography>
                </Stack>
            ) : (
                <Box sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                    <List sx={{ flexWrap: 'wrap' }}>
                        {capabilities.map((item: ISimpleListItem, index: number) => (
                            <Box
                                onClick={() => openEditModal(index)}
                                key={index}>
                                <ListItem sx={{ padding: '2px' }}>
                                    <Typography
                                        fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        color={style?.BodyFontColor ?? 'black'}
                                        letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                                        sx={{
                                            borderRadius: 6,
                                            minWidth: '50px',
                                            textAlign: 'center',
                                            backgroundColor: 'lightgrey',
                                            cursor: 'pointer'
                                        }}>
                                        {item.item}
                                    </Typography>
                                </ListItem>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isCapabilities={true}
                />
            )}
        </Stack>
    )
}

export default CapabilitiesListComponent
