import { Stack, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext } from 'react'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'

interface AddressInterface {
    hideStreet?: boolean
    hideCity?: boolean
    hideState?: boolean
    hideZip?: boolean
    textAlign?: string
}

export const AddressComponent = ({ hideCity = false, hideState = false, hideStreet = false, hideZip = false, textAlign = 'start' }: AddressInterface): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { street, city, state, zip } = euContext.resume.personalInfo.address
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <Stack
            spacing={0}
            direction='column'>
            {!hideStreet && (
                <Typography
                    component='p'
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    sx={{ color: style?.AddressFontColor ?? 'black', letterSpacing: style.AddressFontLetterSpacing, textAlign: textAlign }}>
                    {street}
                </Typography>
            )}
            {!hideCity && (
                <Typography
                    component='p'
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    sx={{ color: style?.AddressFontColor ?? 'black', letterSpacing: style.AddressFontLetterSpacing, textAlign: textAlign }}>
                    {city}
                </Typography>
            )}
            {!hideState && (
                <Typography
                    component='p'
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    sx={{ color: style?.AddressFontColor ?? 'black', letterSpacing: style.AddressFontLetterSpacing, textAlign: textAlign }}>
                    {state}
                </Typography>
            )}
            {!hideZip && (
                <Typography
                    component='p'
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    sx={{ color: style?.AddressFontColor ?? 'black', letterSpacing: style.AddressFontLetterSpacing, textAlign: textAlign }}>
                    {zip}
                </Typography>
            )}
        </Stack>
    )
}
