import { Box, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IProject } from '../../../models/Project'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

type Props = {
    compNum: number
}

const ProjectDraggableComponent = ({ compNum }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume } = euContext
    const topic: IProject = resume.projects.topics[compNum]

    return (
        <ListItem sx={{ width: '100%' }}>
            <Box
                width='100%'
                rowGap={0}>
                <Stack
                    direction='column'
                    spacing={0}
                    rowGap={0}
                    sx={{ flexGrow: 1 }}>
                    <Typography
                        fontFamily={style[style.JobTitleFontName]}
                        sx={{
                            fontSize: `${style?.JobTitleFontSize ?? 5}px`,
                            fontStyle: style?.JobTitleFontStyle ?? 'initial',
                            alignSelf: style?.JobTitleAlign ?? 'end',
                            fontWeight: style?.JobTitleFontWeight ?? 'initial',
                            textAlign: style?.JobTitleAlign ?? 'initial',
                            color: style?.JobTitleFontColor ?? 'black',
                            letterSpacing: style?.JobTitleFontLetterSpacing ?? 1,
                            textTransform: style?.JobTitleFontCase ?? 'none'
                        }}>
                        {topic.topic}
                    </Typography>
                    <Typography
                        fontFamily={style[style.JobTitleFontName]}
                        sx={{
                            fontSize: `${style?.BodyFontSize ?? 5}px`,
                            fontStyle: style?.BodyFontStyle ?? 'initial',
                            alignSelf: style?.BodyAlign ?? 'end',
                            fontWeight: style?.BodyFontWeight ?? 'initial',
                            textAlign: style?.BodyAlign ?? 'initial',
                            color: style?.BodyFontColor ?? 'black',
                            letterSpacing: style?.BodyFontLetterSpacing ?? 1,
                            textTransform: style?.BodyFontCase ?? 'none'
                        }}>
                        {topic.description}
                    </Typography>
                </Stack>
            </Box>
        </ListItem>
    )
}

export default ProjectDraggableComponent
