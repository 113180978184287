import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { BulletListComponent } from './BulletListComponent'

const AccomplishmentsBulletListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, itemIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentItemIndex(itemIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentCategoryIndex(null)
        setCurrentItemIndex(null)
    }

    return (
        <>
            <BulletListComponent
                list={euContext.resume.accomplishments}
                sectionBgColor={sectionBgColor}
                onItemClick={handleOpenModal}
            />

            {currentCategoryIndex !== null && currentItemIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={750}
                    isAccomplishments={true}
                />
            )}
        </>
    )
}

export default AccomplishmentsBulletListComponent
