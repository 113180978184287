import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleCategory, ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const AccomplishmentsListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const accomplishments = euContext.resume.accomplishments.category as ISimpleCategory[]
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, itemIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentItemIndex(itemIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentCategoryIndex(null)
        setCurrentItemIndex(null)
    }

    return (
        <Stack>
            <Typography
                fontFamily={style[style?.SectionFontName] ?? 'Roboto'}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.accomplishments.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Box sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                {accomplishments.map((category: ISimpleCategory, categoryIndex: number) => (
                    <Box key={categoryIndex}>
                        <List>
                            {category.list.map((accomplishment: ISimpleListItem, accomplishmentIndex: number) => (
                                <ListItem
                                    key={accomplishmentIndex}
                                    sx={{ padding: '2px' }}>
                                    <Typography
                                        onClick={() => handleOpenModal(categoryIndex, accomplishmentIndex)}
                                        fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        color={style?.BodyFontColor ?? 'black'}
                                        letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                        {accomplishment.item}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ))}
            </Box>
            {currentCategoryIndex !== null && currentItemIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={750}
                    isAccomplishments={true}
                />
            )}
        </Stack>
    )
}

export default AccomplishmentsListComponent
