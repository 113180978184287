import { Box, Container, Grid2 } from '@mui/material'
import Stack from '@mui/material/Stack'
import React from 'react'
import ContactComponent from '../components/resume/contact/ContactComponent'
import NameComponent from '../components/resume/name/NameComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import SkillCirclesComponent from '../components/resume/simpleLists/SkillCirclesComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import BottomLeftImage from '../components/templates/images/bottom.png'
import TopLeftImage from '../components/templates/images/top-left.png'
import TopRightImage from '../components/templates/images/top-right.png'
import { IResume } from '../models/Resume'
import { AllDefaultCards, defaultCard } from '../utils/Util'

const ResumeOne = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume1.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack direction='column'>
            <Stack
                direction='row'
                fontSize={resume.style[resume.currentStyle].BodyFontSize}
                fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
                fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
                fontFamily={resume.style[resume.currentStyle].BodyFontName}
                marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
                columnGap={0}
                sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-46px',
                        right: '-44px',
                        width: '500px',
                        height: '665px',
                        backgroundImage: `url(${TopRightImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        zIndex: 0
                    }}>
                    <Box
                        sx={{
                            position: 'relative',
                            paddingTop: '150px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '70%',
                            width: '75%',
                            margin: 'auto'
                        }}>
                        <NameComponent />
                        <Box
                            sx={{
                                fontSize: '40px',
                                fontWeight: 300,
                                color: 'rgb(192, 171, 164)',
                                wordBreak: 'break-word',
                                zIndex: 2,
                                position: 'inherit'
                            }}>
                            <TitleComponent />
                        </Box>
                    </Box>
                </Box>
                <Stack
                    direction='column'
                    width='100%'
                    ml={2}
                    sx={{ backgroundColor: '#f7f9f9' }}>
                    <Container>
                        <Grid2
                            container
                            spacing={4}>
                            <Grid2
                                size={12}
                                container
                                alignItems='center'>
                                <Box>
                                    <Grid2
                                        container
                                        zIndex={2}>
                                        <Grid2 size={12}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '450px',
                                                    height: '600px'
                                                }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-23px',
                                                        left: '-56px',
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundImage: `url(${TopLeftImage})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        zIndex: 0
                                                    }}
                                                />
                                                <PictureComponent
                                                    picWidth={275}
                                                    picHeight={275}
                                                    picAlign={'center'}
                                                    picMT={5}
                                                    picMR={12}
                                                />
                                                <Box sx={{ paddingLeft: '25px', width: '300px', zIndex: 10 }}>
                                                    <SummaryComponent />
                                                </Box>
                                            </Box>
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Grid2>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '90%',
                                    margin: 'auto',
                                    position: 'relative',
                                    gap: '100px'
                                }}>
                                <Grid2
                                    size={12}
                                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 2 }}>
                                    <Box sx={{ marginLeft: '10px' }}>
                                        <SkillCirclesComponent />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            zIndex: 2,
                                            height: '350px',
                                            width: '350px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            paddingTop: '30px'
                                        }}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '-88px',
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: `url(${BottomLeftImage})`,
                                                backgroundSize: 'cover',
                                                zIndex: 0
                                            }}
                                        />
                                        <ContactComponent includeIcons={false} />
                                    </Box>
                                </Grid2>
                                <Box>
                                    <Stack
                                        direction='column'
                                        paddingLeft={3}
                                        paddingRight={3}
                                        sx={{ backgroundColor: '#f7f9f9', paddingTop: '16px', gap: '12px' }}>
                                        {orderUsedCards.map((value: defaultCard) => {
                                            return <value.cardComponent key={value.cardType} />
                                        })}
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid2>
                    </Container>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ResumeOne
