import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import SimpleCardComponent from './SimpleCardComponent'

const OtherComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <SimpleCardComponent
            card={euContext.resume.other}
            fieldName='other'
            sectionBgColor={sectionBgColor}
        />
    )
}

export default OtherComponent
