import { MenuItem, Select, type SelectChangeEvent, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IResume } from '../../models/Resume'
import { DateDelims, DateFormats, IStyles } from '../../models/Styles'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import FontNameSettingComponent from './FontNameSettingComponent'

const DateSettingComponent = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { mutate: updateResumeField } = useUpdateResumeField()

    const changeDateDelim = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const datedelim: DateDelims = newValue as DateDelims
        const dateField: string = `style.${resume.currentStyle}.DateDelim`

        updateResumeField(
            { id: resume._id, fieldName: dateField, fieldValue: datedelim },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeDateFormat = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const dateFormat: DateFormats = newValue as DateFormats
        const dateField: string = `style.${resume.currentStyle}.DateFormat`

        updateResumeField(
            { id: resume._id, fieldName: dateField, fieldValue: dateFormat },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <>
            <FontNameSettingComponent fieldName='Date' />
            <TableRow>
                <TableCell>
                    <Typography>Date Format</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style.DateFormat}
                        onChange={changeDateFormat}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(DateFormats).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Date Delim</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style.DateDelim}
                        onChange={changeDateDelim}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(DateDelims).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
        </>
    )
}

export default DateSettingComponent
