import { Box, CircularProgress, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleCategory, ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'

const SkillsCircularProgressComponent = ({
    sectionBgColor = '',
    labelTop = true,
    showCategoryName = true
}: {
    sectionBgColor?: string
    labelTop?: boolean
    showCategoryName?: boolean
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number | null>(null)

    const handleOpenModal = (categoryIndex: number, skillIndex: number) => {
        setCurrentCategoryIndex(categoryIndex)
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentCategoryIndex(null)
        setCurrentSkillIndex(null)
    }
    const skillsCategory = euContext.resume.skills.category as ISimpleCategory[]

    const showEmptyState = !euContext.resume.skills?.category || euContext.resume.skills.category.length === 0

    return (
        <Stack
            direction='column'
            spacing={2}>
            <Typography
                sx={{ textTransform: 'uppercase' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                bgcolor={sectionBgColor}>
                {euContext.resume.skills.label}
            </Typography>
            {showEmptyState && (
                <Stack
                    spacing={2}
                    onClick={() => handleOpenModal(0, 0)}>
                    <Typography
                        sx={{ textTransform: 'uppercase' }}
                        fontFamily={style[style.BodyFontName]}
                        fontSize={`${style?.BodyFontSize}px`}
                        fontWeight={style?.BodyFontWeight}>
                        Click to add Skills
                    </Typography>
                </Stack>
            )}
            {skillsCategory.map((category: ISimpleCategory, categoryIndex: number) => (
                <Stack
                    key={categoryIndex}
                    spacing={2}>
                    {showCategoryName && (
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize}px`}
                            fontWeight={style?.BodyFontWeight}>
                            {category.name}
                        </Typography>
                    )}
                    <List sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {category.list.map((skill: ISimpleListItem, skillIndex: number) => (
                            <ListItem
                                key={skill.item + skillIndex}
                                sx={{ width: 'auto', textAlign: 'center', cursor: 'pointer', margin: '10px' }}
                                onClick={() => handleOpenModal(categoryIndex, skillIndex)}>
                                <Stack
                                    direction='column'
                                    alignItems='center'>
                                    {labelTop && (
                                        <Typography
                                            fontFamily={style.BodyFontName}
                                            fontSize={`${style.BodyFontSize}px`}
                                            fontWeight={style.BodyFontWeight}
                                            sx={{ marginBottom: '10px' }}>
                                            {skill.item}
                                        </Typography>
                                    )}
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress
                                            variant='determinate'
                                            value={skill.rate * 20} // Scale the value out of 5 to 100
                                            sx={{ color: 'rgb(81, 127, 185)' }}
                                            thickness={6}
                                            size={30}
                                        />
                                        <CircularProgress
                                            variant='determinate'
                                            value={100}
                                            thickness={6}
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.1)',
                                                position: 'absolute',
                                                left: 0,
                                                zIndex: 0
                                            }}
                                            size={30}
                                        />
                                    </Box>
                                    {!labelTop && (
                                        <Typography
                                            fontFamily={style.BodyFontName}
                                            fontSize={`${style.BodyFontSize}px`}
                                            sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                                            {skill.item}
                                        </Typography>
                                    )}
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            ))}

            {currentCategoryIndex !== null && currentSkillIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isSkills={true}
                />
            )}
        </Stack>
    )
}

export default SkillsCircularProgressComponent
