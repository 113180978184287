import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { Stack, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IPhone } from '../../../models/Phone'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { AddressComponent } from './AddressComponent'

interface ContactInterface {
    includeLabel?: boolean
    includeSublabels?: boolean
    includeAddress?: boolean
    hideStreet?: boolean
    hideCity?: boolean
    hideState?: boolean
    hideZip?: boolean
    hideEmail?: boolean
    hidePhone?: boolean
    hideLinkedIn?: boolean
    sectionBgColor?: string
    includeIcons?: boolean
    iconOnRight?: boolean
}

const ContactContent = ({
    includeLabel,
    includeSublabels,
    includeAddress,
    hideState,
    hideCity,
    hideStreet,
    hideZip,
    hideEmail,
    hidePhone,
    hideLinkedIn,
    sectionBgColor,
    includeIcons,
    iconOnRight,
    onClick
}: ContactInterface & { onClick?: () => void }) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    return (
        <Stack
            sx={{
                zIndex: 2,
                cursor: onClick ? 'pointer' : 'default'
            }}
            direction='column'
            width='100%'
            onClick={onClick}>
            {includeLabel && (
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={`${style?.SectionFontSize ?? 5}px`}
                    fontStyle={style?.SectionFontStyle ?? 'initial'}
                    alignSelf={style?.SectionAlign ?? 'initial'}
                    textAlign={iconOnRight ? 'end' : style?.SectionAlign ?? 'initial'}
                    color={style?.SectionFontColor ?? 'initial'}
                    letterSpacing={`${style?.SectionFontLetterSpacing ?? 1}px`}
                    fontWeight={style?.SectionFontWeight ?? 'initial'}
                    marginBottom={`${style?.SpaceSectionTitle}px`}
                    textTransform={style?.SectionFontCase ?? 'none'}
                    width='100%'
                    bgcolor={sectionBgColor}
                    sx={{ zIndex: 4 }}>
                    {euContext.resume.personalInfo.label || 'Contact'}
                </Typography>
            )}
            {includeAddress && (
                <>
                    {includeSublabels && (
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'initial'}
                            textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'initial'}
                            color={style?.SectionFontColor ?? 'initial'}
                            letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                            fontWeight={style?.SectionFontWeight ?? 'initial'}
                            textTransform={style?.BodyFontCase ?? 'none'}
                            width='100%'
                            bgcolor={sectionBgColor}>
                            Address:
                        </Typography>
                    )}

                    <Stack
                        direction={iconOnRight ? 'row-reverse' : 'row'}
                        columnGap={1}
                        sx={{ verticalAlign: 'middle' }}>
                        {includeIcons && <MapOutlinedIcon sx={{ mr: iconOnRight ? 0 : 1, color: style.AddressFontColor ?? 'black' }} />}
                        <AddressComponent
                            hideCity={hideCity}
                            hideState={hideState}
                            hideStreet={hideStreet}
                            hideZip={hideZip}
                            textAlign={iconOnRight ? 'end' : style?.AddressAlign ?? 'end'}
                        />
                    </Stack>
                </>
            )}
            {!hideEmail && (
                <>
                    {includeSublabels && (
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'initial'}
                            textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'initial'}
                            color={style?.SectionFontColor ?? 'initial'}
                            letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                            fontWeight={style?.SectionFontWeight ?? 'initial'}
                            width='100%'
                            bgcolor={sectionBgColor}>
                            Email:
                        </Typography>
                    )}

                    <Stack
                        direction={iconOnRight ? 'row-reverse' : 'row'}
                        columnGap={1}
                        sx={{ verticalAlign: 'middle' }}>
                        {includeIcons && <EmailOutlinedIcon sx={{ mr: iconOnRight ? 0 : 1, color: style.BodyFontColor ?? 'black' }} />}
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            color={`${style.BodyFontColor ?? 'black'}`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                            alignSelf={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                            textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                            width='100%'>
                            {euContext.resume.personalInfo.email}
                        </Typography>
                    </Stack>
                </>
            )}
            {!hidePhone &&
                euContext.resume.personalInfo.phone.map((val: IPhone, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {includeSublabels && (
                                <Typography
                                    fontFamily={style[style.BodyFontName]}
                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                    alignSelf={style?.BodyAlign ?? 'initial'}
                                    textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'initial'}
                                    color={style?.SectionFontColor ?? 'initial'}
                                    letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                                    fontWeight={style?.SectionFontWeight ?? 'initial'}
                                    textTransform={style?.BodyFontCase ?? 'none'}
                                    width='100%'
                                    bgcolor={sectionBgColor}>
                                    Phone:
                                </Typography>
                            )}

                            <Stack
                                direction={iconOnRight ? 'row-reverse' : 'row'}
                                columnGap={1}
                                key={index}
                                lineHeight={1}
                                sx={{ verticalAlign: 'middle' }}>
                                {includeIcons && <CallOutlinedIcon sx={{ mr: iconOnRight ? 0 : 1, color: style.BodyFontColor ?? 'black' }} />}
                                <Typography
                                    fontFamily={style[style.BodyFontName]}
                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                    alignSelf={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                                    textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                                    width='100%'
                                    color={style.BodyFontColor ?? 'black'}
                                    sx={{ verticalAlign: 'middle' }}
                                    letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}
                                    display='inline'>
                                    {euContext.resume.personalInfo.phone[index].phone}
                                </Typography>
                            </Stack>
                        </React.Fragment>
                    )
                })}
            {!hideLinkedIn && (
                <>
                    {includeSublabels && (
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'initial'}
                            textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'initial'}
                            color={style?.SectionFontColor ?? 'initial'}
                            letterSpacing={`${style?.BodyFontLetterSpacing ?? 1}px`}
                            fontWeight={style?.SectionFontWeight ?? 'initial'}
                            textTransform={style?.BodyFontCase ?? 'none'}
                            width='100%'
                            bgcolor={sectionBgColor}>
                            LinkedIn:
                        </Typography>
                    )}

                    <Stack
                        direction={iconOnRight ? 'row-reverse' : 'row'}
                        columnGap={1}>
                        {includeIcons && <LinkOutlinedIcon sx={{ mr: iconOnRight ? 0 : 1, color: style.BodyFontColor ?? 'black' }} />}
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                            textAlign={iconOnRight ? 'end' : style?.BodyAlign ?? 'end'}
                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                            width='100%'
                            color={style.BodyFontColor ?? 'black'}
                            letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}>
                            {euContext.resume.personalInfo.linkedIn || 'Enter your LinkedIn profile id'}
                        </Typography>
                    </Stack>
                </>
            )}
        </Stack>
    )
}

const ContactComponent = ({
    includeLabel = true,
    includeSublabels = false,
    includeAddress = false,
    hideState = false,
    hideCity = false,
    hideStreet = false,
    hideZip = false,
    hideEmail = false,
    hidePhone = false,
    hideLinkedIn = false,
    sectionBgColor = '',
    includeIcons = true,
    iconOnRight = false
}: ContactInterface): JSX.Element => {
    const [isModalOpen, setModalOpen] = useState(false)
    const contactRef = useRef<HTMLDivElement | null>(null)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const editNames: string[] = []
    if (!hideEmail) editNames.push('personalInfo.email')
    if (!hidePhone) editNames.push('personalInfo.phone[0].phone')
    if (!hideLinkedIn) editNames.push('personalInfo.linkedIn')
    if (includeAddress && !hideStreet) editNames.push('personalInfo.address.street')
    if (includeAddress && !hideCity) editNames.push('personalInfo.address.city')
    if (includeAddress && !hideState) editNames.push('personalInfo.address.state')
    if (includeAddress && !hideZip) editNames.push('personalInfo.address.zip')

    return (
        <>
            <div ref={contactRef}>
                <ContactContent
                    includeLabel={includeLabel}
                    includeSublabels={includeSublabels}
                    includeAddress={includeAddress}
                    hideState={hideState}
                    hideCity={hideCity}
                    hideStreet={hideStreet}
                    hideZip={hideZip}
                    hideEmail={hideEmail}
                    hidePhone={hidePhone}
                    hideLinkedIn={hideLinkedIn}
                    sectionBgColor={sectionBgColor}
                    includeIcons={includeIcons}
                    iconOnRight={iconOnRight}
                    onClick={handleOpenModal}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={editNames}
                isAddress={includeAddress}
            />
        </>
    )
}

export default ContactComponent
