import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import { IResume } from '../models/Resume'

import { Box } from '@mui/material'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import { HorizontalLine, SmallCircle } from '../components/resume/misc/MiscComponents'
import NameComponentShade from '../components/resume/name/NameComponentShade'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'

const Resume20 = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Resume20.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack direction='column'>
            <Box
                width='100%'
                height='160px'
                bgcolor='#f5f5f5'
                marginBottom={5}
                paddingLeft={3}>
                <NameComponentShade
                    showInline={true}
                    firstNameColor={resume.style[resume.currentStyle].NameFontColor}
                    lastNameColor={resume.style[resume.currentStyle].NameFontColor}
                    boldLastName={true}
                />
                <TitleComponent />
                <Box
                    position='relative'
                    left={-20}
                    top={-75}>
                    <PictureComponent
                        picAlign='end'
                        picHeight={150}
                        picWidth={150}
                    />
                </Box>
            </Box>
            <Stack
                direction='row'
                columnGap={3}
                width='100%'
                marginTop={`${resume.style[resume.currentStyle].SpaceSection}px`}>
                <Stack
                    direction='column'
                    width='225px'
                    rowGap={0}
                    paddingLeft={3}>
                    <ContactComponent
                        includeLabel={false}
                        includeIcons={true}
                        includeAddress={true}
                    />
                    <Stack
                        direction='row'
                        width='225px'>
                        <HorizontalLine />
                        <SmallCircle left={5} />
                    </Stack>
                    <SkillsBulletListComponent />

                    <Stack
                        direction='row'
                        width='225px'>
                        <HorizontalLine />
                        <SmallCircle left={5} />
                    </Stack>
                    <EducationListComponent key='EducationListComponent' />
                </Stack>
                <Stack
                    direction='column'
                    rowGap={0}
                    borderLeft={1}
                    borderColor='lightgray'
                    width='590px'>
                    {orderUsedCards.map((value: defaultCard, index: number) => {
                        return (
                            <Box key={value.cardType + index}>
                                <Box paddingLeft={3}>
                                    <value.cardComponent />
                                </Box>
                                {index < orderUsedCards.length - 1 && (
                                    <Stack direction='row'>
                                        <SmallCircle left={-5} />
                                        <HorizontalLine />
                                    </Stack>
                                )}
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume20
