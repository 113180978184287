import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IStatements } from '../models/Statements'
import { getDistinctStatementCategoriesLink, getDistinctStatementJobTitlesLink, getStatementsByCategoryJobTitleLink, statementLink, statementsLink } from '../utils/Constants'

interface IStatementsResponse {
    statements: IStatements[]
}

interface IStatementResponse {
    statement: IStatements
}

const fetchStatements = async (): Promise<IStatementsResponse> => {
    const response = await axios.get<IStatementsResponse>(statementsLink)
    return response.data
}

const fetchStatement = async (id: string): Promise<IStatementResponse> => {
    const response = await axios.get<IStatementResponse>(statementLink(id))
    return response.data
}

const fetchDistinctStatementCategories = async (): Promise<string[]> => {
    const response = await axios.get(getDistinctStatementCategoriesLink)
    return response.data.categories
}

const fetchDistinctStatementJobTitles = async (category: string | undefined): Promise<string[]> => {
    const jobTitles: string[] = []

    if (category) {
        await axios
            .get(getDistinctStatementJobTitlesLink(category))
            .then((val) => {
                const results: (string | undefined)[] = val.data.jobTitles
                for (const res of results) {
                    if (res) {
                        jobTitles.push(res)
                    }
                }
            })
            .catch((err) => {
                console.error(JSON.stringify(err.response))
            })
    }
    return jobTitles
}

const fetchStatementsByCategoryJobTitle = async (category: string | undefined, jobTitle: string | undefined): Promise<IStatements[]> => {
    if (!category || !jobTitle) {
        return []
    }
    const response = await axios.get(getStatementsByCategoryJobTitleLink(category, jobTitle)).catch((err) => {
        console.error(JSON.stringify(err.response))
        return err.response
    })

    return response.data.statements
}

const addStatement = async (statement: IStatements) => {
    if (statement._id) {
        const response = await axios.patch(statementLink(statement._id), { statement })
        return response.data
    } else {
        const response = await axios.post(statementsLink, { statement })
        return response.data
    }
}

const removeStatement = async (statement: IStatements) => {
    const response = await axios.delete(statementLink(statement._id))
    return response.data
}

const updateStatement = async (statement: IStatements) => {
    const response = await axios.patch(statementLink(statement._id), { statement })
    return response.data
}

export const useStatements = () => {
    return useQuery<IStatementsResponse>({
        queryKey: ['statements'],
        queryFn: fetchStatements
    })
}

export const useStatement = (id: string) => {
    return useQuery<IStatementResponse>({
        queryKey: ['statement'],
        queryFn: () => fetchStatement(id)
    })
}

export const useDistinctStatementCategories = () => {
    return useQuery<string[]>({
        queryKey: ['distinctStatementCategories'],
        queryFn: fetchDistinctStatementCategories
    })
}

export const useDistinctStatementJobTitles = (category: string | undefined) => {
    return useQuery<string[]>({
        queryKey: ['distinctStatementJobTitles', category],
        queryFn: () => fetchDistinctStatementJobTitles(category),
        enabled: !!category
    })
}

export const useStatementsByCategoryJobTitle = (category: string | undefined, jobTitle: string | undefined) => {
    return useQuery<IStatements[]>({
        queryKey: ['statementsByCategoryJobTitle', category, jobTitle],
        queryFn: () => fetchStatementsByCategoryJobTitle(category, jobTitle),
        enabled: !!category && !!jobTitle,
        refetchInterval: 1000
    })
}

export const useAddStatement = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (Statement: IStatements) => addStatement(Statement),
        onError: (error) => {
            console.error('Error adding/updating Statement:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['statements'] })
            return data
        }
    })
}

export const useUpdateStatement = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (statement: IStatements) => updateStatement(statement),
        onError: (error) => {
            console.error('Error adding/updating Statement:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['statements'] })
            return data
        }
    })
}

export const useRemoveStatement = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (statement: IStatements) => removeStatement(statement),
        onError: (error) => {
            console.error('Error removing Statement:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['statements'] })
            return data
        }
    })
}
