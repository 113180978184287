import { Box, Stack, styled } from '@mui/material'
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { updatePictureResume } from '../../../utils/DBUtils'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
})

interface PictureRectangleProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picML?: number
    picMR?: number
}

const PictureRectangle = ({ picWidth = 300, picHeight = 200, picAlign = 'center', picMT = 0, picML = 0, picMR = 0 }: PictureRectangleProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [selectedImage, setSelectedImage] = useState<string>()
    const pictureRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (euContext.resume.picture) {
            const filePicture: string = euContext.resume.picture
            setSelectedImage(filePicture)
        } else {
            setSelectedImage('empty')
        }
    }, [euContext.resume])

    const handleChange = async (evt: FormEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const pictureFile = evt.currentTarget.files ? evt.currentTarget.files[0] : null
        if (pictureFile) {
            const pictureReader = new FileReader()
            pictureReader.readAsDataURL(pictureFile)

            pictureReader.onload = () => {
                const pictureResult: string = pictureReader.result as string
                if (pictureResult) {
                    updatePictureResume(euContext.resume._id, pictureResult)
                    setSelectedImage(pictureResult)
                }
            }

            pictureReader.onerror = () => {
                console.error('Picture error: ' + pictureReader.error)
            }
        }
    }

    return (
        <div ref={pictureRef}>
            <Stack
                spacing={0}
                sx={{ display: 'flex', alignItems: picAlign, cursor: 'pointer' }}>
                <Box
                    sx={{
                        width: picWidth,
                        height: picHeight,
                        backgroundColor: '#f0f0f0',
                        backgroundImage: `url(${selectedImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        mt: picMT,
                        ml: picML,
                        mr: picMR
                    }}
                    onClick={() => pictureRef.current?.querySelector('input')?.click()}>
                    <VisuallyHiddenInput
                        type='file'
                        accept='image/*'
                        onChange={handleChange}
                    />
                </Box>
            </Stack>
        </div>
    )
}

export default PictureRectangle
