import { array, boolean, date, number, object, string } from 'yup'

const CompanySchema = object().shape({
    title: string().required('Volunteer title is required'),
    companyName: string().required('Volunteer company is required'),
    startMonth: number(),
    startYear: number(),
    endMonth: number(),
    endYear: number(),
    summary: object().shape({ label: string(), description: string() }).optional(),
    location: string().required('Location is required'),
    isPresent: boolean()
})

const StyleSchema = object().shape({
    StyleName: string().required(),
    PrimaryFontName: string().required(),
    SecondaryFontName: string().required(),
    AlternateFontName: string().required(),
    NameFontName: string().required(),
    NameFontSize: number().required(),
    NameFontWeight: string().required(),
    NameFontStyle: string().required(),
    NameAlign: string().required(),
    NameFontCase: string().required(),
    NameFontColor: string().required(),
    TitleFontName: string().required(),
    TitleFontSize: number().required(),
    TitleFontWeight: string().required(),
    TitleFontStyle: string().required(),
    TitleAlign: string().required(),
    TitleFontCase: string().required(),
    TitleFontColor: string().required(),
    AddressFontName: string().required(),
    AddressFontSize: number().required(),
    AddressFontWeight: string().required(),
    AddressFontStyle: string().required(),
    AddressAlign: string().required(),
    AddressFontCase: string().required(),
    AddressFontColor: string().required(),
    SectionFontName: string().required(),
    SectionFontSize: number().required(),
    SectionFontWeight: string().required(),
    SectionFontStyle: string().required(),
    SectionAlign: string().required(),
    SectionFontCase: string().required(),
    SectionFontColor: string().required(),
    DateFontName: string().required(),
    DateFontSize: number().required(),
    DateFontWeight: string().required(),
    DateFontStyle: string().required(),
    DateAlign: string().required(),
    DateFontCase: string().required(),
    DateFontColor: string().required(),
    DateFormat: string().required(),
    DateDelim: string().required(),
    CompanyFontName: string().required(),
    CompanyFontSize: number().required(),
    CompanyFontWeight: string().required(),
    CompanyFontStyle: string().required(),
    CompanyAlign: string().required(),
    CompanyFontCase: string().required(),
    CompanyFontColor: string().required(),
    JobTitleFontName: string().required(),
    JobTitleFontSize: number().required(),
    JobTitleFontWeight: string().required(),
    JobTitleFontStyle: string().required(),
    JobTitleAlign: string().required(),
    JobTitleFontCase: string().required(),
    JobTitleFontColor: string().required(),
    BodyFontName: string().required(),
    BodyFontSize: number().required(),
    BodyFontWeight: string().required(),
    BodyFontStyle: string().required(),
    BodyAlign: string().required(),
    BodyFontCase: string().required(),
    BodyFontColor: string().required(),
    Special: string().required(),
    Alignment: string().required(),
    SpaceHeader: number().required(),
    SpaceSectionTitle: number().required(),
    SpaceCard: number().required(),
    SpaceSection: number().required(),
    SpaceBorder: number().required(),
    BorderLocation: string().required(),
    BorderStyle: string().required(),
    seqNum: number(),
    ShowGraphic: boolean().required()
})

const CardOrderSchema = object().shape({
    accomplishments: number().optional(),
    affiliations: number().optional(),
    capabilities: number().optional(),
    educations: number().optional(),
    employments: number().optional(),
    honors: number().optional(),
    licenses: number().optional(),
    objective: number().optional(),
    other: number().optional(),
    personal: number().optional(),
    professional: number().optional(),
    projects: number().optional(),
    publications: number().optional(),
    references: number().optional(),
    skills: number().optional(),
    summary: number().optional(),
    talents: number().optional(),
    trainings: number().optional(),
    volunteers: number().optional()
})

export const DashboardSchema = object().shape({
    resumeName: string().required('Resume name is required'),
    accomplishments: object().shape({
        label: string().required(),
        category: array(
            object().shape({
                name: string(),
                seqNum: number(),
                list: array(
                    object().shape({
                        item: string(),
                        rate: number(),
                        seqNum: number().required()
                    })
                )
            })
        ),
        seqNum: number().required()
    }),
    affiliations: object().shape({
        label: string().required('Affiliation label is required'),
        affiliation: array(CompanySchema)
    }),
    capabilities: object().shape({
        label: string().required(),
        category: array(
            object().shape({
                name: string(),
                seqNum: number(),
                list: array(
                    object().shape({
                        item: string(),
                        rate: number(),
                        seqNum: number().required()
                    })
                )
            })
        ),
        seqNum: number().required()
    }),
    educations: object().shape({
        label: string().required('Education label is required'),
        education: array(
            object().shape({
                degree: string().required('Education degree is required'),
                major: string().required('Education major is required'),
                minor: string().optional(),
                majorGPA: number().optional(),
                minorGPA: number().optional(),
                school: string().required('Education school is required'),
                graduationMonth: number(),
                graduationYear: number(),
                seqNum: number().required()
            })
        )
    }),
    employments: object().shape({
        label: string().required('Employment label is required'),
        employment: array(CompanySchema)
    }),
    honors: object().shape({
        label: string().required('Honor label is required'),
        honor: array(CompanySchema)
    }),
    licenses: object().shape({
        label: string().required('License label is required'),
        license: array(CompanySchema)
    }),
    objective: object().shape({ label: string().required('Label is required'), description: string().optional() }),
    other: object().shape({ label: string().required('Label is required'), description: string().optional() }),
    personal: object().shape({ label: string().required('Label is required'), description: string().optional() }),
    personalInfo: object().shape({
        email: string().required('Email is required').email('Email is invalid'),
        firstName: string().required('First name is required').max(50),
        lastName: string().required('Last name is required').max(50),
        linkedIn: string().required(),
        address: object().shape({
            street: string(),
            city: string(),
            state: string(),
            zip: string()
        }),
        phone: array(object().shape({ label: string(), phone: string().required() })),
        label: string().required('Label is required')
    }),
    professional: object().shape({ label: string().required('Label is required'), description: string().optional() }),
    projects: object().shape({
        label: string().required('Project label is required'),
        topics: array(
            object().shape({
                topic: string().required('Project topic is required'),
                description: string().required('Project description is required')
            })
        )
    }),
    publications: object().shape({
        label: string().required('Publication label is required'),
        publication: array(
            object().shape({
                pubMonth: number(),
                pubYear: number(),
                title: string().required('Publication title is required'),
                publisher: string().required('Publication publisher is required'),
                publications: string().required('Publications is required'),
                pages: number(),
                description: string(),
                seqNum: number().required()
            })
        )
    }),
    references: object().shape({
        label: string().required('Label is required'),
        reference: array(object().shape({ name: string().required('Reference Name is required'), email: string().required('Reference Email is required'), phone: number().optional() }))
    }),
    skills: object().shape({
        label: string().required(),
        category: array(
            object().shape({
                name: string(),
                seqNum: number(),
                list: array(
                    object().shape({
                        item: string(),
                        rate: number(),
                        seqNum: number().required()
                    })
                )
            })
        ),
        seqNum: number().required()
    }),
    summary: object().shape({ label: string().required('Label is required'), description: string().optional() }),
    talents: object().shape({
        label: string().required('Talent label is required'),
        talent: array(
            object().shape({
                production: string().required('Production is required'),
                role: string().required('Role is required'),
                producer: string().required('Producer is required'),
                seqNum: number().required()
            })
        )
    }),
    trainings: object().shape({
        label: string().required('Training label is required'),
        training: array(CompanySchema)
    }),
    volunteers: object().shape({
        label: string().required('Volunteer label is required'),
        volunteer: array(CompanySchema)
    }),
    title: string().optional(),
    createDate: date().required(),
    isPublic: boolean().required(),
    currentStyle: string().required(),
    style: object().shape({ Colorado: StyleSchema, Default: StyleSchema, Harvard: StyleSchema, Miami: StyleSchema }),
    picture: string().optional(),
    cardOrder: object().shape({
        Colorado: CardOrderSchema,
        Default: CardOrderSchema,
        Harvard: CardOrderSchema,
        Miami: CardOrderSchema
    })
})

export const LoginSchema = object().shape({
    email: string().required('Email is required').email('Email is invalid'),
    password: string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password must be at most 32 characters')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'Password must contain at least one letter and one number')
})
