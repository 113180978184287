import { IHTMLColorName } from './HTMLColors'

export enum client_types {
    School = 'School',
    Library = 'Library',
    Government = 'Government',
    Outplacement = 'Outplacement'
}

export interface IClients {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _id: any
    name: string
    type: client_types | undefined
    subdomain: string
    client_url: string
    default_email: string
    talent_community_id: string
    public_key: string
    private_key: string
    security_cert: string
    certificate_authority: string
    htmlColors: IHTMLColorName
    created: Date
    updated: Date
}
