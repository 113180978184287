import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import ProjectsDroppableComponent from '../resume/projects/ProjectsDroppableComponent'

const ShowProjects = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Projects Component (no params)</TableCell>
                    <TableCell>
                        <ProjectsDroppableComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Projects Component (section background color)</TableCell>
                    <TableCell>
                        <ProjectsDroppableComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Component 3</TableCell>
                    <TableCell>View 3</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowProjects
