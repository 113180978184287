import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleCard } from '../../../models/SimpleCard'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { markdownToPlainText } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const SimpleCardContent = ({
    showLabel,
    label,
    description,
    style,
    sectionBgColor,
    onClick,
    isHighlighted
}: {
    showLabel: boolean
    label: string
    description: string | undefined
    style: IStyles
    sectionBgColor?: string
    onClick?: () => void
    isHighlighted: boolean
}) => (
    <Stack
        spacing={0}
        onClick={onClick}
        border={isHighlighted ? '2px solid #1976d2' : 'none'}
        boxShadow={isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none'}
        borderRadius={isHighlighted ? '8px' : 'none'}
        padding={isHighlighted ? '10px' : '0'}
        marginBottom={`${style.SpaceCard}px`}
        zIndex={2}
        bgcolor={sectionBgColor}
        sx={{
            cursor: onClick ? 'pointer' : 'default'
        }}>
        {showLabel && (
            <>
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={`${style?.SectionFontSize ?? 5}px`}
                    fontStyle={style?.SectionFontStyle ?? 'initial'}
                    fontWeight={style?.SectionFontWeight ?? 'initial'}
                    alignSelf={style?.SectionAlign ?? 'initial'}
                    textAlign={style?.SectionAlign ?? 'initial'}
                    width='100%'
                    color={style?.SectionFontColor ?? 'black'}
                    letterSpacing={`${style?.SectionFontLetterSpacing ?? 0}px`}
                    marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                    lineHeight={1}
                    textTransform={style?.SectionFontCase ?? 'none'}
                    bgcolor={sectionBgColor}>
                    {label}
                </Typography>
                {style.SectionHRLine && (
                    <HorizontalLine
                        bgColor={style.SectionHRColor}
                        mb={style.SpaceSectionTitle}
                        mt={1}
                    />
                )}
            </>
        )}
        <Typography
            fontFamily={style[style.BodyFontName]}
            fontSize={`${style?.BodyFontSize ?? 5}px`}
            fontStyle={style?.BodyFontStyle ?? 'initial'}
            color={style?.BodyFontColor ?? 'black'}
            letterSpacing={`${style?.BodyFontLetterSpacing ?? 0}px`}
            fontWeight={style?.BodyFontWeight ?? 'initial'}
            dangerouslySetInnerHTML={{ __html: description || '' }}
        />
    </Stack>
)

const SimpleCardComponent = ({ showLabel = true, card, fieldName, sectionBgColor = '' }: { showLabel?: boolean; card: ISimpleCard; fieldName: string; sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [isModalOpen, setModalOpen] = useState(false)
    const names = [`${fieldName}.label`, `${fieldName}.description`]
    const simpleCardRef = useRef<HTMLDivElement | null>(null)
    const sanitizedDescription = markdownToPlainText(card.description || '')

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={simpleCardRef}>
                <SimpleCardContent
                    showLabel={showLabel}
                    label={card.label}
                    description={sanitizedDescription}
                    style={style}
                    sectionBgColor={sectionBgColor}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                names={names}
            />
        </>
    )
}

export default SimpleCardComponent
