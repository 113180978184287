import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import EditModal from '../../EditModal/EditModal'

const CityStateZipContentView = ({ isHighlighted, onClick }: { isHighlighted: boolean; onClick?: () => void }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { state, city, zip } = euContext.resume.personalInfo.address
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <Stack
            spacing={0}
            direction='row'
            textAlign={style?.NameAlign ?? 'end'}
            width='100%'
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0'
            }}>
            <Typography
                fontFamily={style[style.AddressFontName]}
                fontSize={`${style?.AddressFontSize ?? 5}px`}
                fontStyle={style?.AddressFontStyle ?? 'initial'}
                alignSelf={style?.AddressAlign ?? 'end'}
                fontWeight={style?.AddressFontWeight ?? 'initial'}
                width='100%'
                sx={{
                    color: style?.AddressFontColor ?? 'black',
                    letterSpacing: style.AddressFontLetterSpacing
                }}>
                {city}, {state} {zip}
            </Typography>
        </Stack>
    )
}

const CityStateZipComponent = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const names = ['personalInfo.address.city', 'personalInfo.address.state', 'personalInfo.address.zip']

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box textAlign={style?.NameAlign ?? 'end'}>
            <CityStateZipContentView
                isHighlighted={isModalOpen}
                onClick={handleOpenModal}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </Box>
    )
}

export default CityStateZipComponent
