import React, { useContext } from 'react'
import { Navigate } from 'react-router'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useTokens } from '../hooks/useTokens'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

interface IProtectedLogin {
    children: JSX.Element[]
}

export const ProtectedAdminRoute = (props: IProtectedLogin) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { data: tokenResponse, error: tokenError } = useTokens()

    if (!euContext.user?._id && !!tokenResponse?.user?._id) euContext.setUser(tokenResponse.user)
    if (!euContext.resume?._id && tokenResponse?.resume?._id) euContext.setResume(tokenResponse.resume)

    if (tokenError || (tokenResponse && !tokenResponse.user.isAdmin && !tokenResponse.user.isSuperAdmin)) {
        return (
            <Navigate
                to='/login'
                replace
            />
        )
    }

    if (tokenResponse?.accessToken && (euContext.user.isAdmin || euContext.user.isSuperAdmin)) {
        return props.children
    }
}
