import { Box, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import NameComponentShade from '../components/resume/name/NameComponentShade'
import OtherComponent from '../components/resume/simpleCards/OtherComponent'
import CapabilitiesBulletListComponent from '../components/resume/simpleLists/CapabilitiesBulletListComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import backgroundImage from '../images/background/Resume8_header.png'
import { IResume } from '../models/Resume'

const Resume8 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume8.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='column'
            rowGap={0}>
            <Box mb={style.SpaceHeader}>
                <img
                    src={backgroundImage}
                    style={{ width: '100%', height: '8vh' }}
                    alt='Background'
                />
                <NameComponentShade
                    mt={-25}
                    sx={{ letterSpacing: 10, display: 'inline' }}
                    lastNameColor='#e5e4e2'
                />
                <TitleComponent sx={{ letterSpacing: 10, color: '#e5e4e2' }} />
            </Box>
            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={3}>
                <Stack
                    direction='column'
                    width='275px'
                    paddingLeft={3}
                    paddingRight={3}
                    bgcolor='#e5e4e2'
                    rowGap={style.SpaceSection}>
                    <ContactComponent
                        includeLabel={true}
                        includeAddress={true}
                        hideState={false}
                        hideCity={false}
                        hideStreet={true}
                        hideZip={true}
                        hideEmail={false}
                        hideLinkedIn={false}
                        hidePhone={false}
                        includeIcons={true}
                    />
                    <EducationListComponent />
                    <CapabilitiesBulletListComponent />
                    <SkillsBulletListComponent />
                    <OtherComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='540px'
                    paddingLeft={0}
                    paddingRight={3}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return (
                            <Box
                                borderTop={1}
                                borderColor='lightgrey'
                                key={value.cardType}>
                                <value.cardComponent />
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume8
