import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import LicensesListComponent from '../resume/licenses/LicensesListComponent'

const ShowLicenses = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Licenses List Component (no params)</TableCell>
                    <TableCell>
                        <LicensesListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Licenses List Component (section background color)</TableCell>
                    <TableCell>
                        <LicensesListComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowLicenses
