import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import SimpleCardComponent from './SimpleCardComponent'

const ObjectiveComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <SimpleCardComponent
            card={euContext.resume.objective}
            fieldName='objective'
            sectionBgColor={sectionBgColor}
        />
    )
}

export default ObjectiveComponent
