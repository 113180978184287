import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogTitle, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@mui/material'
import React, { SyntheticEvent, useContext, useState } from 'react'
import Draggable from 'react-draggable'
import DateSettingComponent from '../../components/settings/DateSettingComponent'
import FontNameSettingComponent from '../../components/settings/FontNameSettingComponent'
import ResumeFontSettingComponent from '../../components/settings/ResumeFontSettingComponent'
import SectionSettingComponent from '../../components/settings/SectionSettingComponent'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import { ResumeStyles, validResumeStyles } from '../../utils/Constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PaperComponent = (props: any) => {
    return (
        <Draggable
            handle='.draggable-handle'
            cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

export interface EditStyleDialogProps {
    openEditStyle: boolean
    onCloseEditStyle: () => void
}

const EditStyleDialog = (props: EditStyleDialogProps) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const [tabIndex, setTabIndex] = useState<number>(0)
    const { openEditStyle, onCloseEditStyle } = props

    const onClose = (reason: string) => {
        if (reason !== 'backdropClick') {
            onCloseEditStyle()
        }
    }

    const handleSetDefault = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        evt.stopPropagation()

        const styleName: validResumeStyles = euContext.resume.currentStyle
        const newStyle: IStyles = ResumeStyles[styleName]

        updateResumeField(
            { id: euContext.resume._id, fieldName: `style.${styleName}`, fieldValue: newStyle },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <Dialog
            open={openEditStyle}
            disableEscapeKeyDown={true}
            disablePortal={true}
            PaperComponent={PaperComponent}
            onClose={() => onClose('buttonClick')}
            sx={{ left: '200px', cursor: 'grab' }}>
            <DialogTitle className='draggable-handle'>
                Edit Style
                <IconButton
                    aria-label='close'
                    onClick={() => onClose('buttonClick')}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Tabs
                value={tabIndex}
                variant='scrollable'
                visibleScrollbar
                onChange={(event: SyntheticEvent, newIndex: number) => {
                    setTabIndex(newIndex)
                }}>
                <Tab label='Resume' />
                <Tab label='Name' />
                <Tab label='Address' />
                <Tab label='Section' />
                <Tab label='Company' />
                <Tab label='Job Title' />
                <Tab label='Body' />
                <Tab label='Date' />
                <Tab label='Title' />
            </Tabs>
            <TableContainer
                component={Paper}
                sx={{ height: 660, overflowY: 'auto', minWidth: 600 }}>
                <Table>
                    <TableBody>
                        {tabIndex === 0 && <ResumeFontSettingComponent />}
                        {tabIndex === 1 && <FontNameSettingComponent fieldName='Name' />}
                        {tabIndex === 2 && <FontNameSettingComponent fieldName='Address' />}
                        {tabIndex === 3 && <SectionSettingComponent />}
                        {tabIndex === 4 && <FontNameSettingComponent fieldName='Company' />}
                        {tabIndex === 5 && <FontNameSettingComponent fieldName='JobTitle' />}
                        {tabIndex === 6 && <FontNameSettingComponent fieldName='Body' />}
                        {tabIndex === 7 && <DateSettingComponent />}
                        {tabIndex === 8 && <FontNameSettingComponent fieldName='Title' />}

                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align='right'>
                                <Button onClick={handleSetDefault}>Set Default</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Dialog>
    )
}

export default EditStyleDialog
