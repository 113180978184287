export const Degrees = [
    { value: '', label: '' },
    { value: 'md', label: 'Doctor' },
    { value: 'ms', label: 'Masters' },
    { value: 'MBA', label: 'MBA' },
    { value: 'bs', label: 'Bachelor of Science' },
    { value: 'ba', label: 'Bachelor of Arts' },
    { value: 'associate', label: 'Associate' },
    { value: 'certification', label: 'Certificate' },
    { value: 'highschool', label: 'High School' }
]

export const ValuesOfHighSchoolDegrees = ['highschool', 'hs', 'highSchoolOrEquivalent']
export const ValuesOfAssociateDegrees = ['associate', 'associates']
export const ValuesOfBSDegrees = ['bs', 'b.s.', 'ba', 'b.a', 'BAc', 'BSc', 'bachelors']
export const ValuesOfMSDegrees = ['ms', 'm.s.', 'ma', 'm.a.', 'MAc', 'MSc', 'masters']
export const ValuesOfMDDegrees = ['md', 'm.d.', 'MDc']

export interface IEducation {
    school: string
    degree: string
    major: string
    minor?: string
    majorGPA?: number
    minorGPA?: number
    graduationMonth: number
    graduationYear: number
    seqNum: number
}
