import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Divider, Drawer, IconButton, Stack, Typography, styled } from '@mui/material'
import React, { useContext } from 'react'
import { Header } from './components/Header'
import { EmploymentUniverseContext } from './context/EmploymentUniverseContext'
import { AppNavigation } from './navigation/AppNavigation'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: '816px',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })
}))

const DrawerHeader = styled('div')<{ backgroundColor: string; color: string }>(({ theme, backgroundColor, color }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    zIndex: 2,
    backgroundColor,
    color
}))

interface IAppLayout {
    children: JSX.Element
}

export const AppLayout = (props: IAppLayout) => {
    const euContext = useContext(EmploymentUniverseContext)

    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex', position: 'relative', height: '100vh', width: '840px' }}>
            <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{
                    position: 'absolute',
                    top: 34,
                    left: 10,
                    width: 48,
                    height: 48,
                    m: 2,
                    color: euContext.htmlColors?.navigationBar.textColor,
                    '&:hover': {
                        color: euContext.htmlColors?.navigationBar.hoverBackgroundColor
                    },
                    ...(open && { display: 'none' })
                }}>
                <MenuIcon />
            </IconButton>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: euContext.htmlColors?.navigationBar.backgroundColor,
                        color: euContext.htmlColors?.navigationBar.textColor
                    }
                }}
                variant='persistent'
                anchor='left'
                open={open}>
                <DrawerHeader
                    backgroundColor={euContext.htmlColors?.navigationBar.backgroundColor || ''}
                    color={euContext.htmlColors?.navigationBar.textColor || ''}>
                    <IconButton
                        onClick={handleDrawerClose}
                        sx={{ color: euContext.htmlColors?.navigationBar.textColor }}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography
                        variant='body1'
                        sx={{ ml: 1, display: open ? 'block' : 'none' }}>
                        Back
                    </Typography>
                </DrawerHeader>
                <Divider sx={{ backgroundColor: euContext.htmlColors?.navigationBar.hoverBackgroundColor }} />
                <AppNavigation key='AppNavigation' />
            </Drawer>
            <Main open={open}>
                <Header />
                <Stack
                    alignItems='center'
                    justifyContent='center'>
                    {props.children}
                </Stack>
            </Main>
        </Box>
    )
}
