import { Switch, TableCell, TableRow, Typography } from '@mui/material'
import React, { ChangeEvent, useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IResume } from '../../models/Resume'
import { IStyles } from '../../models/Styles'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import SelectColorComponent from '../SelectColorComponent'
import FontNameSettingComponent from './FontNameSettingComponent'

const SectionSettingComponent = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { mutate: updateResumeField } = useUpdateResumeField()

    const changeHRLine = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue: boolean = event.target.checked

        const resume: IResume = euContext.resume
        const HRLineField: string = `style.${resume.currentStyle}.SectionHRLine`

        updateResumeField(
            { id: resume._id, fieldName: HRLineField, fieldValue: newValue },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeColor = async (color: string, fieldName: string) => {
        const resume: IResume = euContext.resume
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: color },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <>
            <FontNameSettingComponent fieldName='Section' />
            <TableRow>
                <TableCell>
                    <Typography>Section HR Line</Typography>
                </TableCell>
                <TableCell>
                    <Switch
                        checked={style.SectionHRLine}
                        onChange={changeHRLine}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>Section HR Color</Typography>
                </TableCell>
                <TableCell>
                    <SelectColorComponent
                        value={style.SectionHRColor as string}
                        handleChange={(newColor) => changeColor(newColor, 'SectionHRColor')}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

export default SectionSettingComponent
