import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import SimpleCardComponent from './SimpleCardComponent'

const SummaryComponent = ({ sectionBgColor = '', showLabel = true }: { sectionBgColor?: string; showLabel?: boolean }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <SimpleCardComponent
            card={euContext.resume.summary}
            fieldName='summary'
            sectionBgColor={sectionBgColor}
            showLabel={showLabel}
        />
    )
}

export default SummaryComponent
