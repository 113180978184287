import { Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { Degrees, IEducation } from '../../../models/Education'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
}

const EducationCondensedListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEducation: IEducation[] = euContext.resume.educations.education.sort((a, b) => a.graduationYear - b.graduationYear)
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack marginBottom={style.SpaceCard}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.educations.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Table
                component={Paper}
                elevation={0}
                padding='none'
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none'
                    }
                }}>
                <TableBody>
                    {orderedEducation.map((ed: IEducation, index: number) => {
                        const degreeLabel: string = Degrees.find((d) => d.value === ed.degree)?.label || ''

                        return (
                            <TableRow
                                onClick={() => openEditModal(index)}
                                key={index}>
                                <TableCell
                                    align='left'
                                    width='max-content'>
                                    <Typography
                                        width='max-content'
                                        fontFamily={style[style.BodyFontName]}
                                        fontSize={`${style.BodyFontSize}px`}
                                        fontStyle={style.BodyFontStyle}
                                        fontWeight={style.BodyFontWeight}
                                        textAlign={style.BodyAlign}
                                        color={style.BodyFontColor}
                                        letterSpacing={`${style.BodyFontLetterSpacing}px`}
                                        sx={{ cursor: 'pointer' }}>
                                        {degreeLabel}&nbsp;-&nbsp;{ed.school}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align='right'
                                    width='100%'>
                                    <Typography
                                        fontFamily={style[style.DateFontName]}
                                        fontSize={`${style.DateFontSize}px`}
                                        fontStyle={style.DateFontStyle}
                                        fontWeight={style.DateFontWeight}
                                        textAlign='end'
                                        color={style.DateFontColor}
                                        letterSpacing={`${style.DateFontLetterSpacing}px`}>
                                        {format(new Date(ed.graduationYear, ed.graduationMonth - 1), style.DateFormat)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isEducation={true}
                />
            )}
        </Stack>
    )
}

export default EducationCondensedListComponent
