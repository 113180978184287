import Stack from '@mui/material/Stack'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import { PersonalDetailComponent } from '../components/resume/contact/PersonalDetailsComponent'
import NameComponent from '../components/resume/name/NameComponent'
import PictureComponent from '../components/resume/picture/PictureComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import { IResume } from '../models/Resume'

const DefaultResume = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Default.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack
            direction='row'
            fontSize={resume.style[resume.currentStyle].BodyFontSize}
            fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
            fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
            fontFamily={resume.style[resume.currentStyle].BodyFontName}
            marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
            columnGap={2}>
            <Stack
                direction='column'
                width='245px'
                rowGap={`${resume.style[resume.currentStyle].SpaceSection}px`}>
                <PictureComponent />
                <SummaryComponent />
                <PersonalDetailComponent />
            </Stack>
            <Stack
                direction='column'
                width='570px'>
                <NameComponent />
                {orderUsedCards.map((value: defaultCard) => {
                    return <value.cardComponent key={value.cardType} />
                })}
            </Stack>
        </Stack>
    )
}

export default DefaultResume
