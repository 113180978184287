import { Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { Degrees, IEducation } from '../../../models/Education'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
}

const EducationListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEducation: IEducation[] = euContext.resume.educations.education.sort((a, b) => a.graduationYear - b.graduationYear)

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.educations.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                rowGap={1}>
                {orderedEducation.map((ed: IEducation, index: number) => {
                    const degreeLabel: string = Degrees.find((d) => d.value === ed.degree)?.label || ''

                    return (
                        <Stack
                            direction='column'
                            onClick={() => openEditModal(index)}
                            key={index}>
                            <Typography
                                fontFamily={style[style.CompanyFontName]}
                                fontSize={`${style.CompanyFontSize}px`}
                                fontStyle={style.CompanyFontStyle}
                                fontWeight={style.CompanyFontWeight}
                                textAlign={style.CompanyAlign}
                                color={style.CompanyFontColor}
                                letterSpacing={`${style.CompanyFontLetterSpacing}px`}
                                sx={{ cursor: 'pointer' }}>
                                {degreeLabel}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}
                                color={style.BodyFontColor}
                                letterSpacing={`${style.BodyFontLetterSpacing}px`}>
                                {ed.major}
                                {ed.majorGPA ? ' | ' + ed.majorGPA : ''}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}
                                color={style.BodyFontColor}
                                letterSpacing={`${style.BodyFontLetterSpacing}px`}>
                                {ed.school}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}
                                color={style.BodyFontColor}
                                letterSpacing={`${style.BodyFontLetterSpacing}px`}>
                                {ed.graduationYear}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isEducation={true}
                />
            )}
        </Stack>
    )
}

export default EducationListComponent
