import { Grid2 } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { Degrees } from '../../../models/Education'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const EducationSummaryComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [isModalOpen, setModalOpen] = useState(false)

    const openEditModal = () => {
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
    }

    const educationList = euContext.resume.educations.education.sort((a, b) => b.graduationYear - a.graduationYear)

    return (
        <Stack onClick={openEditModal}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                bgcolor={sectionBgColor}
                width='100%'
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.educations.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Box width='100%'>
                {educationList.map((education, educationIndex) => (
                    <Grid2
                        key={educationIndex}
                        container
                        spacing={0}
                        columns={12}
                        gap={2}
                        sx={{ marginBottom: 2 }}>
                        <Grid2
                            display='flex'
                            justifyContent='left'
                            alignItems='left'
                            size={5}>
                            <Typography
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                color={style?.BodyFontColor ?? 'black'}
                                letterSpacing={style?.BodyFontLetterSpacing ?? 1}>
                                {Degrees.find((d) => d.value === education.degree)?.label || ''}
                            </Typography>
                        </Grid2>
                        <Grid2
                            display='flex'
                            justifyContent='right'
                            alignItems='right'
                            textAlign='right'
                            size={5}>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                sx={{
                                    textTransform: style?.BodyFontCase ?? 'none',
                                    color: style?.BodyFontColor ?? 'black',
                                    letterSpacing: `${style?.BodyFontLetterSpacing ?? 1}px`,
                                    textAlign: style?.BodyAlign ?? 'initial'
                                }}>
                                {education.major}
                            </Typography>
                        </Grid2>
                        <Grid2
                            display='flex'
                            justifyContent='left'
                            alignItems='left'
                            size={5}>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                sx={{
                                    textTransform: style?.BodyFontCase ?? 'none',
                                    color: style?.BodyFontColor ?? 'black',
                                    letterSpacing: `${style?.BodyFontLetterSpacing ?? 1}px`,
                                    textAlign: style?.BodyAlign ?? 'initial'
                                }}>
                                {education.school}
                            </Typography>
                        </Grid2>
                        <Grid2
                            display='flex'
                            justifyContent='right'
                            alignItems='left'
                            size={5}>
                            <Typography
                                fontSize={`${style?.DateFontSize ?? 5}px`}
                                fontStyle={style?.DateFontStyle ?? 'initial'}
                                alignSelf={style?.DateAlign ?? 'initial'}
                                fontWeight={style?.DateFontWeight ?? 'initial'}
                                sx={{
                                    textTransform: style?.DateFontCase ?? 'none',
                                    color: style?.DateFontColor ?? 'black',
                                    letterSpacing: `${style?.DateFontLetterSpacing ?? 1}px`,
                                    textAlign: style?.DateAlign ?? 'initial'
                                }}>
                                {format(new Date(education.graduationYear, education.graduationMonth - 1), style.DateFormat)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                ))}
            </Box>

            <EditModal
                open={isModalOpen}
                onClose={closeEditModal}
                isEducation={true}
            />
        </Stack>
    )
}

export default EducationSummaryComponent
