import { List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleList, ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { HorizontalLine } from '../misc/MiscComponents'

export const BulletListComponent = ({
    list,
    sectionBgColor = '',
    boldCategory = false,
    alignRight = false,
    onItemClick
}: {
    list: ISimpleList
    sectionBgColor?: string
    boldCategory?: boolean
    alignRight?: boolean
    onItemClick?: (categoryIndex: number, itemIndex: number) => void
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]

    const orderedSkills = (index: number) => (list.category && list.category[index].list ? list.category[index].list?.sort((a, b) => a.seqNum - b.seqNum) : ([] as ISimpleListItem[]))

    return (
        <Stack
            direction='column'
            marginBottom={`${style.SpaceCard}px`}>
            <Typography
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={alignRight ? 'end' : style?.SectionAlign ?? 'initial'}
                textAlign={alignRight ? 'end' : style?.SectionAlign ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}>
                {list.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                sx={{ display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                {list.category.map((category, categoryIndex) => (
                    <Stack
                        key={category.name + categoryIndex}
                        direction='column'>
                        {category.name && category.name.length > 0 && (
                            <Typography
                                suppressContentEditableWarning={true}
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                fontWeight={boldCategory ? 'bold' : style?.BodyFontWeight ?? 'initial'}
                                alignSelf={alignRight ? 'end' : style?.BodyAlign ?? 'initial'}
                                textAlign={alignRight ? 'end' : style?.BodyAlign ?? 'initial'}
                                color={style?.BodyFontColor ?? 'black'}
                                letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                                width='100%'>
                                {category.name}
                            </Typography>
                        )}

                        <List sx={{ listStyleType: 'disc', paddingBottom: 0, paddingTop: 0 }}>
                            {orderedSkills(categoryIndex).map((item: ISimpleListItem, itemIndex: number) => (
                                <ListItem
                                    sx={{
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        display: 'list-item',
                                        ml: 2,
                                        textAlign: alignRight ? 'end' : 'initial',
                                        alignContent: alignRight ? 'end' : 'initial'
                                    }}
                                    key={item.item + itemIndex}
                                    onClick={() => onItemClick && onItemClick(categoryIndex, itemIndex)}>
                                    <Typography
                                        suppressContentEditableWarning={true}
                                        fontFamily={style[style.BodyFontName]}
                                        fontSize={`${style?.BodyFontSize}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                                        color={style.BodyFontColor ?? 'black'}
                                        letterSpacing={`${style.BodyFontLetterSpacing ?? 0}px`}>
                                        {item.item}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
