import { type SearchForSuggestionsResult } from '@aws-sdk/client-location'
import { Autocomplete, CircularProgress, FormControl, TextField, type TextFieldProps } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type ModalTextFieldProps = {
    name: string
    label: string
    isAddressField?: boolean
    onLocationSelect?: (placeId: string) => Promise<void>
    onLocationSuggestions?: (inputValue: string) => void
    locationOptions?: SearchForSuggestionsResult[]
    loadingSuggestions?: boolean
} & TextFieldProps

const ModalTextField = ({ name, label, isAddressField, onLocationSelect, onLocationSuggestions, locationOptions, loadingSuggestions }: ModalTextFieldProps): JSX.Element => {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return isAddressField ? (
                    <FormControl
                        size='small'
                        error={!!error}>
                        <Autocomplete
                            freeSolo
                            value={value}
                            options={locationOptions?.map((option) => option.Text) ?? []}
                            onInputChange={(_, newValue) => onLocationSuggestions && onLocationSuggestions(newValue)}
                            onChange={async (_, selectedValue) => {
                                if (onLocationSelect && selectedValue) {
                                    const chosenOption = locationOptions?.filter((option) => option.Text === selectedValue)[0]
                                    if (chosenOption?.PlaceId) {
                                        onLocationSelect(chosenOption.PlaceId)
                                    }
                                }
                            }}
                            loading={loadingSuggestions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ marginTop: 2 }}
                                    label={label}
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    variant='outlined'
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingSuggestions && (
                                                    <CircularProgress
                                                        color='inherit'
                                                        size={20}
                                                    />
                                                )}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                ) : (
                    <FormControl
                        size='small'
                        error={!!error}>
                        <TextField
                            value={value}
                            onChange={onChange}
                            label={label}
                            variant='outlined'
                            size='small'
                            error={!!error}
                            sx={{ marginTop: 2, marginBottom: 2 }}
                        />
                    </FormControl>
                )
            }}
        />
    )
}

export default ModalTextField
