import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

const LoadingState = ({ message = 'Loading...' }: { message?: string }) => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100vh'>
            <CircularProgress />
            <Typography
                variant='h6'
                color='textSecondary'
                style={{ marginTop: 16 }}>
                {message}
            </Typography>
        </Box>
    )
}

export default LoadingState
