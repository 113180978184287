import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import EditModal from '../../EditModal/EditModal'

const HorizontalContactContentView = ({
    includeAddress,
    includePhone,
    includeEmail,
    includeLinkedin,
    includeIcons,
    isHighlighted,
    delim,
    columnGap,
    onClick,
    sx
}: {
    includeAddress: boolean
    includePhone: boolean
    includeEmail: boolean
    includeLinkedin: boolean
    includeIcons: boolean
    isHighlighted: boolean
    delim: string
    columnGap: number
    onClick?: () => void
    sx: object
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { state, city, zip } = euContext.resume.personalInfo.address
    const address = `${city}, ${state} ${zip}`
    const phone = euContext.resume.personalInfo.phone[0].phone
    const email = euContext.resume.personalInfo.email
    const linkedin = euContext.resume.personalInfo.linkedIn
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <Stack
            spacing={0}
            direction='row'
            alignSelf={style?.NameAlign ?? 'end'}
            justifyContent={style.NameAlign.startsWith('center') ? 'center' : `flex-${style.NameAlign}`}
            width='100%'
            columnGap={columnGap}
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0',
                ...sx
            }}>
            {includeAddress && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {includeIcons && (
                        <>
                            <MapOutlinedIcon
                                htmlColor={style.TitleFontColor}
                                sx={{ height: `${style.AddressFontSize}px`, width: `${style.AddressFontSize}px`, verticalAlign: 'middle' }}
                            />
                            &nbsp;
                        </>
                    )}

                    {address}
                </Typography>
            )}

            {includeAddress && includePhone && delim && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {delim}
                </Typography>
            )}

            {includePhone && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {includeIcons && (
                        <>
                            <CallOutlinedIcon
                                htmlColor={style.TitleFontColor}
                                sx={{ height: `${style.AddressFontSize}px`, width: `${style.AddressFontSize}px`, verticalAlign: 'middle' }}
                            />
                            &nbsp;
                        </>
                    )}

                    {phone}
                </Typography>
            )}
            {(includeAddress || includePhone) && includeEmail && delim && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {(includeAddress || includePhone) && includeEmail ? `${delim} ` : ''}
                </Typography>
            )}

            {includeEmail && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {includeIcons && (
                        <>
                            <EmailOutlinedIcon
                                htmlColor={style.TitleFontColor}
                                sx={{ height: `${style.AddressFontSize}px`, width: `${style.AddressFontSize}px`, verticalAlign: 'middle' }}
                            />
                            &nbsp;
                        </>
                    )}

                    {email}
                </Typography>
            )}
            {(includeAddress || includePhone || includeEmail) && includeLinkedin && delim && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {delim}
                </Typography>
            )}
            {includeLinkedin && (
                <Typography
                    fontFamily={style[style.AddressFontName]}
                    fontSize={`${style?.AddressFontSize ?? 5}px`}
                    fontStyle={style?.AddressFontStyle ?? 'initial'}
                    alignSelf={style?.AddressAlign ?? 'end'}
                    fontWeight={style?.AddressFontWeight ?? 'initial'}
                    color={style?.AddressFontColor ?? 'black'}
                    letterSpacing={style.AddressFontLetterSpacing}
                    sx={sx}>
                    {includeIcons && (
                        <>
                            <LinkOutlinedIcon
                                htmlColor={style.TitleFontColor}
                                sx={{ height: `${style.AddressFontSize}px`, width: `${style.AddressFontSize}px`, verticalAlign: 'middle' }}
                            />
                            &nbsp;
                        </>
                    )}

                    {linkedin}
                </Typography>
            )}
        </Stack>
    )
}

const HorizontalContactComponent = ({
    sx = {},
    includeAddress = true,
    includePhone = true,
    includeEmail = true,
    includeLinkedin = false,
    includeIcons = false,
    delim = '|',
    columnGap = 1
}: {
    sx?: object
    includeAddress?: boolean
    includePhone?: boolean
    includeEmail?: boolean
    includeLinkedin?: boolean
    includeIcons?: boolean
    delim?: string
    columnGap?: number
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const names: string[] = []
    if (includeAddress) names.push('personalInfo.address.city', 'personalInfo.address.state', 'personalInfo.address.zip')
    if (includePhone) names.push('personalInfo.phone.0.phone')
    if (includeEmail) names.push('personalInfo.email')
    if (includeLinkedin) names.push('personalInfo.linkedIn')

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box textAlign={style?.NameAlign ?? 'end'}>
            <HorizontalContactContentView
                isHighlighted={isModalOpen}
                onClick={handleOpenModal}
                sx={sx}
                includeAddress={includeAddress}
                includePhone={includePhone}
                includeEmail={includeEmail}
                includeLinkedin={includeLinkedin}
                includeIcons={includeIcons}
                delim={delim}
                columnGap={columnGap}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </Box>
    )
}

export default HorizontalContactComponent
