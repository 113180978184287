import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import AccomplishmentsBulletListComponent from '../resume/simpleLists/AccomplishmentsBulletListComponent'
import AccomplishmentsListComponent from '../resume/simpleLists/AccomplishmentsListComponent'
import AccomplishmentsRateLineComponent from '../resume/simpleLists/AccomplishmentsRateLineComponent'
import CapabilitiesBulletListComponent from '../resume/simpleLists/CapabilitiesBulletListComponent'
import CapabilitiesListComponent from '../resume/simpleLists/CapabilitiesListComponent'
import SkillCirclesComponent from '../resume/simpleLists/SkillCirclesComponent'
import SkillsBlockListComponent from '../resume/simpleLists/SkillsBlockListComponent'
import SkillsBubbleListComponent from '../resume/simpleLists/SkillsBubbleListComponent'
import SkillsBulletListComponent from '../resume/simpleLists/SkillsBulletListComponent'
import SkillsCircularProgressComponent from '../resume/simpleLists/SkillsCircularProgressComponent'
import SkillsHorizontalListComponent from '../resume/simpleLists/SkillsHorizontalListComponent'
import SkillsListRateComponent from '../resume/simpleLists/SkillsListRateComponent'
import SkillsRateLineComponent from '../resume/simpleLists/SkillsRateLineComponent'

const ShowSimpleLists = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Accomplishments Bullet List Component</TableCell>
                    <TableCell>
                        <AccomplishmentsBulletListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Accomplishments List Component</TableCell>
                    <TableCell>
                        <AccomplishmentsListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Accomplishments Rate Line Component</TableCell>
                    <TableCell>
                        <AccomplishmentsRateLineComponent gridSize={6} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Capabilities Bullet List Component</TableCell>
                    <TableCell>
                        <CapabilitiesBulletListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Capabilities List Component</TableCell>
                    <TableCell>
                        <CapabilitiesListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Block List Component</TableCell>
                    <TableCell>
                        <SkillsBlockListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Circles Component</TableCell>
                    <TableCell>
                        <SkillCirclesComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Rate Line Component</TableCell>
                    <TableCell>
                        <SkillsRateLineComponent gridSize={6} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Bubble List Component</TableCell>
                    <TableCell>
                        <SkillsBubbleListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Circular Progress Component</TableCell>
                    <TableCell>
                        <SkillsCircularProgressComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Bullet List Component</TableCell>
                    <TableCell>
                        <SkillsBulletListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Horizontal List Component</TableCell>
                    <TableCell>
                        <SkillsHorizontalListComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skills Rate List Component</TableCell>
                    <TableCell>
                        <SkillsListRateComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowSimpleLists
