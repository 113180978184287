import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { askGoogleAILink, askOpenAILink } from '../utils/Constants'

interface AIResponse {
    answers: string[]
}

const fetchAIResponse = async (question: string): Promise<AIResponse> => {
    const response = await axios.post<AIResponse>(askOpenAILink, { question })
    return response.data
}

const askGoogleAI = async (question: string): Promise<string[]> => {
    const response = await axios.post<{ answers: string }>(askGoogleAILink, { question })
    const answers = response.data.answers.trim().split('\n')
    return answers
}

export const useAIResponse = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: fetchAIResponse,
        onSuccess: (data) => {
            queryClient.setQueryData(['aiResponse'], data)
        }
    })
}

export const useAskGoogleAI = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: askGoogleAI,
        onSuccess: (data) => {
            queryClient.setQueryData(['aiResponse'], data)
        }
    })
}
