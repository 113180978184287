import { Box, Button, Checkbox, InputLabel, Modal, TextareaAutosize, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useUpdateStatement } from '../../hooks/useStatements'
import { IStatements } from '../../models/Statements'
import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'

interface EditStatementComponentProps {
    openEditStatement: boolean
    setOpenEditStatement: Function
    statementIndex: number
    setStatementIndex: Function
    statements: IStatements[]
    setStatements: Function
}

const EditStatementComponent = (props: EditStatementComponentProps) => {
    const [myStatement, setMyStatement] = useState<IStatements>()
    const { mutate: updateStatement, isPending: isUpdating, error: updateError } = useUpdateStatement()

    useEffect(() => {
        setMyStatement(props.statements[props.statementIndex])
    }, [props.statementIndex, props.statements])

    const doClose = () => {
        const newStatements: IStatements[] = props.statements.slice()

        if (myStatement && props.statementIndex > -1) {
            newStatements[props.statementIndex] = myStatement
            props.setStatements(newStatements)
        }
        props.setStatementIndex(-1)
        props.setOpenEditStatement(false)
    }

    const onClose = (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
            props.setOpenEditStatement(false)
        }
    }

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement> | undefined, name: string) => {
        const value = event?.currentTarget.checked

        if (myStatement) {
            setMyStatement({ ...myStatement, [name]: value })
        }
    }

    const onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (myStatement) {
            updateStatement(myStatement)
            doClose()
        }
    }

    return (
        <Modal
            open={props.openEditStatement}
            onClose={onClose}>
            <Box
                sx={{
                    width: 600,
                    height: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflow: 'auto',
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                {!isUpdating && (
                    <>
                        <Typography variant='h6'>Edit Statement</Typography>
                        <form onSubmit={onSubmit}>
                            <InputLabel htmlFor='statement'>Statement</InputLabel>
                            <TextareaAutosize
                                id='statement'
                                minRows={2}
                                maxRows={10}
                                disabled
                                value={myStatement?.statement}
                                placeholder='Statement'
                                style={{ width: '100%' }}
                            />
                            <br />
                            <InputLabel htmlFor='show_user'>
                                Show to User?&nbsp;
                                <Checkbox
                                    id='show_user'
                                    checked={myStatement?.show_user}
                                    onChange={(evt) => onChangeCheckbox(evt, 'show_user')}
                                />
                            </InputLabel>
                            <br />
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'>
                                Save
                            </Button>
                        </form>
                    </>
                )}
                {isUpdating && <LoadingState message='Updating Statement' />}
                {updateError && <ErrorState message='Error updating statement' />}
            </Box>
        </Modal>
    )
}

export default EditStatementComponent
