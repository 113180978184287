import { Box, Button, Card, CardContent, Grid2, Typography } from '@mui/material'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementOptions, loadStripe } from '@stripe/stripe-js'
import React from 'react'

const stripePromise = loadStripe('pk_test_51PlDCpJHEvz66ddv6W5xKn6IFYLpCXRmkum917l0d9KnSNSRN3J6tNjAS8uquDwk7aSiTOXQ4wyz8cpZsF8lqjV100HWfmPQhO')

interface CardUpdateFormProps {
    onUpdateSuccess: (showBoth?: boolean) => void
}

const cardElementOptions: StripeCardElementOptions = {
    style: {
        base: {
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace, Courier',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#9e2146'
        }
    },
    hidePostalCode: false
}

const CardUpdateForm: React.FC<CardUpdateFormProps> = ({ onUpdateSuccess }) => {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!stripe || !elements) {
            return
        }

        const cardElement = elements.getElement(CardElement)
        if (!cardElement) {
            return
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        })

        if (error) {
            console.error(error)
        } else {
            console.log(paymentMethod)
            onUpdateSuccess(true)
        }
    }

    return (
        <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{ mt: 3, maxWidth: 400 }}>
            <Card>
                <CardContent>
                    <Typography
                        variant='h6'
                        gutterBottom>
                        Update Card Information
                    </Typography>
                    <Box sx={{ mb: 5, mt: 3 }}>
                        <CardElement options={cardElementOptions} />
                    </Box>
                    <Grid2
                        container
                        justifyContent='flex-end'>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            sx={{ marginRight: 2 }}
                            disabled={!stripe}>
                            Update Card
                        </Button>
                        <Button
                            type='submit'
                            variant='outlined'
                            onClick={() => onUpdateSuccess(false)}>
                            Cancel
                        </Button>
                    </Grid2>
                </CardContent>
            </Card>
        </Box>
    )
}

interface StripeCardUpdateProps {
    onUpdateSuccess: () => void
}

const StripeCardUpdate: React.FC<StripeCardUpdateProps> = ({ onUpdateSuccess }) => (
    <Elements stripe={stripePromise}>
        <CardUpdateForm onUpdateSuccess={onUpdateSuccess} />
    </Elements>
)

export default StripeCardUpdate
