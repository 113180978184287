import { Typography, type TypographyProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { FocusEvent, default as React, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useUpdateResumeField } from '../../hooks/useResume'

type FormInputProps = {
    name: string
    sx?: object
    canEdit?: boolean
    defaultValue?: string
} & TypographyProps

const FieldInputTypography = ({ name, sx = {}, canEdit = true, defaultValue = '', ...others }: FormInputProps): JSX.Element => {
    // 👇 Utilizing useFormContext to have access to the form Context
    const euContext = useContext(EmploymentUniverseContext)
    const { setValue, control } = useFormContext()
    const [editable, setEditable] = useState(canEdit)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleClick = () => {
        setEditable(!!euContext.user?.email && canEdit)
    }

    const handleBlur = async (evt: FocusEvent<HTMLSpanElement>) => {
        const value: string | null = evt.currentTarget.textContent

        if (value && euContext.resume._id) {
            // Update resume field in the database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, value)
            setEditable(false)
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value }, fieldState: { error } }) => (
                <>
                    <Typography
                        onBlur={handleBlur}
                        suppressContentEditableWarning={true}
                        contentEditable={editable}
                        onClick={handleClick}
                        sx={sx}
                        {...others}>
                        {value || defaultValue}
                    </Typography>
                    {error && <Typography color='error'>{error.message}</Typography>}
                </>
            )}
        />
    )
}

export default FieldInputTypography
