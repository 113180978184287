import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

const ShowInitialsComponent = ({ transformValue = 'translate(-40%, 5%)' }: { transformValue?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const firstName: string = euContext.resume.personalInfo.firstName
    const lastName: string = euContext.resume.personalInfo.lastName
    // Get style
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [firstInitial, setfirstInitial] = useState<string>(firstName[0])
    const [lastInitial, setlastInitial] = useState<string>(lastName[0])

    useEffect(() => {
        // Get first and last initial
        const { firstName, lastName } = euContext.resume.personalInfo

        setfirstInitial(firstName[0])
        setlastInitial(lastName[0])
    }, [euContext.resume.personalInfo])

    return (
        <Box
            display='flex'
            position='absolute'>
            <Box
                position='absolute'
                sx={{ transform: transformValue }}>
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={30}
                    color='white'
                    display='inline'>
                    {firstInitial}
                </Typography>
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={30}
                    color='white'
                    display='inline'>
                    {lastInitial}
                </Typography>
            </Box>
        </Box>
    )
}

export default ShowInitialsComponent
