import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useStripePayments } from '../../hooks/useClients'
import { capitalizeFirstLetter, formatMoney } from '../../utils/Util'
import EmptyState from '../../views/EmptyState'
import LoadingState from '../../views/LoadingState'
import CustomTable from '../CustomTable'
import StripeCardUpdate from './StripeCardUpdate'

const columns = [
    { id: 'id', label: 'ID' },
    { id: 'date', label: 'Date' },
    { id: 'amount', label: 'Amount' },
    { id: 'description', label: 'Description' },
    { id: 'status', label: 'Status' }
]

const SubscriptionInfo = () => {
    const { data: stripePaymentsResponse, isLoading: stripePaymentsIsLoading, isError: stripePaymentsIsError, isPending: stripePaymentsIsPending } = useStripePayments()

    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)

    if (stripePaymentsIsLoading || stripePaymentsIsError || stripePaymentsIsPending) {
        return <LoadingState />
    }

    if (stripePaymentsResponse.length === 0) {
        return <EmptyState />
    }

    const subscriptionStartDate = moment.unix(stripePaymentsResponse[stripePaymentsResponse.length - 1].created).format('LL')
    const today = moment()
    const subscriptionDuration = today.diff(subscriptionStartDate, 'months')
    const hasActiveSubscription = today.diff(moment.unix(stripePaymentsResponse[0].created), 'days') <= 31
    const nextBillingDate = moment
        .unix(stripePaymentsResponse[stripePaymentsResponse.length - 1].created)
        .add(1, 'months')
        .startOf('month')
        .format('LL')

    const paymentRows = stripePaymentsResponse.map((payment, index) => {
        const { created, amount, description, status } = payment
        return {
            id: index + 1,
            date: moment.unix(created).format('lll'),
            amount: formatMoney(amount),
            description,
            status: capitalizeFirstLetter(status)
        }
    })

    const handleUpdateSuccess = (showBoth = true) => {
        if (showBoth) {
            setShowUpdateForm(false)
            setAlertOpen(true)
        } else {
            setShowUpdateForm(false)
        }
    }

    return (
        <Box>
            <Typography
                variant='h5'
                gutterBottom>
                Subscription Information
            </Typography>
            <Typography variant='body1'>Subscribed since: {subscriptionStartDate}</Typography>
            {subscriptionDuration > 0 && <Typography variant='body1'>Subscribed for: {subscriptionDuration} months</Typography>}
            <Typography variant='body1'>Next Billing Date: {nextBillingDate}</Typography>
            <Typography variant='body1'>Status: {hasActiveSubscription ? 'Active' : 'Inactive'}</Typography>
            {!showUpdateForm && (
                <Button
                    variant='contained'
                    color='primary'
                    sx={{ mt: 2 }}
                    onClick={() => setShowUpdateForm(!showUpdateForm)}>
                    Update Card
                </Button>
            )}
            {showUpdateForm && <StripeCardUpdate onUpdateSuccess={handleUpdateSuccess} />}
            <Typography
                variant='h6'
                gutterBottom
                sx={{ mt: 4 }}>
                Monthly Transactions
            </Typography>
            <CustomTable
                columns={columns}
                rows={paymentRows}
                showSearch={false}
            />
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity='success'
                    sx={{ width: '100%' }}>
                    Update card saved successfully!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default SubscriptionInfo
