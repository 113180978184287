import { type SearchForSuggestionsResult } from '@aws-sdk/client-location'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, CircularProgress, Divider, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IEducation } from '../../models/Education'
import { IEmployment } from '../../models/Employment'
import { IPublication } from '../../models/Publication'
import { ISimpleCategory, ISimpleCategoryName, ISimpleListItem, ratingType } from '../../models/SimpleList'
import { getLocation, getPlace } from '../../services/LocationService'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import { capitalizeFirstLetter, mapCategories, setNestedProperty, toReadableString } from '../../utils/Util'
import ModalTextField from '../forms/ModalTextField'
import QuillEditor from '../forms/QuillEditor'
import EditModalTitle from './EditModalTitle'
import EducationComponent from './EducationEditModalComponent'
import EmploymentEditModalComponent from './EmploymentEditModalComponent'
import PublicationEditModalComponent from './PublicationEditModalComponent'
import SimpleListEditModalComponent from './SimpleListEditModalComponent'

interface EditModalComponentProps {
    open: boolean
    onClose: () => void
    title?: string
    names?: string[]
    width?: number
    loading?: boolean
    isSkills?: boolean
    isEmployment?: boolean
    isEducation?: boolean
    isAddress?: boolean
    isAccomplishments?: boolean
    isCapabilities?: boolean
    isAffiliations?: boolean
    isHonors?: boolean
    isLicenses?: boolean
    isTrainings?: boolean
    isVolunteers?: boolean
    isPublications?: boolean
}

type FieldMap = {
    'accomplishments.category': ISimpleCategory[]
    'capabilities.category': ISimpleCategory[]
    'skills.category': ISimpleCategory[]
    'personalInfo.address': string
    'employments.employment': IEmployment[]
    'educations.education': IEducation[]
    'affiliations.affiliation': IEmployment[]
    'honors.honor': IEmployment[]
    'licenses.license': IEmployment[]
    'trainings.training': IEmployment[]
    'volunteers.volunteer': IEmployment[]
    'publications.publication': IPublication[]
    [key: string]: unknown
}

const EditModal: React.FC<EditModalComponentProps> = ({
    open,
    onClose,
    title,
    names,
    width = 750,
    loading,
    isSkills = false,
    isEmployment = false,
    isEducation = false,
    isAddress = false,
    isAccomplishments = false,
    isCapabilities = false,
    isAffiliations = false,
    isHonors = false,
    isLicenses = false,
    isTrainings = false,
    isVolunteers = false,
    isPublications = false
}) => {
    const { mutate: updateResumeField } = useUpdateResumeField()
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { getValues, reset, setValue } = useFormContext()

    const initialSkills: ISimpleCategory[] = euContext.resume.skills.category ?? []
    const initialEmploymentList: IEmployment[] = euContext.resume.employments.employment ?? []
    const initialEducationList: IEducation[] = euContext.resume.educations.education ?? []
    const initialAccomplishments: ISimpleCategory[] = euContext.resume.accomplishments.category ?? []
    const initialCapabilities: ISimpleCategory[] = euContext.resume.capabilities.category ?? []
    const initialAffiliations: IEmployment[] = euContext.resume.affiliations.affiliation ?? []
    const initialHonors: IEmployment[] = euContext.resume.honors.honor ?? []
    const initialLicenses: IEmployment[] = euContext.resume.licenses.license ?? []
    const initialTrainings: IEmployment[] = euContext.resume.trainings.training ?? []
    const initialVolunteers: IEmployment[] = euContext.resume.volunteers.volunteer ?? []
    const initialPublications: IPublication[] = euContext.resume.publications.publication ?? []

    const [accomplishments, setLocalAccomplishments] = useState<ISimpleCategory[]>(initialAccomplishments)
    const [volunteers, setVolunteers] = useState<IEmployment[]>(initialVolunteers)
    const [publications, setPublications] = useState<IPublication[]>(initialPublications)
    const [trainings, setTrainings] = useState<IEmployment[]>(initialTrainings)
    const [honors, setHonors] = useState<IEmployment[]>(initialHonors)
    const [affiliations, setAffiliations] = useState<IEmployment[]>(initialAffiliations)
    const [licenses, setLicenses] = useState<IEmployment[]>(initialLicenses)
    const [capabilities, setLocalCapabilities] = useState<ISimpleCategory[]>(initialCapabilities)
    const [skills, setSkills] = useState<ISimpleCategory[]>(initialSkills || [{ name: 'Default Category', list: [], seqNum: 0 }])
    const [employmentList, setEmploymentList] = useState(initialEmploymentList)
    const [educationList, setEducationList] = useState<IEducation[]>(initialEducationList)
    const [selectedTab, setSelectedTab] = useState(0)
    const [locationOptions, setLocationOptions] = useState<SearchForSuggestionsResult[]>([])
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)
    const currentYear = new Date().getFullYear()

    const updateField = <T extends keyof FieldMap>(fieldName: T, fieldValue: FieldMap[T]) => {
        updateResumeField(
            {
                id: euContext.resume._id,
                fieldName: fieldName as string,
                fieldValue
            },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    reset(data.resume)
                    onClose()
                }
            }
        )
    }

    const handleSaveClick = () => {
        if (!euContext.resume._id) return

        if (isPublications) {
            const sortedPublicationsList = [...publications].sort((a, b) => {
                return new Date(b.pubYear, b.pubMonth - 1).getTime() - new Date(a.pubYear, a.pubMonth - 1).getTime()
            })
            updateField('publications.publication', sortedPublicationsList)
        }
        if (isAccomplishments) {
            updateField('accomplishments.category', mapCategories(accomplishments))
        } else if (isVolunteers) {
            const sortedVolunteersList = [...volunteers].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('volunteers.volunteer', sortedVolunteersList)
        } else if (isTrainings) {
            const sortedTrainingsList = [...trainings].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('trainings.training', sortedTrainingsList)
        } else if (isLicenses) {
            const sortedLicensesList = [...licenses].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('licenses.license', sortedLicensesList)
        } else if (isHonors) {
            const sortedHonorsList = [...honors].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('honors.honor', sortedHonorsList)
        } else if (isAffiliations) {
            const sortedAffiliationsList = [...affiliations].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('affiliations.affiliation', sortedAffiliationsList)
        } else if (isCapabilities) {
            updateField('capabilities.category', mapCategories(capabilities))
        } else if (isSkills) {
            updateField('skills.category', mapCategories(skills))
        } else if (isAddress) {
            const address = getValues('personalInfo.address')
            updateField('personalInfo.address', address)
        } else if (isEmployment) {
            const sortedEmploymentList = [...employmentList].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('employments.employment', sortedEmploymentList)
        } else if (isEducation) {
            const sortedEducationList = [...educationList].sort((a, b) => {
                return new Date(b.graduationYear, b.graduationMonth - 1).getTime() - new Date(a.graduationYear, a.graduationMonth - 1).getTime()
            })
            updateField('educations.education', sortedEducationList)
        } else {
            names?.forEach((name) => {
                const fieldValue = getValues(name)
                updateField(name, fieldValue)
            })
        }
    }

    const handleNameChange = (type: ISimpleCategoryName, categoryIndex: number, itemIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        updatedCategories[categoryIndex].list[itemIndex].item = event.target.value
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
    }

    const handleSliderChange = (type: ISimpleCategoryName, categoryIndex: number, itemIndex: number, newValue: number) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        updatedCategories[categoryIndex].list[itemIndex].rate = newValue as ratingType
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
    }

    const handleAddItem = (type: ISimpleCategoryName, categoryIndex: number) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        const newItem: ISimpleListItem = {
            item: `New ${type === 'skills' ? 'Skill' : type === 'accomplishments' ? 'Accomplishment' : 'Capability'}`,
            rate: 0,
            seqNum: updatedCategories[categoryIndex].list.length
        }
        updatedCategories[categoryIndex].list.push(newItem)
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
    }

    const handleDeleteItem = (type: ISimpleCategoryName, categoryIndex: number, itemIndex: number) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        updatedCategories[categoryIndex].list.splice(itemIndex, 1)
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
    }

    const handleAddCategory = (type: ISimpleCategoryName) => {
        const newCategory: ISimpleCategory = {
            name: `New ${type === 'skills' ? 'Skill Category' : type === 'accomplishments' ? 'Accomplishment Category' : 'Capability Category'}`,
            list: [],
            seqNum: (type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities).length
        }
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities), newCategory]
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
        setSelectedTab(updatedCategories.length - 1)
    }

    const handleDeleteCategory = (type: ISimpleCategoryName) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        updatedCategories.splice(selectedTab, 1)
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
        setSelectedTab((prevTab) => (prevTab >= updatedCategories.length ? updatedCategories.length - 1 : prevTab))
    }

    const handleCategoryNameChange = (type: ISimpleCategoryName, categoryIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCategories = [...(type === 'skills' ? skills : type === 'accomplishments' ? accomplishments : capabilities)]
        updatedCategories[categoryIndex].name = event.target.value
        type === 'skills' ? setSkills(updatedCategories) : type === 'accomplishments' ? setLocalAccomplishments(updatedCategories) : setLocalCapabilities(updatedCategories)
    }

    const getListByFlag = (flag: string) => {
        switch (flag) {
            case 'affiliations':
                return { list: euContext.resume.affiliations.affiliation, setList: setAffiliations }
            case 'honors':
                return { list: euContext.resume.honors.honor, setList: setHonors }
            case 'licenses':
                return { list: euContext.resume.licenses.license, setList: setLicenses }
            case 'trainings':
                return { list: euContext.resume.trainings.training, setList: setTrainings }
            case 'volunteers':
                return { list: euContext.resume.volunteers.volunteer, setList: setVolunteers }
            default:
                return { list: employmentList, setList: setEmploymentList }
        }
    }

    const handleEmploymentChange = (listType: string, index: number, field: string, value: string | boolean) => {
        const { setList } = getListByFlag(listType)

        setList((prevList) =>
            prevList.map((employment, i) => {
                if (i === index) {
                    if (field === 'isPresent' && typeof value === 'boolean') {
                        return { ...employment, isPresent: value }
                    } else if (field === 'start' || field === 'end') {
                        const [year, month] = (value as string).split('-')
                        const updatedYear = parseInt(year, 10)
                        const updatedMonth = parseInt(month, 10)

                        if (field === 'start') {
                            if (employment.endYear && employment.endMonth && (updatedYear > employment.endYear || (updatedYear === employment.endYear && updatedMonth > employment.endMonth))) {
                                alert('Start date cannot be after the end date')
                                return employment
                            }
                            return { ...employment, startYear: updatedYear, startMonth: updatedMonth }
                        } else if (field === 'end') {
                            if (
                                employment.startYear &&
                                employment.startMonth &&
                                (updatedYear < employment.startYear || (updatedYear === employment.startYear && updatedMonth < employment.startMonth))
                            ) {
                                alert('End date cannot be before the start date')
                                return employment
                            }
                            return { ...employment, endYear: updatedYear, endMonth: updatedMonth }
                        }
                    } else {
                        return { ...employment, [field]: value }
                    }
                }
                return employment
            })
        )
    }

    const handleAddEmployment = (listType: string) => {
        const currentYear = new Date().getFullYear()
        const newEmployment: IEmployment = {
            title: '',
            companyName: 'Untitled',
            startMonth: 1,
            startYear: currentYear,
            endMonth: 1,
            endYear: currentYear,
            location: '',
            summary: {
                description: '',
                label: ''
            },
            seqNum: getListByFlag(listType).list.length,
            isPresent: false
        }

        const { setList } = getListByFlag(listType)
        setList((prevList) => [...prevList, newEmployment])
        setSelectedTab(getListByFlag(listType).list.length)
    }

    const handleDeleteEmployment = (listType: string, index: number) => {
        const { list, setList } = getListByFlag(listType)
        const updatedList = list.filter((_, i) => i !== index)
        setList(updatedList)
        setSelectedTab(0)
    }

    const handleQuillChange = (listType: string, value: string) => {
        const { list, setList } = getListByFlag(listType)
        setList(list.map((employment, i) => (i === selectedTab ? { ...employment, summary: { ...employment.summary, description: value } } : employment)))
    }

    const handleEducationChange = (index: number, field: keyof IEducation, value: string | number) => {
        setEducationList((prevEducationList) =>
            prevEducationList.map((education, i) => {
                if (i === index) {
                    if (field === 'graduationMonth' || field === 'graduationYear') {
                        const updatedValue = Number(value)

                        if (field === 'graduationMonth' && (updatedValue < 1 || updatedValue > 12)) {
                            alert('Graduation month must be between 1 and 12')
                            return education
                        }

                        return { ...education, [field]: updatedValue }
                    } else {
                        return { ...education, [field]: value }
                    }
                }
                return education
            })
        )
    }

    const handleAddEducation = () => {
        const newEducation = {
            degree: '',
            major: '',
            school: '',
            graduation: '',
            graduationMonth: 1,
            graduationYear: currentYear,
            seqNum: 0
        }
        setEducationList([...educationList, newEducation])
        setSelectedTab(educationList.length)
    }

    const handleDeleteEducation = (index: number) => {
        const updatedList = educationList.filter((_, i) => i !== index)
        setEducationList(updatedList)
        setSelectedTab(0)
    }

    const handlePublicationChange = (index: number, field: keyof IPublication, value: string | number) => {
        setPublications((prevPublications) => prevPublications.map((publication, i) => (i === index ? { ...publication, [field]: value } : publication)))
    }

    const handleAddPublication = () => {
        const newPublication: IPublication = {
            title: '',
            publisher: '',
            pubYear: new Date().getFullYear(),
            pubMonth: new Date().getMonth() + 1,
            pages: 0,
            description: '',
            publications: '',
            seqNum: 0
        }
        setPublications([...publications, newPublication])
        setSelectedTab(publications.length)
    }

    const handleDeletePublication = (index: number) => {
        const updatedList = publications.filter((_, i) => i !== index)
        setPublications(updatedList)
        setSelectedTab(0)
    }

    const handleLocationSuggestions = async (inputValue: string) => {
        setLoadingSuggestions(true)
        const suggestions = await getLocation(inputValue)
        if (suggestions) {
            setLocationOptions(suggestions.map((suggestion) => suggestion))
        }
        setLoadingSuggestions(false)
    }

    const handleSelectLocation = async (placeId: string) => {
        setValue('personalInfo.address.street', '')

        if (placeId) {
            const place = await getPlace(placeId)

            if (place) {
                const street = `${place.AddressNumber ?? ''} ${place.Street ?? ''}`
                setValue('personalInfo.address.street', street ?? '')
                setValue('personalInfo.address.city', place.Municipality ?? '')
                setValue('personalInfo.address.state', place.Region ?? '')
                setValue('personalInfo.address.zip', place.PostalCode ?? '')
            }
        }
    }

    const handleCancel = () => {
        setSkills(initialSkills)
        setEmploymentList(initialEmploymentList)
        setEducationList(initialEducationList)
        setLocalAccomplishments(initialAccomplishments)
        setLocalCapabilities(initialCapabilities)
        onClose()
    }

    const getLabel = () => {
        if (isAccomplishments) return euContext.resume.accomplishments.label
        if (isSkills) return euContext.resume.skills.label
        if (isEmployment) return euContext.resume.employments.label
        if (isAffiliations) return euContext.resume.affiliations.label
        if (isEducation) return euContext.resume.educations.label
        if (isHonors) return euContext.resume.honors.label
        if (isLicenses) return euContext.resume.licenses.label
        if (isTrainings) return euContext.resume.trainings.label
        if (isVolunteers) return euContext.resume.volunteers.label
        if (isCapabilities) return euContext.resume.capabilities.label
        if (isPublications) return euContext.resume.publications.label
        else {
            const name = names?.find((name) => name.includes('.label'))
            return name
                ? name.split('.').reduce((acc, part) => {
                      if (acc && typeof acc === 'object' && part in acc) {
                          return (acc as Record<string, unknown>)[part]
                      }
                      return undefined
                  }, euContext.resume as unknown)
                : title
        }
    }

    const handleLabelSave = (newLabel: string) => {
        if (isAccomplishments) {
            euContext.resume.accomplishments.label = newLabel
            updateField('accomplishments.label', newLabel)
        } else if (isSkills) {
            euContext.resume.skills.label = newLabel
            updateField('skills.label', newLabel)
        } else if (isEmployment) {
            euContext.resume.employments.label = newLabel
            updateField('employments.label', newLabel)
        } else if (isEducation) {
            euContext.resume.educations.label = newLabel
            updateField('educations.label', newLabel)
        } else if (isAffiliations) {
            euContext.resume.affiliations.label = newLabel
            updateField('affiliations.label', newLabel)
        } else if (isHonors) {
            euContext.resume.honors.label = newLabel
            updateField('honors.label', newLabel)
        } else if (isLicenses) {
            euContext.resume.licenses.label = newLabel
            updateField('licenses.label', newLabel)
        } else if (isTrainings) {
            euContext.resume.trainings.label = newLabel
            updateField('trainings.label', newLabel)
        } else if (isVolunteers) {
            euContext.resume.volunteers.label = newLabel
            updateField('volunteers.label', newLabel)
        } else if (isCapabilities) {
            euContext.resume.capabilities.label = newLabel
            updateField('capabilities.label', newLabel)
        } else if (isPublications) {
            euContext.resume.publications.label = newLabel
            updateField('publications.label', newLabel)
        } else {
            const name = names?.find((name) => name.includes('.label'))

            if (name) {
                const parts = name.split('.')

                setNestedProperty(euContext.resume, parts, newLabel)
                updateField(name, newLabel)
                euContext.setResume({ ...euContext.resume })
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCancel}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'>
            <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        maxHeight: 700,
                        overflowY: 'auto'
                    }}>
                    <IconButton
                        onClick={handleCancel}
                        sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CloseIcon />
                    </IconButton>
                    <EditModalTitle
                        label={getLabel() as string}
                        onSave={handleLabelSave}
                        variant='h4'
                    />
                    {!loading && isSkills && (
                        <SimpleListEditModalComponent
                            itemsList={skills}
                            itemName={'Skill'}
                            handleNameChange={(categoryIndex, itemIndex, event) => handleNameChange('skills', categoryIndex, itemIndex, event)}
                            handleSliderChange={(categoryIndex, itemIndex, value) => handleSliderChange('skills', categoryIndex, itemIndex, value)}
                            handleAddItem={(categoryIndex) => handleAddItem('skills', categoryIndex)}
                            handleDeleteItem={(categoryIndex, itemIndex) => handleDeleteItem('skills', categoryIndex, itemIndex)}
                            handleAddCategory={() => handleAddCategory('skills')}
                            handleDeleteCategory={() => handleDeleteCategory('skills')}
                            handleCategoryNameChange={(categoryIndex, event) => handleCategoryNameChange('skills', categoryIndex, event)}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && (isEmployment || isAffiliations || isHonors || isLicenses || isTrainings || isVolunteers) && (
                        <EmploymentEditModalComponent
                            employmentList={isEmployment ? employmentList : isAffiliations ? affiliations : isHonors ? honors : isLicenses ? licenses : isTrainings ? trainings : volunteers}
                            handleQuillChange={handleQuillChange}
                            handleAddEmployment={handleAddEmployment}
                            handleDeleteEmployment={handleDeleteEmployment}
                            handleEmploymentChange={handleEmploymentChange}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            listType={isEmployment ? 'employment' : isAffiliations ? 'affiliations' : isHonors ? 'honors' : isLicenses ? 'licenses' : isTrainings ? 'trainings' : 'volunteers'}
                        />
                    )}

                    {!loading && isEducation && (
                        <EducationComponent
                            educationList={educationList}
                            handleAddEducation={handleAddEducation}
                            handleDeleteEducation={handleDeleteEducation}
                            handleEducationChange={handleEducationChange}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isPublications && (
                        <PublicationEditModalComponent
                            publicationList={publications}
                            handleAddPublication={handleAddPublication}
                            handleDeletePublication={handleDeletePublication}
                            handlePublicationChange={handlePublicationChange}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isAccomplishments && (
                        <SimpleListEditModalComponent
                            itemsList={accomplishments}
                            itemName={euContext.resume.accomplishments.label}
                            handleNameChange={(categoryIndex, itemIndex, event) => handleNameChange('accomplishments', categoryIndex, itemIndex, event)}
                            handleSliderChange={(categoryIndex, itemIndex, value) => handleSliderChange('accomplishments', categoryIndex, itemIndex, value)}
                            handleAddItem={(categoryIndex) => handleAddItem('accomplishments', categoryIndex)}
                            handleDeleteItem={(categoryIndex, itemIndex) => handleDeleteItem('accomplishments', categoryIndex, itemIndex)}
                            handleAddCategory={() => handleAddCategory('accomplishments')}
                            handleDeleteCategory={() => handleDeleteCategory('accomplishments')}
                            handleCategoryNameChange={(categoryIndex, event) => handleCategoryNameChange('accomplishments', categoryIndex, event)}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isCapabilities && (
                        <SimpleListEditModalComponent
                            itemsList={capabilities}
                            itemName={euContext.resume.capabilities.label}
                            handleNameChange={(categoryIndex, itemIndex, event) => handleNameChange('capabilities', categoryIndex, itemIndex, event)}
                            handleSliderChange={(categoryIndex, itemIndex, value) => handleSliderChange('capabilities', categoryIndex, itemIndex, value)}
                            handleAddItem={(categoryIndex) => handleAddItem('capabilities', categoryIndex)}
                            handleDeleteItem={(categoryIndex, itemIndex) => handleDeleteItem('capabilities', categoryIndex, itemIndex)}
                            handleAddCategory={() => handleAddCategory('capabilities')}
                            handleDeleteCategory={() => handleDeleteCategory('capabilities')}
                            handleCategoryNameChange={(categoryIndex, event) => handleCategoryNameChange('capabilities', categoryIndex, event)}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && !isSkills && !isEmployment && !isEducation && !isCapabilities && !isAffiliations && !isHonors && !isLicenses && !isTrainings && !isVolunteers && !isPublications && (
                        <Box
                            id='modal-description'
                            sx={{ mt: 2, mb: 2 }}>
                            {names?.map((name) => {
                                if (name.includes('.label')) return
                                return (
                                    <Stack
                                        key={name}
                                        spacing={2}
                                        direction='column'>
                                        {name.includes('.description') ? (
                                            <QuillEditor
                                                value={getValues(name) || `${capitalizeFirstLetter(name.split('.description')[0])} Description`}
                                                onChange={(value) => setValue(name, value)}
                                                sx={{ marginTop: 2 }}
                                            />
                                        ) : (
                                            <ModalTextField
                                                key={name}
                                                name={name}
                                                label={name === 'personalInfo.address.street' ? 'Street Address' : toReadableString(name)}
                                                isAddressField={name === 'personalInfo.address.street'}
                                                onLocationSelect={handleSelectLocation}
                                                onLocationSuggestions={handleLocationSuggestions}
                                                locationOptions={locationOptions}
                                                loadingSuggestions={loadingSuggestions}
                                            />
                                        )}
                                    </Stack>
                                )
                            })}
                        </Box>
                    )}

                    <Divider />
                    {loading && (
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            sx={{ mt: 2 }}>
                            <CircularProgress size={24} />
                            <Typography sx={{ ml: 2 }}>Saving...</Typography>
                        </Stack>
                    )}

                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        columnGap={1}>
                        <Button
                            type='button'
                            size='small'
                            color='info'
                            onClick={handleCancel}
                            disabled={loading}
                            sx={{ mt: 2 }}>
                            CANCEL
                        </Button>
                        <Button
                            type='button'
                            color='secondary'
                            onClick={() => {
                                handleSaveClick()
                            }}
                            disabled={loading}
                            sx={{ mt: 2 }}>
                            SAVE
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    )
}

export default EditModal
