import { Box, Grid2, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { getTypographyStyles, markdownToPlainText } from '../../../utils/Util'

type Props = {
    compNum: number
    cardSeperator?: boolean
}

export const EmploymentComponent = ({ compNum, cardSeperator = false }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume } = euContext

    const bodyTypographyStyles = getTypographyStyles(style, 'Body', true)
    const companyTypographyStyles = getTypographyStyles(style, 'Company', true)
    const jobTitleTypographyStyles = getTypographyStyles(style, 'JobTitle', true)
    const dateTypographyStyles = getTypographyStyles(style, 'Date', true)

    const { startMonth, startYear, endMonth, endYear, isPresent, summary } = resume.employments.employment[compNum]
    const sanitizedDescription = markdownToPlainText(summary?.description || '')

    return (
        <Box
            width='100%'
            mb={`${resume.style[resume.currentStyle].SpaceCard}px`}
            borderBottom={cardSeperator && compNum < resume.employments.employment.length - 1 ? '1px dotted lightgray' : ''}>
            <Grid2
                container
                spacing={0}
                columns={12}>
                <Grid2
                    container
                    spacing={0}
                    columns={12}>
                    <Grid2
                        display='flex'
                        justifyContent='left'
                        alignItems='left'
                        size={12}>
                        <Typography sx={jobTitleTypographyStyles}>{resume.employments.employment[compNum].title || 'Enter Job Title'}</Typography>
                    </Grid2>
                    <Grid2
                        display='flex'
                        justifyContent='left'
                        alignItems='left'
                        size={6}>
                        <Typography sx={companyTypographyStyles}>{resume.employments.employment[compNum].companyName || 'Company Name'}</Typography>
                    </Grid2>
                    <Grid2
                        display='flex'
                        justifyContent='right'
                        alignItems='right'
                        size={6}>
                        <Typography sx={dateTypographyStyles}>
                            {format(new Date(startYear, startMonth - 1), style.DateFormat)}
                            &nbsp;{style.DateDelim}&nbsp;
                            {isPresent ? 'Present' : format(new Date(endYear, endMonth - 1), style.DateFormat)}
                        </Typography>
                    </Grid2>
                    <Grid2
                        display='flex'
                        justifyContent='left'
                        alignItems='left'
                        size={12}>
                        <Typography
                            sx={bodyTypographyStyles}
                            dangerouslySetInnerHTML={{ __html: sanitizedDescription || 'Job Description' }}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    )
}
