import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Rating, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import { ISimpleCategory, ISimpleListItem } from '../../models/SimpleList'

interface SimpleListEditModalComponentProps {
    itemsList: ISimpleCategory[]
    itemName: string
    handleNameChange: (categoryIndex: number, itemIndex: number, event: React.ChangeEvent<HTMLInputElement>) => void
    handleSliderChange: (categoryIndex: number, itemIndex: number, value: number) => void
    handleAddItem: (categoryIndex: number) => void
    handleDeleteItem: (categoryIndex: number, itemIndex: number) => void
    handleAddCategory: () => void
    handleDeleteCategory: (categoryIndex: number) => void
    handleCategoryNameChange: (categoryIndex: number, event: React.ChangeEvent<HTMLInputElement>) => void
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

const SimpleListEditModalComponent: React.FC<SimpleListEditModalComponentProps> = ({
    itemsList,
    itemName,
    handleNameChange,
    handleSliderChange,
    handleAddItem,
    handleDeleteItem,
    handleAddCategory,
    handleDeleteCategory,
    handleCategoryNameChange,
    selectedTab,
    setSelectedTab
}) => {
    const showEmptyState = !itemsList || itemsList.length === 0
    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            {!showEmptyState && (
                <>
                    <Tabs
                        value={selectedTab}
                        onChange={(_, newValue) => setSelectedTab(newValue)}
                        aria-label={`${itemName}-tabs`}
                        variant='scrollable'
                        scrollButtons='auto'
                        sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {itemsList.map((category, index) => (
                            <Tab
                                key={`tab-${index}`}
                                label={category.name || 'Untitled Category'}
                            />
                        ))}
                    </Tabs>
                    {itemsList.map(
                        (category: ISimpleCategory, categoryIndex: number) =>
                            selectedTab === categoryIndex && (
                                <Box
                                    key={`category-${categoryIndex}`}
                                    sx={{ mt: 3 }}>
                                    <TextField
                                        label='Category Name'
                                        value={category.name}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCategoryNameChange(categoryIndex, event)}
                                        variant='outlined'
                                        fullWidth
                                        sx={{ mb: 2, mt: 2 }}
                                    />
                                    {category.list?.map((item: ISimpleListItem, itemIndex: number) => (
                                        <Stack
                                            key={`item-${itemIndex}`}
                                            spacing={2}
                                            direction='column'>
                                            <Stack
                                                direction='row'
                                                justifyContent='flex-start'
                                                alignItems='center'
                                                gap={2}
                                                mt={2}
                                                sx={{ width: '100%' }}>
                                                <TextField
                                                    label={item.item || `${itemName} Name`}
                                                    value={item.item}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNameChange(categoryIndex, itemIndex, event)}
                                                    variant='outlined'
                                                    sx={{ flexGrow: 0.5, mb: 2, mt: 1 }}
                                                />
                                                <Rating
                                                    name={`${itemName}-rating-${itemIndex}`}
                                                    value={item.rate}
                                                    onChange={(_, newValue) => handleSliderChange(categoryIndex, itemIndex, newValue || 0)}
                                                    max={5}
                                                    defaultValue={0}
                                                    icon={<span className='material-icons'>star</span>}
                                                    emptyIcon={<span className='material-icons'>star_border</span>}
                                                    precision={1}
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                                <IconButton
                                                    onClick={() => handleDeleteItem(categoryIndex, itemIndex)}
                                                    color='error'>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    ))}
                                    <Stack
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        mt={2}
                                        sx={{ width: '100%' }}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={() => handleAddItem(categoryIndex)}>
                                            Add {itemName}
                                        </Button>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        mt={4}
                                        sx={{ width: '100%' }}>
                                        <IconButton
                                            onClick={() => handleDeleteCategory(categoryIndex)}
                                            color='error'>
                                            <DeleteIcon />
                                        </IconButton>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={handleAddCategory}>
                                            Add Category
                                        </Button>
                                    </Stack>
                                </Box>
                            )
                    )}
                </>
            )}

            {showEmptyState && (
                <>
                    <Typography>No {itemName.toLowerCase()} available. Add one to get started!</Typography>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        mt={4}
                        sx={{ width: '100%' }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleAddCategory}>
                            Add Category
                        </Button>
                    </Stack>
                </>
            )}
        </Box>
    )
}

export default SimpleListEditModalComponent
