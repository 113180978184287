import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Box, Button, IconButton, Typography } from '@mui/material'
import { isAxiosError } from 'axios'
import { signInWithPopup } from 'firebase/auth'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import FieldSigninText from '../components/forms/FieldSigninText'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useGoogleLogin, useLogin } from '../hooks/useAuth'
import { useEnvironments } from '../hooks/useEnvironments'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import LoadingState from '../views/LoadingState'
import { getLoginDefaultValues } from './forms/defaultValues/FormDefaultValues'
import { LoginSchema } from './forms/schemas/FormSchemas'

export const LoginPage = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState('')
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [showPassword, setShowPassword] = useState(false)

    const { mutate: loginMutate, isPending: loginLoading } = useLogin()
    const { mutate: googleLoginMutate, isPending: googleLoginLoading } = useGoogleLogin()
    const { data: environmentResponse, isLoading: environmentLoading } = useEnvironments()
    const navigate = useNavigate()

    const formContext = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: getLoginDefaultValues(),
        mode: 'onBlur',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    const handleLinkedInSignIn = async () => {
        const params = 'scrollbars=no,resizable=no,status=no,locationbar=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100'

        const onMessage = (event: MessageEvent) => {
            if (event.data.user) euContext.setUser(event.data.user)
            if (event.data.resume) euContext.setResume(event.data.resume)

            if (event.data.user) {
                window.removeEventListener('message', onMessage)
                navigate('/')
            }
        }
        window.addEventListener('message', onMessage)

        window.open(environmentResponse?.linkedinOAuthURLSignin, '_linkedIn', params)
    }

    const handleGoogleSignIn = async () => {
        if (environmentResponse) {
            try {
                environmentResponse.googleProvider.setCustomParameters({
                    prompt: 'select_account'
                })

                const result = await signInWithPopup(environmentResponse.auth, environmentResponse.googleProvider)
                const { user } = result

                googleLoginMutate(user, {
                    onSuccess: (response) => {
                        euContext.setUser(response.user)
                        euContext.setResume(response.resume)
                        navigate('/')
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error.response) {
                            setErrorMessage(error.response.data.message)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                })
            } catch (err: unknown) {
                if (isAxiosError(err) && err.response) {
                    setErrorMessage(err.response.data.message)
                } else {
                    setErrorMessage('An unknown error occurred')
                }
            }
        }
    }

    const submitIfEnter = (key: string): void => {
        if (key === 'Enter') onLoginClickedWrapper()
    }

    const handleClickShowPassword = (): void => {
        setShowPassword((show) => !show)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
    }

    const onLoginClickedWrapper = (): void => {
        onLoginClicked().catch((error) => {
            setErrorMessage(error)
        })
    }

    const onLoginClicked = async (): Promise<void> => {
        try {
            const { email, password } = formContext.getValues()
            loginMutate(
                { email, password, subdomain: euContext.domain },
                {
                    onSuccess: (response) => {
                        euContext.setUser(response.user)
                        euContext.setResume(response.resume)
                        navigate('/')
                    },
                    onError: (error) => {
                        if (isAxiosError(error) && error.response) {
                            setErrorMessage(error.response.data.message)
                        } else {
                            setErrorMessage('An unknown error occurred')
                        }
                    }
                }
            )
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                setErrorMessage(err.response.data.message)
            } else {
                setErrorMessage('An unknown error occurred')
            }
        }
    }

    return (
        <>
            {environmentLoading ? (
                <LoadingState />
            ) : (
                <FormProvider {...formContext}>
                    <form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: 5,
                                paddingBottom: 20,
                                backgroundColor: euContext?.htmlColors?.login.backgroundColor,
                                borderColor: euContext?.htmlColors?.login.borderColor
                            }}>
                            <Box
                                sx={{
                                    width: '400px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '24px',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                                }}>
                                <Typography
                                    component='h1'
                                    variant='h4'
                                    sx={{ mb: 2, color: euContext?.htmlColors?.login.textColor }}>
                                    Log In
                                </Typography>
                                {errorMessage && (
                                    <Alert
                                        severity='error'
                                        sx={{ mb: 2 }}>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <FieldSigninText
                                    name='email'
                                    autoComplete='current-email'
                                    label={'Email'}
                                    placeholder='someone@somewhere.com'
                                    onChange={() => {
                                        setErrorMessage('')
                                    }}
                                    onKeyDown={(e) => {
                                        submitIfEnter(e.key)
                                    }}
                                    error={errorMessage !== ''}
                                    sx={{ width: '100%' }}
                                    autoFocus
                                />
                                <FieldSigninText
                                    name='password'
                                    label='Password'
                                    autoComplete='current-password'
                                    onChange={() => {
                                        setErrorMessage('')
                                    }}
                                    onKeyDown={(e) => {
                                        submitIfEnter(e.key)
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    }
                                    sx={{ width: '100%' }}
                                    error={errorMessage !== ''}
                                />
                                <Button
                                    variant='outlined'
                                    disabled={loginLoading}
                                    onClick={onLoginClickedWrapper}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '10px',
                                        color: euContext.htmlColors?.login.buttonTextSecondary,
                                        borderColor: euContext?.htmlColors?.login?.buttonBackgroundColor
                                    }}>
                                    {loginLoading ? 'Logging in...' : 'Login'}
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={handleGoogleSignIn}
                                    sx={{ width: '100%', marginBottom: '10px', backgroundColor: euContext.htmlColors?.login.buttonBackgroundColor }}>
                                    {googleLoginLoading ? 'Signing in...' : 'Sign In With Google Account'}
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={handleLinkedInSignIn}
                                    sx={{ width: '100%', marginBottom: '10px', backgroundColor: euContext.htmlColors?.login.buttonBackgroundColor }}>
                                    Sign In With LinkedIn Account
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            )}
        </>
    )
}
