import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { Degrees, IEducation } from '../../../models/Education'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

interface Props {
    education: IEducation
}

const EducationComponent = ({ education }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    return (
        <Stack
            width='100%'
            direction='column'
            spacing={1}>
            <Typography
                fontFamily={style[style.CompanyFontName]}
                fontSize={`${style.CompanyFontSize}px`}
                fontStyle={style.CompanyFontStyle}
                fontWeight={style.CompanyFontWeight}
                color={style?.CompanyFontColor ?? 'black'}
                letterSpacing={style?.CompanyFontLetterSpacing ?? 1}
                textAlign={style.CompanyAlign}>
                {Degrees.find((d) => d.value === education.degree)?.label || ''}
            </Typography>
            <Typography
                fontFamily={style[style.BodyFontName]}
                fontSize={`${style.BodyFontSize}px`}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                color={style?.BodyFontColor ?? 'black'}
                letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                textAlign={style.BodyAlign}>
                {education.major}
            </Typography>
            <Typography
                fontFamily={style[style.BodyFontName]}
                fontSize={`${style.BodyFontSize}px`}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                color={style?.BodyFontColor ?? 'black'}
                letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                textAlign={style.BodyAlign}>
                {education.school}
            </Typography>
            <Typography
                fontFamily={style[style.DateFontName]}
                fontSize={`${style.DateFontSize}px`}
                fontStyle={style.DateFontStyle}
                fontWeight={style.DateFontWeight}
                color={style?.DateFontColor ?? 'black'}
                letterSpacing={style?.DateFontLetterSpacing ?? 1}
                textAlign={style.DateAlign}>
                {education.graduationYear}
            </Typography>
        </Stack>
    )
}

export default EducationComponent
