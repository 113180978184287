import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import HonorsComponent from '../resume/honors/HonorsComponent'

const ShowHonors = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Honors Component (no params)</TableCell>
                    <TableCell>
                        <HonorsComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Honors Component (section background color)</TableCell>
                    <TableCell>
                        <HonorsComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Component 3</TableCell>
                    <TableCell>View 3</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowHonors
