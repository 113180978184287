import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import ObjectiveComponent from '../resume/simpleCards/ObjectiveComponent'
import OtherComponent from '../resume/simpleCards/OtherComponent'
import PersonalComponent from '../resume/simpleCards/PersonalComponent'
import ProfessionalComponent from '../resume/simpleCards/ProfessionalComponent'
import SummaryComponent from '../resume/simpleCards/SummaryComponent'

const ShowSimpleCards = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Objective Component</TableCell>
                    <TableCell>
                        <ObjectiveComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Other Component</TableCell>
                    <TableCell>
                        <OtherComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Personal Component</TableCell>
                    <TableCell>
                        <PersonalComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Professional Component</TableCell>
                    <TableCell>
                        <ProfessionalComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Summary Component</TableCell>
                    <TableCell>
                        <SummaryComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowSimpleCards
