import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback } from 'react'
import { useCreateStripeSession } from '../hooks/useClients'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This test secret API key is a placeholder. Don't include personal details in requests with this key.
// To see your test secret API key embedded in code samples, sign in to your Stripe account.
// You can also find your test secret API key at https://dashboard.stripe.com/test/apikeys.
// const stripePromise = loadStripe('pk_test_51Pad7tCY5lo7jJdRtHgl0Fsl86sEaCguKlKfn3SqkxpMpJPOknDrrM9g591tysWneNpLEuqinb2U2ccrFwHxkTSY00HlBN8D4z')
const stripePromise = loadStripe('pk_test_51PlDCpJHEvz66ddv6W5xKn6IFYLpCXRmkum917l0d9KnSNSRN3J6tNjAS8uquDwk7aSiTOXQ4wyz8cpZsF8lqjV100HWfmPQhO')

export const CheckoutForm = () => {
    const { mutateAsync: createSession } = useCreateStripeSession()

    const fetchClientSecret = useCallback(async () => {
        try {
            const data = await createSession()
            return data.clientSecret
        } catch (error) {
            console.error('Error fetching client secret:', error)
        }
    }, [createSession])

    const options = { fetchClientSecret }

    return (
        <div
            style={{ minWidth: 750, maxWidth: 850 }}
            id='checkout'>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}
