import { Box, Grid2, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { ITalent } from '../../../models/Talent'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

type Props = {
    compNum: number
}

const TalentDraggableComponent = ({ compNum }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const talent: ITalent = euContext.resume.talents.talent[compNum]

    return (
        <ListItem sx={{ width: '100%' }}>
            <Box
                width='100%'
                rowGap={0}>
                <Stack
                    direction='column'
                    spacing={0}
                    rowGap={0}
                    sx={{ flexGrow: 1 }}>
                    <Typography
                        fontFamily={style[style.JobTitleFontName]}
                        fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                        fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                        alignSelf={style?.JobTitleAlign ?? 'end'}
                        fontWeight={style?.JobTitleFontWeight ?? 'initial'}
                        sx={{ color: style?.BodyFontColor ?? 'black', letterSpacing: style?.BodyFontLetterSpacing ?? 1 }}>
                        {talent.production}
                    </Typography>
                    <Grid2
                        container
                        spacing={1}
                        direction='row'
                        justifyContent='space-between'
                        sx={{ flexGrow: 1 }}>
                        <Grid2 size={6}>
                            <Typography
                                fontFamily={style[style.CompanyFontName]}
                                fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                alignSelf={style?.CompanyAlign ?? 'end'}
                                fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                sx={{
                                    textTransform: style?.CompanyFontCase ?? 'initial',
                                    color: style?.CompanyFontColor ?? 'black',
                                    letterSpacing: style?.CompanyFontLetterSpacing ?? 1
                                }}>
                                {talent.producer}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Typography
                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                        alignSelf={style?.BodyAlign ?? 'end'}
                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                        sx={{ color: style?.BodyFontColor ?? 'black', letterSpacing: style?.BodyFontLetterSpacing ?? 1 }}>
                        {talent.role}
                    </Typography>
                </Stack>
            </Box>
        </ListItem>
    )
}

export default TalentDraggableComponent
