import { Box, Grid2, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'

type Props = {
    compNum: number
}

const ReferenceComponent = ({ compNum }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume } = euContext

    return (
        <Stack
            direction='row'
            width='100%'>
            <ListItem sx={{ width: '100%' }}>
                <Box
                    width='100%'
                    rowGap={0}>
                    <Grid2
                        container
                        spacing={1}
                        direction='row'>
                        <Grid2 size='auto'>
                            <Typography
                                sx={{
                                    textTransform: style?.BodyFontCase ?? 'initial',
                                    alignItems: style?.BodyAlign,
                                    color: style?.BodyFontColor ?? 'black',
                                    letterSpacing: style?.BodyFontLetterSpacing ?? 1
                                }}
                                fontFamily={style?.BodyFontName ?? 'initial'}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'end'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                {resume.references.reference[compNum].name}
                            </Typography>
                        </Grid2>
                        <Grid2 size='auto'>
                            <Typography
                                sx={{
                                    textTransform: style?.BodyFontCase ?? 'initial',
                                    alignItems: style?.BodyAlign,
                                    color: style?.BodyFontColor ?? 'black',
                                    letterSpacing: style?.BodyFontLetterSpacing ?? 1
                                }}
                                fontFamily={style?.BodyFontName ?? 'initial'}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'end'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                {resume.references.reference[compNum].email}
                            </Typography>
                        </Grid2>
                        <Grid2 size='auto'>
                            <Typography
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                alignSelf={style?.BodyAlign ?? 'end'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                {resume.references.reference[compNum].phone}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Box>
            </ListItem>
        </Stack>
    )
}

export default ReferenceComponent
