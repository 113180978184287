import { Box, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { format } from 'date-fns'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { Degrees, IEducation } from '../../../models/Education'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
    isHighlighted: boolean
    onClick?: () => void
    cardSeperator?: boolean
}

const EducationCondensedSummaryContentView = ({ sectionBgColor = '', isHighlighted, onClick, cardSeperator }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEducation: IEducation[] = euContext.resume.educations.education.sort((a, b) => b.graduationYear - a.graduationYear)

    return (
        <Stack
            marginBottom={`${style.SpaceCard}px`}
            width='100%'
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0'
            }}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.educations.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Table
                border={0}
                cellSpacing={0}
                cellPadding={0}
                size='small'>
                <TableBody>
                    {orderedEducation.map((edu: IEducation, index: number) => {
                        return (
                            <>
                                <TableRow
                                    key={index}
                                    sx={{ border: 0, lineHeight: 1 }}>
                                    <TableCell sx={{ border: 0, padding: 0 }}>
                                        <Typography
                                            display='inline'
                                            fontFamily={style[style.JobTitleFontName]}
                                            fontSize={`${style.JobTitleFontSize}px`}
                                            fontStyle={style.JobTitleFontStyle}
                                            fontWeight={style.JobTitleFontWeight}
                                            textAlign={style.JobTitleAlign}
                                            color={style?.JobTitleFontColor ?? 'black'}
                                            letterSpacing={style?.JobTitleFontLetterSpacing ?? 1}>
                                            {Degrees.filter((d) => d.value === edu.degree)[0].label}
                                        </Typography>
                                        <Typography
                                            display='inline'
                                            fontFamily={style[style.BodyFontName]}
                                            fontSize={`${style.BodyFontSize}px`}
                                            fontStyle={style.BodyFontStyle}
                                            fontWeight={style.BodyFontWeight}
                                            textAlign={style.BodyAlign}
                                            color={style?.BodyFontColor ?? 'black'}
                                            letterSpacing={style?.BodyFontLetterSpacing ?? 1}>
                                            :&nbsp;
                                        </Typography>
                                        <Typography
                                            display='inline'
                                            fontFamily={style[style.BodyFontName]}
                                            fontSize={`${style.BodyFontSize}px`}
                                            fontStyle={style.BodyFontStyle}
                                            fontWeight={style.BodyFontWeight}
                                            textAlign={style.BodyAlign}
                                            color={style?.BodyFontColor ?? 'black'}
                                            letterSpacing={style?.BodyFontLetterSpacing ?? 1}>
                                            {edu.major}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 0, padding: 0 }}>
                                        <Typography
                                            fontFamily={style[style.DateFontName]}
                                            fontSize={`${style.DateFontSize}px`}
                                            fontStyle={style.DateFontStyle}
                                            fontWeight={style.DateFontWeight}
                                            textAlign='end'
                                            color={style?.DateFontColor ?? 'black'}
                                            letterSpacing={style?.DateFontLetterSpacing ?? 1}>
                                            {format(new Date(edu.graduationYear, edu.graduationMonth - 1), style.DateFormat)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ borderBottom: cardSeperator ? '1px dotted lightgray' : 0, paddingTop: 1 }}>
                                    <TableCell sx={{ border: 0, paddingBottom: 0, paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                                        <Typography
                                            fontFamily={style[style.CompanyFontName]}
                                            fontSize={`${style.CompanyFontSize}px`}
                                            fontStyle={style.CompanyFontStyle}
                                            fontWeight={style.CompanyFontWeight}
                                            textAlign={style.CompanyAlign}
                                            color={style?.CompanyFontColor ?? 'black'}
                                            letterSpacing={style?.CompanyFontLetterSpacing ?? 1}>
                                            {edu.school}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        </Stack>
    )
}

const EducationCondensedSummaryComponent = ({ sectionBgColor = '', cardSeperator = false }: { sectionBgColor?: string; cardSeperator?: boolean }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box textAlign={style?.NameAlign ?? 'end'}>
            <EducationCondensedSummaryContentView
                sectionBgColor={sectionBgColor}
                isHighlighted={isModalOpen}
                onClick={handleOpenModal}
                cardSeperator={cardSeperator}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                isEducation={true}
            />
        </Box>
    )
}
export default EducationCondensedSummaryComponent
