import { FormControl, FormHelperText, FormLabel, InputAdornment, OutlinedInput, type OutlinedInputProps } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type FormInputProps = {
    name: string
    label: string
    endAdornment?: React.ReactNode
} & OutlinedInputProps

const FieldSigninText = ({ name, label, endAdornment, ...others }: FormInputProps): JSX.Element => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl
                    variant='outlined'
                    error={!!error}
                    fullWidth
                    margin='normal'>
                    {label && <FormLabel>{label}</FormLabel>}
                    <OutlinedInput
                        {...others}
                        value={value ?? ''}
                        onChange={onChange}
                        endAdornment={endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : null}
                    />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
            )}
        />
    )
}

export default FieldSigninText
