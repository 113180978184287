import { GetPlaceCommand, LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location'
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper'

const apiKey =
    // eslint-disable-next-line max-len
    'v1.public.eyJqdGkiOiJhZTY2YzdiZC0yMzExLTQzYTktYjRjYS05MzY1Y2IxNTM5MjQifRgL2MLJ9u4vwT4aPqkonL_ObpLuAJfT_bQ6oeL45ijqHvluydKwQV2Mm252_q_z-4-xKenVuYQqxor9ESQuAt__mWB6Ll7C5dQdbu2YXB3xZoznpizVKbyQ9cx5VjQb7h7XkKuCzpmnsBeeS-_71gnQ6TXaXNySuS4r-L9CUvbPpE_wxKkVIOfPNzqVRd5JwgSogoFqn8ANsv5O0AHmYj5Qz_uYz99cEbGUwjc_ZVDIjfv0gTKI1Q4i2r9aNDCqswqKWbyD9B929dQyslJHeeDqK1Ox2fxoj37wzvgzvmLcuDRgziEhAihSyZd57U_1OEPc8qMzlLD0qNJZE53hOro.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'

export const getLocation = async (searchText: string) => {
    const authHelper = await withAPIKey(apiKey)

    const params = {
        IndexName: 'ResumeBuilderAddressSearch1',
        Text: searchText,
        FilterCategories: ['AddressType', 'MunicipalityType', 'PostalCodeType'],
        FilterCountries: ['USA'],
        MaxResults: 10
    }

    const client = new LocationClient({ region: 'us-east-1', ...authHelper.getLocationClientConfig() })
    const search = new SearchPlaceIndexForSuggestionsCommand(params)
    const response = await client.send(search)

    return response.Results
}

export const getPlace = async (PlaceId: string) => {
    const authHelper = await withAPIKey(apiKey)
    const client = new LocationClient({ region: 'us-east-1', ...authHelper.getLocationClientConfig() })
    const search = new GetPlaceCommand({
        IndexName: 'ResumeBuilderAddressSearch1',
        PlaceId
    })
    const response = await client.send(search)

    return response.Place
}
