import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import { PersonalDetailComponent } from '../components/resume/contact/PersonalDetailsComponent'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { AllDefaultCards, defaultCard } from 'utils/Util'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const SingleColumnResume = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Colorado.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack
            width='100%'
            marginTop={`${resume.style[resume.currentStyle].SpaceSection}px`}>
            <NameComponent />
            <PersonalDetailComponent />
            {orderUsedCards.map((value: defaultCard) => {
                return <value.cardComponent key={value.cardType} />
            })}
        </Stack>
    )
}

export default SingleColumnResume
