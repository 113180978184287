import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

const SkillsBubbleListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills: ISimpleListItem[] =
        euContext.resume.skills.category && euContext.resume.skills.category[0].list ? euContext.resume.skills.category[0].list?.sort((a, b) => a.seqNum - b.seqNum) : []

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)

    const handleOpenModal = (skillIndex: number) => {
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentSkillIndex(null)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                sx={{ mb: 0, mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}>
                {euContext.resume.skills.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Box sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {orderedSkills.map((item: ISimpleListItem, index: number) => (
                        <ListItem
                            sx={{ padding: '2px', cursor: 'pointer' }}
                            key={item.item + index}
                            onClick={() => handleOpenModal(index)}>
                            <Typography
                                suppressContentEditableWarning={true}
                                sx={{
                                    borderRadius: 6,
                                    minWidth: '50px',
                                    textAlign: 'center',
                                    backgroundColor: 'lightgrey'
                                }}
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                {item.item}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>

            {currentSkillIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isSkills={true}
                />
            )}
        </Stack>
    )
}

export default SkillsBubbleListComponent
