import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'

interface ICardComponent {
    jobCategories: (string | undefined)[]
    jobTitles: (string | undefined)[]
    selectedJobCategory: number
    selectedJobTitle: number
    setSelectedJobCategory: (val: number) => void
    setSelectedJobTitle: (val: number) => void
}

const CardComponent = (props: ICardComponent) => {
    const { jobCategories, jobTitles, selectedJobCategory, selectedJobTitle, setSelectedJobCategory, setSelectedJobTitle } = props
    return (
        <Stack direction='column'>
            <Typography>Job Categories</Typography>
            <Box
                height={400}
                width={200}
                sx={{ overflowY: 'scroll' }}
                border={1}>
                {jobCategories &&
                    jobCategories.map((item, index) => {
                        return (
                            <Stack
                                direction='row'
                                key={item || 'Unassigned' + index}>
                                <Tooltip
                                    title={item || 'Unassigned'}
                                    placement='right-start'>
                                    <Typography
                                        suppressContentEditableWarning={true}
                                        contentEditable={false}
                                        noWrap
                                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        bgcolor={selectedJobCategory === index ? 'lightgray' : ''}
                                        onClick={() => {
                                            setSelectedJobCategory(index)
                                        }}>
                                        {item || 'Unassigned'}
                                    </Typography>
                                </Tooltip>
                            </Stack>
                        )
                    })}
            </Box>
            <Typography>Job Titles</Typography>
            <Box
                height={400}
                width={200}
                sx={{ overflowY: 'scroll' }}
                border={1}>
                {jobTitles &&
                    jobTitles.map((item, index) => {
                        return (
                            <Stack
                                direction='row'
                                key={item || 'Unassigned' + index}>
                                <Tooltip
                                    title={item || 'Unassigned'}
                                    placement='right-start'>
                                    <Typography
                                        suppressContentEditableWarning={true}
                                        contentEditable={false}
                                        noWrap
                                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        bgcolor={selectedJobTitle === index ? 'lightgray' : ''}
                                        onClick={() => {
                                            setSelectedJobTitle(index)
                                        }}>
                                        {item || 'Unassigned'}
                                    </Typography>
                                </Tooltip>
                            </Stack>
                        )
                    })}
            </Box>
        </Stack>
    )
}

export default CardComponent
