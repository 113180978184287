import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import AffiliationsComponent from '../resume/affiliations/AffiliationsComponent'

const ShowAffiliations = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Affiliations Component (no params)</TableCell>
                    <TableCell>
                        <AffiliationsComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Affiliations Component (section background color)</TableCell>
                    <TableCell>
                        <AffiliationsComponent sectionBgColor='blue' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Component 3</TableCell>
                    <TableCell>View 3</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowAffiliations
