import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { getLabel } from '../labels/labelUtils'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const Header = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <Typography
            variant='h2'
            width='816px'
            sx={{
                backgroundColor: euContext.htmlColors?.header.backgroundColor,
                color: euContext.htmlColors?.header.textColor,
                padding: '16px 0px'
            }}
            textAlign='center'>
            {getLabel(euContext.domain, 'siteTitle')}
        </Typography>
    )
}
