import Typography from '@mui/material/Typography'
import { GoogleOAuthProvider } from '@react-oauth/google'
import React, { ReactNode, useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MyRoutes } from 'routes/MyRoutes'
import { getTheme } from 'themes/DefaultTheme'
import { EmploymentUniverseContext } from './context/EmploymentUniverseContext'
import { useDoesClientExist } from './hooks/useClients'
import { EmploymentUniverseType } from './types/EmploymentUniverseType'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import ErrorState from './views/ErrorState'
import LoadingState from './views/LoadingState'

const App = (): ReactNode => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    let domain = window.location.host.split('.')[0]
    euContext.setDomain(domain)

    useEffect(() => {
        domain = window.location.host.split('.')[0]
        euContext.setDomain(domain)
    }, [window.location.host])

    const { data: clientsResponse, isLoading: clientsIsLoading, isError: clientsIsError } = useDoesClientExist(domain)

    useEffect(() => {
        if (clientsResponse?.exists) {
            euContext.setHtmlColors(clientsResponse?.htmlColors)
        }
    }, [clientsResponse])

    if (clientsIsLoading) {
        return <LoadingState message='Loading...' />
    }

    if (clientsIsError) {
        return <ErrorState message='Error getting client exists' />
    }

    return (
        <>
            {clientsResponse?.exists ? (
                <GoogleOAuthProvider clientId='490250454622-p2b87jgs5gemdipgd5t37s50dumeia00.apps.googleusercontent.com'>
                    <BrowserRouter>
                        <ThemeProvider theme={getTheme(domain)}>
                            <CssBaseline />
                            <MyRoutes />
                        </ThemeProvider>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            ) : (
                <Typography>Site Does Not Exist</Typography>
            )}
        </>
    )
}

export default App
