import { Stack, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import { getTypographyStyles } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'

const NameShadeContent = ({
    mt,
    firstNameColor,
    lastNameColor,
    showInline = false,
    boldLastName = false,
    isHighlighted,
    onClick,
    sx = {}
}: {
    mt?: number
    firstNameColor: string
    lastNameColor: string
    showInline?: boolean
    boldLastName?: boolean
    isHighlighted?: boolean
    onClick?: () => void
    sx?: object
}) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const firstName: string = euContext.resume.personalInfo.firstName
    const lastName: string = euContext.resume.personalInfo.lastName
    const commonTypographyStyles = getTypographyStyles(style, 'Name', true)

    return (
        <Stack
            display={showInline ? 'inline' : 'block'}
            spacing={0}
            direction='column'
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'none',
                padding: isHighlighted ? '10px' : '0',
                ...sx
            }}>
            <Typography
                sx={{ ...sx, ...commonTypographyStyles, color: firstNameColor }}
                display={showInline ? 'inline' : 'block'}
                margin='none'
                marginBottom={`${style?.SpaceSectionTitle}px`}
                marginTop={`${mt}px`}>
                {firstName}
            </Typography>
            {showInline && (
                <Typography
                    sx={{ ...sx, ...commonTypographyStyles, color: firstNameColor }}
                    display={showInline ? 'inline' : 'block'}
                    margin='none'
                    marginBottom={`${style?.SpaceSectionTitle}px`}
                    marginTop={`${mt}px`}>
                    &nbsp;
                </Typography>
            )}
            <Typography
                sx={{ ...sx, ...commonTypographyStyles, color: lastNameColor, fontWeight: boldLastName ? 'bold' : style?.NameFontWeight }}
                display={showInline ? 'inline' : 'block'}
                margin='none'
                marginBottom={`${style?.SpaceSectionTitle}px`}
                marginTop={`${mt}px`}>
                {lastName}
            </Typography>
        </Stack>
    )
}

const NameComponentShade = ({
    mt = 0,
    firstNameColor = 'black',
    lastNameColor = 'lightgray',
    showInline = false,
    boldLastName = false,
    sx = {}
}: {
    mt?: number
    firstNameColor?: string
    lastNameColor?: string
    showInline?: boolean
    boldLastName?: boolean
    sx?: object
}): JSX.Element => {
    const nameRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const names = ['personalInfo.firstName', 'personalInfo.lastName']

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={nameRef}>
                <NameShadeContent
                    firstNameColor={firstNameColor}
                    lastNameColor={lastNameColor}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                    showInline={showInline}
                    boldLastName={boldLastName}
                    mt={mt}
                    sx={sx}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </>
    )
}

export default NameComponentShade
