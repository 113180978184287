import { Box, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleList, ISimpleListItem } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import { HorizontalLine } from '../misc/MiscComponents'

export const HorizontalListComponent = ({
    list,
    sectionBgColor = '',
    boldCategory = false,
    onSkillClick
}: {
    list: ISimpleList
    sectionBgColor?: string
    boldCategory?: boolean
    onSkillClick?: (categoryIndex: number, skillIndex: number) => void
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills = (index: number) => (list.category && list.category[index].list ? list.category[index].list?.sort((a, b) => a.seqNum - b.seqNum) : ([] as ISimpleListItem[]))

    return (
        <Stack
            direction='column'
            marginBottom={`${style.SpaceCard}px`}>
            <Typography
                sx={{
                    textTransform: style?.SectionFontCase ?? 'none',
                    bgcolor: sectionBgColor,
                    marginBottom: style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`
                }}>
                {list.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {list.category.map((category, categoryIndex) => (
                    <Stack
                        key={category.name + categoryIndex}
                        direction='row'
                        columnGap={3}>
                        {orderedSkills(categoryIndex).map((item: ISimpleListItem, skillIndex: number) => (
                            <Box
                                onClick={onSkillClick ? () => onSkillClick(categoryIndex, skillIndex) : undefined}
                                key={item.item + skillIndex}>
                                <Typography
                                    sx={{
                                        borderBottom: '1px dotted lightgray',
                                        fontWeight: boldCategory ? 'bold' : style?.BodyFontWeight
                                    }}>
                                    {item.item}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
