import { Box, Checkbox, IconButton, Stack, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import { IStatements } from '../../models/Statements'
import CustomTable from '../CustomTable'
import EditStatementComponent from './EditStatementComponent'

interface IStatementComponent {
    label: string
    statements: IStatements[]
    setStatements: Function
}

interface HeadCell {
    disablePadding: boolean
    id: keyof IStatements
    label: string
    numeric: boolean
    width: number
}

const headCells: readonly HeadCell[] = [
    {
        id: 'statement',
        numeric: true,
        disablePadding: false,
        label: 'Statement',
        width: 100
    },
    {
        id: 'show_user',
        numeric: true,
        disablePadding: false,
        label: 'Show User?',
        width: 30
    },
    {
        id: 'times_used',
        numeric: true,
        disablePadding: false,
        label: 'Times Used',
        width: 30
    }
]

interface EnhancedTableProps {
    numSelected: number
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all statements'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        style={{ width: headCell.width }}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

interface EnhancedTableToolbarProps {
    numSelected: number
    label: string
    showUserStatements: (event: { preventDefault: () => void }) => void
    setEditStatementModal: Function
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { label, numSelected, showUserStatements, setEditStatementModal } = props

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                py: 1,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => theme.palette.action.selected
                })
            }}>
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    component='div'>
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    variant='h6'
                    sx={{ flex: '1 1 100%' }}
                    id='tableTitle'
                    component='div'>
                    {label}
                </Typography>
            )}

            <Stack
                direction='row'
                spacing={1}>
                {numSelected === 1 && (
                    <Tooltip title='Edit'>
                        <IconButton
                            size='small'
                            onClick={() => setEditStatementModal(true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {numSelected > 0 && (
                    <Tooltip title='Resolve'>
                        <IconButton
                            size='small'
                            onClick={showUserStatements}>
                            <CheckCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        </Box>
    )
}

const StatementsComponent = (props: IStatementComponent) => {
    const { label, statements, setStatements } = props
    const [selected, setSelected] = React.useState<readonly number[]>([])
    const [editStatementModal, setEditStatementModal] = useState<boolean>(false)
    const isSelected = (i: number) => selected.indexOf(i) !== -1

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = statements.map((n, i) => i)
            setSelected(newSelected)
            return
        }
        setSelected([])
    }

    const handleClick = (event: React.MouseEvent<unknown>, selectedIndex: number) => {
        event.stopPropagation()

        let newSelected: readonly number[] = []
        const indexNum = selected.indexOf(selectedIndex)

        if (indexNum >= 0) {
            newSelected = newSelected.concat(selected.slice(0, indexNum), selected.slice(indexNum + 1))
        } else {
            newSelected = selected.concat(selectedIndex)
        }

        setSelected(newSelected)
    }

    const setShowUserStatements = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        const newStatements = statements.slice()
        for (const item of selected) {
            newStatements[item] = { ...newStatements[item], show_user: true }
        }

        setStatements(newStatements)
        setSelected([])
    }

    const rows = statements.map((item, index) => ({
        statement: (
            <Tooltip title={item.statement}>
                <Typography>{item.statement}</Typography>
            </Tooltip>
        ),
        show_user: item.show_user ? 'true' : 'false',
        times_used: item.times_used,
        isSelected: isSelected(index),
        index: index
    }))

    return (
        <Stack direction='column'>
            <EnhancedTableToolbar
                numSelected={selected.length}
                label={label}
                showUserStatements={setShowUserStatements}
                setEditStatementModal={setEditStatementModal}
            />
            <EditStatementComponent
                openEditStatement={editStatementModal}
                setOpenEditStatement={setEditStatementModal}
                statementIndex={selected[0]}
                setStatementIndex={setSelected}
                statements={statements}
                setStatements={setStatements}
            />
            <Box
                height={776}
                width={600}
                sx={{ overflowY: 'scroll' }}
                border={1}>
                <CustomTable
                    columns={headCells.map((headCell) => ({
                        id: headCell.id,
                        label: headCell.label,
                        align: headCell.numeric ? 'right' : 'left'
                    }))}
                    rows={rows}
                    EnhancedTableHeadComponent={EnhancedTableHead}
                    handleRowClick={handleClick}
                    toolbarProps={{
                        numSelected: selected.length,
                        onSelectAllClick: handleSelectAllClick,
                        rowCount: statements.length,
                        label,
                        selected: selected.slice()
                    }}
                />
            </Box>
        </Stack>
    )
}

export default StatementsComponent
