import { Container, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import ContactComponent from '../components/resume/contact/ContactComponent'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import AccomplishmentsRateLineComponent from '../components/resume/simpleLists/AccomplishmentsRateLineComponent'
import SkillsRateLineComponent from '../components/resume/simpleLists/SkillsRateLineComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import backgroundImage from '../images/background/6HexaDesign.jpg'
import { IResume } from '../models/Resume'

const Resume7 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume7.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })
    const style = resume.style[resume.currentStyle]

    return (
        <Stack
            direction='row'
            border={2}
            fontSize={style.BodyFontSize}
            fontStyle={style.BodyFontStyle}
            fontWeight={style.BodyFontWeight}
            fontFamily={style[style.BodyFontName]}
            columnGap={2}>
            <Stack
                direction='column'
                width='275px'
                paddingRight={2}
                borderRight={2}
                rowGap={style.SpaceSection}>
                <img
                    src={backgroundImage}
                    width={50}
                    style={{ alignSelf: 'end', marginTop: -5 }}
                />
                <Container sx={{ border: 0, width: 100, height: 100, alignSelf: 'end', textAlign: 'end', bgcolor: 'lightgray', mt: 1, ml: 0, mr: 0, mb: 6 }} />

                <ContactComponent
                    includeLabel={true}
                    includeSublabels={true}
                    includeAddress={true}
                    includeIcons={false}
                    iconOnRight={true}
                />
                <SkillsRateLineComponent
                    gridSize={12}
                    alignRight={true}
                />
                <TrainingListComponent alignRight={true} />
                <AccomplishmentsRateLineComponent
                    gridSize={12}
                    alignRight={true}
                />
            </Stack>
            <Stack
                direction='column'
                width='474px'
                paddingTop='19px'
                paddingLeft={2}
                paddingRight={2}>
                <NameVerticleComponent />
                <TitleComponent />
                {orderUsedCards.map((value: defaultCard, index: number) => {
                    return <value.cardComponent key={index} />
                })}
            </Stack>
        </Stack>
    )
}

export default Resume7
