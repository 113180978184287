import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import ContactComponent from '../resume/contact/ContactComponent'
import ContactHorizontalComponent from '../resume/contact/ContactHorizontalComponent'
import { LocationComponent } from '../resume/contact/LocationComponent'

const ShowContacts = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width='150px'>Component Name</TableCell>
                    <TableCell>Component View</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>ContactComponent (no params)</TableCell>
                    <TableCell>
                        <ContactComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ContactComponent (includeAddress)</TableCell>
                    <TableCell>
                        <ContactComponent includeAddress={true} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ContactComponent (includeAddress & iconOnRight)</TableCell>
                    <TableCell>
                        <ContactComponent
                            includeAddress={true}
                            iconOnRight={true}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ContactComponent (includeAddress & includeLabel)</TableCell>
                    <TableCell>
                        <ContactComponent
                            includeAddress={true}
                            includeLabel={false}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ContactComponent (includeAddress & includeSubLabel)</TableCell>
                    <TableCell>
                        <ContactComponent
                            includeAddress={true}
                            includeSublabels={true}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>ContactHorizontalComponent (no params)</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>
                        <ContactHorizontalComponent />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>LocationComponent (no params)</TableCell>
                    <TableCell>
                        <LocationComponent />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ShowContacts
