import { SvgIcon } from '@mui/material'
import DOMPurify from 'dompurify'
import { ReactNode } from 'react'
import { ISimpleCategory } from '../models/SimpleList'
import {
    DefaultDefaultCards,
    Resume10DefaultCards,
    Resume11DefaultCards,
    Resume12DefaultCards,
    Resume13DefaultCards,
    Resume14DefaultCards,
    Resume15DefaultCards,
    Resume16DefaultCards,
    Resume17DefaultCards,
    Resume18DefaultCards,
    Resume19DefaultCards,
    Resume1DefaultCards,
    Resume20DefaultCards,
    Resume2DefaultCards,
    Resume3DefaultCards,
    Resume4DefaultCards,
    Resume5DefaultCards,
    Resume6DefaultCards,
    Resume7DefaultCards,
    Resume8DefaultCards,
    Resume9DefaultCards,
    SingleColumnDefaultCards
} from './CardUtils'
import { cardTypes, validResumeStyles } from './Constants'

export const defaultHost: string = 'stanford'

export enum ButtonTypes {
    Add = 'Add',
    Remove = 'Remove'
}

export interface defaultCard {
    title: string
    description: string
    cardType: cardTypes
    isDraggable?: boolean

    cardComponent: ({
        showLabel,
        sectionBgColor,
        cardSeperator,
        gridSize,
        alignRight
    }: {
        showLabel?: boolean
        sectionBgColor?: string
        cardSeperator?: boolean
        gridSize?: number
        alignRight?: boolean
    }) => ReactNode
    icon: typeof SvgIcon & { muiName: string }
}

type DefaultCardsPerStyleType = {
    [style in validResumeStyles]: defaultCard[]
}

export const AllDefaultCards: DefaultCardsPerStyleType = {
    Colorado: SingleColumnDefaultCards,
    Default: DefaultDefaultCards,
    Harvard: SingleColumnDefaultCards,
    Miami: SingleColumnDefaultCards,
    Resume1: Resume1DefaultCards,
    Resume2: Resume2DefaultCards,
    Resume3: Resume3DefaultCards,
    Resume4: Resume4DefaultCards,
    Resume5: Resume5DefaultCards,
    Resume6: Resume6DefaultCards,
    Resume7: Resume7DefaultCards,
    Resume8: Resume8DefaultCards,
    Resume9: Resume9DefaultCards,
    Resume10: Resume10DefaultCards,
    Resume11: Resume11DefaultCards,
    Resume12: Resume12DefaultCards,
    Resume13: Resume13DefaultCards,
    Resume14: Resume14DefaultCards,
    Resume15: Resume15DefaultCards,
    Resume16: Resume16DefaultCards,
    Resume17: Resume17DefaultCards,
    Resume18: Resume18DefaultCards,
    Resume19: Resume19DefaultCards,
    Resume20: Resume20DefaultCards
}

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const currentYear = new Date().getFullYear()
export const years = Array.from({ length: 101 }, (_, i) => currentYear - i)
export const currentMonth = new Date().getMonth()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareArrays = (a: any, b: any) => a.length === b.length && a.every((element: any, index: number) => element === b[index])

export const cardStyleHover = {
    border: '1px solid black'
}

const noDragCardStyle = {
    left: '0px'
}

const cardStyle = (spaceCard: number) => {
    return {
        left: '-40px',
        margin: '0px',
        marginBottom: `${spaceCard}px`,
        position: 'relative',
        zIndex: '1200'
    }
}

export const cardStyleToUse = (isDraggable: boolean, spaceCard: number) => {
    return isDraggable ? cardStyle(spaceCard) : { ...cardStyle(spaceCard), ...noDragCardStyle }
}

export const dragIconStyleHover = {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderBottom: '1px solid black'
    //display: 'inline-block'
}

const noDragIconStyle = {
    display: 'none'
}

const dragIconStyle = {
    left: '-40px',
    marginTop: '4px',
    display: '-webkit-box',
    position: 'relative',
    zIndex: '1200'
    //display: 'none'
}

export const dragIconStyleToUse = (isDraggable: boolean) => {
    return isDraggable ? dragIconStyle : { ...dragIconStyle, ...noDragIconStyle }
}

export const camelToTitle = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim()

export const isValidISODate = (dateString: string): boolean => {
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

    if (!isoFormat.test(dateString)) {
        return false
    }

    const date = new Date(dateString)
    return !isNaN(date.getTime())
}

export const formatMoney = (amount: number): string => {
    const dollars = amount / 100
    return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    })
}

export const capitalizeFirstLetter = (str: string): string => {
    if (!str) return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const toReadableString = (input: string): string => {
    const parts = input.split('.')

    const readableParts = parts.map((part) => {
        return part.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (char) => char.toUpperCase())
    })

    return readableParts[readableParts.length - 1]
}

export const markdownToPlainText = (markdown: string): string => {
    const cleanText: string = DOMPurify.sanitize(markdown, { USE_PROFILES: { html: true } })
    return cleanText
}

export enum TokenValidity {
    Expired = 'Expired',
    ExpiringSoon = 'ExpiringSoon',
    Valid = 'Valid'
}

export const getTokenTokenValidity = (expireDate: string, thresholdInMinutes: number = 5): TokenValidity => {
    const currentTime = Date.now() / 1000
    const expiryTime = Date.parse(expireDate) / 1000
    const timeRemaining = expiryTime - currentTime

    if (expiryTime < currentTime) return TokenValidity.Expired
    else if (timeRemaining < thresholdInMinutes * 60) return TokenValidity.ExpiringSoon
    else return TokenValidity.Valid
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTypographyStyles = (style: any, key: string, showPointer = false) => {
    return {
        color: style?.[`${key}FontColor`] ?? 'black',
        fontFamily: style?.[`${key}FontName`],
        fontSize: `${style?.[`${key}FontSize`]}px`,
        fontStyle: style?.[`${key}FontStyle`] ?? 'initial',
        fontWeight: style?.[`${key}FontWeight`] ?? 'normal',
        letterSpacing: `${style?.[`${key}FontLetterSpacing`] ?? 1}px`,
        textAlign: style?.[`${key}Align`] ?? 'left',
        cursor: showPointer ? 'pointer' : 'inherit'
    }
}

export const isSimpleCard = (defaultCard: defaultCard) => {
    return ['SummaryComponent', 'ObjectiveComponent', 'PersonalComponent', 'OtherComponent'].includes(defaultCard.cardComponent.name)
}

export const mapCategories = (categories: ISimpleCategory[]): ISimpleCategory[] => {
    return categories.map((category) => ({
        name: category.name,
        list: category.list.map((listItem) => ({
            item: listItem.item,
            rate: listItem.rate,
            seqNum: listItem.seqNum
        })),
        seqNum: category.seqNum
    }))
}

/**
 * Checks if a given category array matches the default empty state structure.
 * @param categories - Array of ISimpleCategory to check.
 * @returns true if it matches the default empty state, otherwise false.
 */
export const isDefaultEmptyState = (categories: ISimpleCategory[]): boolean => {
    const defaultEmptyState: ISimpleCategory[] = [
        {
            name: '',
            seqNum: 0,
            list: [
                {
                    item: '',
                    rate: 0,
                    seqNum: 0
                }
            ]
        }
    ]
    return (
        categories.length === 0 ||
        (categories.length === 1 &&
            categories[0].name === defaultEmptyState[0].name &&
            categories[0].seqNum === defaultEmptyState[0].seqNum &&
            categories[0].list.length === 1 &&
            categories[0].list[0].item === defaultEmptyState[0].list[0].item &&
            categories[0].list[0].rate === defaultEmptyState[0].list[0].rate &&
            categories[0].list[0].seqNum === defaultEmptyState[0].list[0].seqNum)
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNestedProperty = (obj: any, path: string[]): any => {
    return path.reduce((acc, part) => (acc && typeof acc === 'object' ? acc[part] : undefined), obj)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setNestedProperty = (obj: any, path: string[], value: any): void => {
    const lastKey = path.pop()
    const target = getNestedProperty(obj, path)
    if (target && typeof target === 'object' && lastKey) {
        target[lastKey] = value
    }
}
