import React, { useContext, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import EditModal from '../../EditModal/EditModal'

const NameContentView = ({ style, mt, isHighlighted, onClick, sx }: { style: IStyles; mt?: number; isHighlighted?: boolean; onClick?: () => void; sx: object }) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { firstName, lastName } = euContext.resume.personalInfo

    return (
        <Stack
            spacing={0}
            direction='row'
            textAlign={style?.NameAlign ?? 'end'}
            width='100%'
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'unset',
                padding: isHighlighted ? '10px' : '0',
                ...sx
            }}>
            <Typography
                width='100%'
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                color={style?.NameFontColor ?? 'black'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                letterSpacing={`${style?.NameFontLetterSpacing ?? 0}px`}
                marginTop={`${mt}px`}
                sx={sx}>
                {firstName}&nbsp;{lastName}
            </Typography>
        </Stack>
    )
}

const NameComponent = ({ mt = 0, nameWidth = '100%', sx = {} }: { mt?: number; nameWidth?: string; sx?: object }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const [isModalOpen, setModalOpen] = useState(false)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const names = ['personalInfo.firstName', 'personalInfo.lastName']

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <Box
            width={nameWidth}
            textAlign={style?.NameAlign ?? 'end'}>
            <NameContentView
                style={style}
                onClick={handleOpenModal}
                isHighlighted={isModalOpen}
                mt={mt}
                sx={sx}
            />
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </Box>
    )
}

export default NameComponent
