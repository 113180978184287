import EditIcon from '@mui/icons-material/Edit'
import { Divider, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

interface EditModalTitleProps {
    label: string
    onSave: (newLabel: string) => void
    variant?: 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2'
}

const EditModalTitle: React.FC<EditModalTitleProps> = ({ label, onSave, variant = 'h4' }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [currentLabel, setCurrentLabel] = useState(label)

    const handleEditClick = () => {
        setIsEditing(true)
    }

    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentLabel(event.target.value)
    }

    const handleLabelSave = () => {
        setIsEditing(false)
        if (currentLabel !== label) {
            onSave(currentLabel)
        }
    }

    return (
        <>
            {isEditing ? (
                <TextField
                    value={currentLabel}
                    onChange={handleLabelChange}
                    onBlur={handleLabelSave}
                    autoFocus
                    variant='standard'
                    sx={{ fontSize: `${variant}.fontSize`, fontWeight: 'bold', mb: 2, width: '100%' }}
                />
            ) : (
                <Typography
                    variant={variant}
                    component='div'
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {currentLabel}
                    <IconButton
                        onClick={handleEditClick}
                        size='small'
                        aria-label='edit'
                        sx={{ ml: 1 }}>
                        <EditIcon fontSize='small' />
                    </IconButton>
                </Typography>
            )}
            <Divider sx={{ paddingTop: 1 }} />
        </>
    )
}

export default EditModalTitle
