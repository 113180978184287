import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { ISimpleListItem, ratingType } from '../../../models/SimpleList'
import { IStyles } from '../../../models/Styles'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface SkillCirclesProps {
    maxRating?: number
}

const SkillCirclesComponent = ({ maxRating = 5 }: SkillCirclesProps) => {
    const { getValues, setValue } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills = euContext.resume.skills.category?.[0]?.list ?? []
    const [skills, setLocalSkills] = useState<ISimpleListItem[]>(orderedSkills)
    const [ratings, setRatings] = useState<number[]>(skills.map((skill) => getValues(`skills[${skill.seqNum}].rate`) || 0))
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null)

    const handleOpenModal = (skillIndex: number) => {
        setCurrentSkillIndex(skillIndex)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setCurrentSkillIndex(null)
    }

    const handleCircleClick = (skillIndex: number, rating: number) => {
        const newRating = rating + 1
        const updatedRatings = [...ratings]
        updatedRatings[skillIndex] = newRating
        setRatings(updatedRatings)
        setValue(`skills[${skills[skillIndex].seqNum}].rate`, newRating)
        const updatedSkills = [...skills]
        updatedSkills[skillIndex].rate = newRating as ratingType
        setLocalSkills(updatedSkills)
    }

    return (
        <Box marginBottom={`${style.SpaceCard}px`}>
            <Typography
                variant='h3'
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ mt: 2, textTransform: style?.SectionFontCase ?? 'none', paddingBottom: '12px' }}>
                {euContext.resume.skills.label}
            </Typography>

            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}

            {skills.map((skill, index) => {
                const currentRating = skill.rate

                return (
                    <Stack
                        key={index}
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        mb={2}>
                        <Typography
                            sx={{ width: 150, fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => handleOpenModal(index)}>
                            {skill.item}
                        </Typography>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {Array.from({ length: maxRating }).map((_, circleIndex) => (
                                <Box
                                    key={circleIndex}
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        backgroundColor: circleIndex < currentRating ? 'darkgray' : 'lightgray',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: circleIndex < currentRating ? 'gray' : '#b0b0b0'
                                        }
                                    }}
                                    onClick={() => handleCircleClick(index, circleIndex)}
                                />
                            ))}
                        </Box>
                    </Stack>
                )
            })}

            {currentSkillIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    width={700}
                    isSkills={true}
                />
            )}
        </Box>
    )
}

export default SkillCirclesComponent
